import React from 'react'
import { Route, RouteProps, Switch, useLocation } from 'react-router-dom'
import { some } from 'lodash'
import { wrapArray } from 'ytil'
import { memo } from '~/ui/component'
import Flipper from './Flipper'

export interface Props {
  duration?: number
  children:  React.ReactNode
}

const FlipNavigator = memo('FlipNavigator', (props: Props) => {

  const {
    duration,
    children,
  } = props

  const location = useLocation()

  const routes = React.useMemo((): React.ReactElement<RouteProps & FlipNavigatorRouteProps>[] => {
    return React.Children.toArray(children) as React.CElement<RouteProps & FlipNavigatorRouteProps, Route>[]
  }, [children])

  const currentRoute = React.useMemo(() => {
    return routes.find(route => {
      const {path, exact} = route.props
      if (path == null) { return false }

      const paths = wrapArray(path)
      if (exact) {
        return paths.includes(location.pathname)
      } else {
        return some(paths, it => location.pathname.startsWith(it))
      }
    })
  }, [location.pathname, routes])

  const flipped = currentRoute?.props.side === 'back'

  //------
  // Rendering

  function render() {
    return (
      <Flipper flipped={flipped} duration={duration}>
        <Switch key={location.pathname} location={location}>
          {children}
        </Switch>
      </Flipper>
    )
  }

  return render()

})

export interface FlipNavigatorRouteProps {
  side?: 'front' | 'back'
}

Object.assign(FlipNavigator, {Route})
export default FlipNavigator as typeof FlipNavigator & {
  Route: React.ComponentType<RouteProps & FlipNavigatorRouteProps>
}