import React from 'react'
import bewizrStore from '~/stores/bewizrStore'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller } from '~/ui/components'
import { NavColumnHelmet } from '../navigation'
import BeWizrLearningTrackView from './learning-tracks/BeWizrLearningTrackView'

export interface Props {
  id: string
}

const BeWizrLearningTrackColumn = observer('BeWizrLearningTrackColumn', (props: Props) => {

  const {id} = props

  const authenticated = bewizrStore.authenticated

  const document      = bewizrStore.learningTracks.document(id)
  const learningTrack = document.data
  const fetchStatus   = document.fetchStatus

  React.useEffect(() => {
    if (!authenticated) { return }
    document?.fetch()
  }, [document, authenticated])

  //------
  // Rendering

  function render() {
    return (
      <>
        <NavColumnHelmet
          title={learningTrack?.title ?? null}
          fetchStatus={fetchStatus}
        />
        {renderContent()}
      </>
    )
  }

  function renderContent() {
    if (learningTrack == null || fetchStatus !== 'done') {
      return renderEmpty()
    }

    return (
      <Scroller>
        <BeWizrLearningTrackView
          learningTrack={learningTrack}
        />
      </Scroller>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        flex
        status={fetchStatus}
      />
    )
  }

  return render()

})

export default BeWizrLearningTrackColumn