import React from 'react'
import { DateTime } from 'luxon'
import { Answer, AnswerResult, Challenge, ChallengeState, ChallengeTask } from '~/models'
import { observer } from '~/ui/component'
import { VBox, Markdown } from '~/ui/components'
import { layout } from '~/ui/styling'
import ChallengeStatusNotice from '../ChallengeStatusNotice'
import ChallengeTaskAnswerEntry from './ChallengeTaskAnswerEntry'
import ChallengeTaskAnswerOutcome from './ChallengeTaskAnswerOutcome'
import ChallengeTaskCompleteNotice from './ChallengeTaskCompleteNotice'

export interface Props {
  challenge:      Challenge
  state:          ChallengeState | null
  task:           ChallengeTask
  answer:         Answer | null
  results:        AnswerResult[]
  mayAnswer:      boolean
  correctAnswers: any[]
}

const ChallengeTaskContent = observer('ChallengeTaskContent', (props: Props) => {

  const {challenge, state, task, mayAnswer, answer, results, correctAnswers} = props

  const deadline       = state?.deadline == null ? null : DateTime.fromISO(state.deadline)
  const pastDeadline   = deadline != null && deadline < DateTime.local()

  //------
  // Rendering

  function render() {
    return (
      <VBox flex='grow' gap={layout.padding.m}>
        {pastDeadline ? (
          <ChallengeStatusNotice
            challenge={challenge}
            state={state!}
          />
        ) : answer?.status != null ? (
          <ChallengeTaskCompleteNotice
            task={task}
            answer={answer}
          />
        ) : null}

        <VBox flex='grow'>
          <Markdown>
            {task.body}
          </Markdown>
        </VBox>

        {mayAnswer ? (
          renderAnswerEntry()
        ) : (
          renderAnswerOutcome()
        )}
      </VBox>
    )
  }

  function renderAnswerEntry() {
    return (
      <ChallengeTaskAnswerEntry
        challenge={challenge}
        task={task}
      />
    )
  }

  function renderAnswerOutcome() {
    if (answer == null) { return null }

    return (
      <ChallengeTaskAnswerOutcome
        task={task}
        answer={answer}
        results={results}
        correctAnswers={correctAnswers}
      />
    )
  }

  return render()

})

export default ChallengeTaskContent