import React from 'react'
import { useTranslation } from 'react-i18next'
import { notificationsStore, projectStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Badge, HBox, Label, PopupMenu, PopupMenuItem, Tappable } from '~/ui/components'
import SVG, { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'
import AppSideBarNavigationButton from './AppSideBarNavigationButton'

const AppSideBarMoreButton = observer('AppSideBarMoreButton', () => {

  const [t] = useTranslation('app')

  const project              = projectStore.project
  const drawer               = project?.feature('drawer')
  const drawerEnabled        = drawer?.enabled
  const notificationsEnabled = drawer?.options.notifications
  const searchEnabled        = drawer?.options.search

  const notificationCount = notificationsEnabled ? notificationsStore.unseenNotificationCount : null

  const renderItem = React.useCallback((name: string, href: string, icon: SVGName, badge?: string | number | null) => {
    return (
      <AppSideBarMoreMenuItem
        caption={t(`nav.${name}`)}
        href={href}
        icon={icon}
        badge={badge}
      />
    )
  }, [t])

  const drawerItems = React.useMemo(() => {
    if (!drawerEnabled) { return [] }
    const items: PopupMenuItem[] = []
    if (notificationsEnabled) {
      items.push({
        icon: 'bell',
        render: () => renderItem('notifications', '/notifications', 'bell', notificationCount),
        href: '/notifications',
      })
    }
    if (searchEnabled) {
      items.push({
        icon: 'search',
        render: () => renderItem('search', '/search', 'search'),
        href: '/search',
      })
    }
    items.push({
      render: () => renderItem('profile', '/profile', 'user'),
      href: '/profile',
    })
    return items
  }, [drawerEnabled, notificationsEnabled, notificationCount, renderItem, searchEnabled])

  //------
  // Rendering

  function render() {
    if (drawerItems.length === 0) { return null }
    return (
      <PopupMenu items={drawerItems}>
        {toggle => (
          <AppSideBarNavigationButton
            icon='hamburger'
            caption={t('nav.more')}
            onTap={toggle}
            badge={notificationCount}
          />
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default AppSideBarMoreButton

interface AppSideBarMoreMenuItemProps {
  caption: string
  href:    string
  icon:    SVGName
  badge?:  string | number | null
}

const AppSideBarMoreMenuItem = memo('AppSideBarMoreMenuItem', (props: AppSideBarMoreMenuItemProps) => {

  const {href, icon, caption, badge} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable href={href}>
        <HBox gap={layout.padding.inline.m} classNames={$.AppSideBarMoreMenuItem}>
          {renderIcon()}
          {renderCaption()}
          {renderBadge()}
        </HBox>
      </Tappable>
    )
  }

  function renderIcon() {
    return (
      <SVG name={icon} size={layout.icon.l}/>
    )
  }

  function renderCaption() {
    return (
      <Label
        caption
        children={caption}
      />
    )
  }

  function renderBadge() {
    if (badge == null) { return null }
    return (
      <Badge
        value={badge}
      />
    )
  }

  return render()

})

const useStyles = createUseStyles({
  AppSideBarMoreMenuItem: {
    padding: layout.padding.inline.l,
  },
})