import React from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserNotificationManager } from 'react-notifications'
import { useHistory } from 'react-router'
import { memo, observer } from '~/ui/component'
import { ClearButton, FlexProp, HBox, Label, Panel } from '~/ui/components'
import { useLocationChange, useViewState } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import Chat from '../chat/Chat'
import { useHomeChat } from './HomeChatContext'

export interface Props {
  classNames?: React.ClassNamesProp
  flex?:       FlexProp
}

export interface Params {
  channelID?: string
}

const HomeChat = observer('HomeChat', (props: Props) => {

  const {classNames, flex = true} = props

  const service = useHomeChat().service
  const history = useHistory()

  useLocationChange(location => {
    const channelID = location.hash?.slice(1) ?? null
    service?.switchChannel(channelID ?? null)
  })

  React.useEffect(() => {
    if (service == null) { return }

    return service.setSwitchChannelHandler(channelID => {
      history.replace(`#${channelID ?? ''}`)
    })
  }, [history, service])

  //------
  // Rendering

  function render() {
    if (service == null) { return null }

    return (
      <Panel tag='section' arial-label='chat' semi flex={flex} classNames={classNames}>
        <Chat
          controller={service}
          subheader={<BrowserNotificationsBar/>}
        />
      </Panel>
    )
  }

  return render()

})

export default HomeChat

const BrowserNotificationsBar = memo('BrowserNotificationsBar', () => {

  const [askedForPermissions, setAskedForPermissions] = useViewState('chat.notifications.asked-for-permissions', false)
  const permission = BrowserNotificationManager.permission

  const [t] = useTranslation('chat')

  const requestPermission = React.useCallback(() => {
    BrowserNotificationManager.requestPermission()
    setAskedForPermissions(true)
  }, [setAskedForPermissions])

  const dontAskAgain = React.useCallback(() => {
    setAskedForPermissions(true)
  }, [setAskedForPermissions])

  const $ = useStyles()

  if (askedForPermissions || permission == null) { return null }

  return (
    <HBox gap={layout.padding.inline.l} classNames={$.browserNotificationsBar}>
      <Label small flex>
        {t('notifications.prompt')}
      </Label>
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          caption={t('notifications.enable')}
          onTap={requestPermission}
          small
        />
        <ClearButton
          icon='cross'
          caption={t('notifications.dont_ask_again')}
          onTap={dontAskAgain}
          small
        />
      </HBox>
    </HBox>
  )

})

const useStyles = createUseStyles(theme => ({
  browserNotificationsBar: {
    padding:    [layout.padding.inline.xs, layout.padding.inline.m],
    background: theme.bg.normal,
  },
}))