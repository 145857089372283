import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { memo, observer } from '~/ui/component'
import { Center, Spinner } from '~/ui/components'
import HomeScreen from '../home/HomeScreen'

export interface Props {}

const RootNavigator = observer('RootNavigator', (props: Props) => {

  return (
    <Switch>
      <Route exact path='/login' component={TransitionScreen}/>
      <Route path='/auth' component={TransitionScreen}/>

      <Route component={HomeScreen}/>
    </Switch>
  )

})

interface TransitionScreenProps {}

const TransitionScreen = memo('TransitionScreen', (props: TransitionScreenProps) => {

  function render() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  return render()

})

export default RootNavigator