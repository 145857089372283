import React from 'react'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { AppHeader } from '~/ui/layouts'
import CollapsedNavContainer from '../navigation/CollapsedNavContainer'

const CollapsedLayout = memo('CollapsedLayout', () => {

  function render() {
    return (
      <>
        <AppHeader/>
        <VBox flex>
          <CollapsedNavContainer/>
        </VBox>
      </>
    )
  }

  return render()

})

export default CollapsedLayout