import Dotenv from 'dotenv'
import Logger from 'logger'
import * as Path from 'path'

export type DataEnvironment =
  /** Local development. */
  | 'local'
  /** Integration environment. Automatically deployed from the `dev` branch. */
  | 'integration'
  /** Acceptance environment. Automatically deployed from the `release` branch. */
  | 'acceptance'
  /** Live, promoted from acceptance or deployed from hotfix branches. */
  | 'live'

export const APP_NAME           = 'web'
export const LOCAL_PORT         = 8083
export const LOCAL_MISSION_PORT = 8085
export const LOCAL_SITE_PORT    = 8088
export const LOCAL_API_PORT     = 3003
export const APP_URL            = typeof document !== 'undefined' ? document.location.href : null

export const WELL_KNOWN_DOMAINS = {
  'htg.groundcontrol.app': {
    appName: 'web',
    stack:   'htg',
  },
  'groundcontrol.app': {
    appName: 'web',
    stack:   null,
  },
  'wizr.eu': {
    appName: 'app',
    stack:   'htg',
  },
}

export const ENV_MAP: Record<string, DataEnvironment> = {
  int: 'integration',
  acc: 'acceptance',
}

const logger = new Logger('config')

const [environment, stack, domain] = parseHostname(APP_URL)
logger.info("Loaded configuration", [
  {environment},
  {stack},
  {domain},
])

if (environment === 'local') {
  Dotenv.config({
    path: Path.resolve(__dirname, '../.env'),
  })
}

export function isServerEnv() {
  return typeof window === 'undefined'
}

export function parseHostname(href: string | null): [DataEnvironment, string | null, string | null] {
  const url     = href == null ? null : new URL(href)
  const isLocal = url == null || url.hostname === 'localhost'
  const queryDomain = url?.searchParams.get('__domain')

  if (isLocal) {
    return [
      (process.env.DATA_ENV ?? 'local') as DataEnvironment,
      process.env.APP_STACK ?? null,
      queryDomain ?? process.env.DOMAIN ?? null,
    ]
  } else {
    const [prefix, domain, domainConfig, local] = findWellKnownDomain(url.hostname)
    const {
      appName = APP_NAME,
      stack   = null,
    } = domainConfig ?? {}

    // Prefix is: <project-code>?.<app-name>.<environment>.<stack>?.<suffix>
    if (prefix[0] !== appName) { prefix.shift() }
    if (prefix[0] === appName) {
      prefix.shift()
    } else {
      console.warn("Hostname does not match app name")
    }

    const environment = Object.keys(ENV_MAP).includes(prefix[0])
      ? ENV_MAP[prefix.shift()!]
      : 'live'

    return [
      local ? 'local' : (process.env.DATA_ENV ?? environment  ?? 'local') as DataEnvironment,
      process.env.APP_STACK ?? stack ?? null,
      queryDomain ?? process.env.DOMAIN ?? domain  ?? null,
    ]
  }
}

function findWellKnownDomain(hostname: string): [string[], string, {appName: string, stack: string | null} | null, boolean] {
  // Remove a local .test or .local suffix for those that use puma-dev.
  hostname = hostname.replace(/\.(test|local)$/, '')

  for (const [suffix, config] of Object.entries(WELL_KNOWN_DOMAINS)) {
    if (hostname.endsWith(suffix)) {
      const prefix = hostname.slice(0, -suffix.length - 1)
      return [prefix.split('.'), hostname, config, false]
    }
  }

  return [[], hostname, null, false]
}

export function deriveBaseDomain() {
  switch (environment) {
    case 'local':
      return 'localhost'
    case 'integration':
      return stack == null ? 'int.groundcontrol.app' : `int.${stack}.groundcontrol.app`
    case 'acceptance':
      return stack == null ? 'acc.groundcontrol.app' : `acc.${stack}.groundcontrol.app`
    case 'live':
      return stack == null ? 'groundcontrol.app' : `${stack}.groundcontrol.app`
  }
}

export function deriveSecurityDomain() {
  if (environment === 'local') {
    return deriveBaseDomain()
  } else {
    return `${APP_NAME}.${deriveBaseDomain()}`
  }
}

export function deriveAPIBase() {
  if (environment === 'local') {
    return `http://localhost:${LOCAL_API_PORT}`
  } else {
    return `https://api.${deriveBaseDomain()}`
  }
}

export function deriveAppBase() {
  if (environment === 'local') {
    return `http://localhost:${LOCAL_PORT}`
  } else {
    return `https://${APP_NAME}.${deriveBaseDomain()}`
  }
}

export function deriveMissionControlBase() {
  if (environment === 'local') {
    return `http://localhost:${LOCAL_MISSION_PORT}`
  } else {
    return `https://mission.${deriveBaseDomain()}`
  }
}

const config = {

  version: process.env.VERSION,

  environment:            environment,
  stack:                  stack,
  domain:                 domain,
  securityDomain:         deriveSecurityDomain(),


  api: {
    baseURL:   `${deriveAPIBase()}/v5`,
    socketURI: `${deriveAPIBase()}/v5/web`,
  },

  socket: {
    defaultReconnect: 1000,
    sendTimeout:      5000,
  },

  urls: {
    app:    deriveAppBase(),
    api:    deriveAPIBase(),

    relative(path: string) {
      if (path.startsWith('/')) {
        return `${this.app}${path}`
      } else {
        return `${this.app}/${path}`
      }
    },
  },

  schedule: {
    axisInterval: 10 * 60_000,
    lookAhead:    60 * 60_000,
  },

  conference: {
    defaults: {
      interfaceConfigOverwrite: {
        'HIDE_INVITE_MORE_HEADER':      true,
        'TOOLBAR_BUTTONS':              ['microphone', 'camera', 'desktop','closedcaptions', 'raise-hand', 'fullscreen', 'select-background', 'tileview'],
        'MOBILE_APP_PROMO':             false,
        'RECENT_LIST_ENABLED':          false,
        'SHOW_JITSI_WATERMARK':         false,
        'SUPPORT_URL':                  'mailto:jelle@groundcontrol.app',
        'VIDEO_QUALITY_LABEL_DISABLED': true,

      },
      configOverwrite: {
        'apiLogLevels':           [],
        'disableTileView':        false,
        'requireDisplayName':     false,
        'prejoinPageEnabled':     false,
        'startWithAudioMuted':    true,
        'disableInviteFunctions': true,
        'hideConferenceSubject':  true,
        'hideConferenceTimer':    true,
        'hideParticipantsStats':  true,
        'p2p':                    {
          'enabled':false,
        },
      },
    },
  },

  news: {
    maxMediaCount:    4,
    allowedReactions: ['❤️','👍','😱'],
  },

  chat: {
    typingTimeout:     10_000,
    timestampInterval: '5m',
    maxReceipts:       20,
  },

  mediaUploader: {
    maxFileSize: 200 * 1024 * 1024,
  },

  livePreview: {
    namespace:  'app.groundcontrol.web.preview',
    hostOrigin: deriveMissionControlBase(),
  },

  changelog: {
    client: '/changelog.json',
    server: `${deriveAPIBase()}/changelog.json`,
  },
}

export default config
