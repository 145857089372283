import { DateTime } from 'luxon'
import config from '~/config'
import { WebScheduleItem } from '~/models'

export default class ScheduleLayout {

  constructor(
    public readonly startTime: DateTime,
    public readonly endTime:   DateTime,
    public readonly size:      Size,
    public readonly margin:    number,
  ) {}

  public get timeMargin() {
    return this.widthToInterval(this.margin) ?? 0
  }

  public dateToX(dateTime: DateTime) {
    if (this.size.width === 0) { return null }

    const min = this.startTime.valueOf()
    const max = this.endTime.valueOf()
    const time = dateTime.valueOf() + this.timeMargin

    const ratio = (time - min) / (max - min)
    return ratio * this.size.width
  }

  public widthToInterval(width: number) {
    if (this.size.width === 0) { return null }

    const ratio = width / this.size.width
    return ratio * (this.endTime.valueOf() - this.startTime.valueOf())
  }

  public itemLayout(item: WebScheduleItem) {
    const left  = this.dateToX(item.start)
    const right = this.dateToX(item.end ?? this.endTime)
    if (left == null || right == null) { return null }

    return {
      left:  left,
      width: right - left,
    }
  }

  public isVisible(item: WebScheduleItem) {
    if (item.start > this.endTime) { return false }
    if (item.end != null && item.end < this.startTime) { return false }
    return true
  }

  public get axisDates() {
    const axisDates: DateTime[] = []
    const roundedStart = DateTime.fromMillis(Math.floor(this.startTime.valueOf() / 3600_000) * 3600_000)

    for (
      let time = roundedStart;
      time <= this.endTime;
      time = time.plus(config.schedule.axisInterval)
    ) {
      axisDates.push(time)
    }

    return axisDates
  }


}