import React from 'react'
import BeWizrCourseColumn from '../bewizr/BeWizrCourseColumn'
import BeWizrCoursesColumn from '../bewizr/BeWizrCoursesColumn'
import BeWizrLearningTrackColumn from '../bewizr/BeWizrLearningTrackColumn'
import ChallengeColumn from '../challenges/ChallengeColumn'
import ChallengesColumn from '../challenges/ChallengesColumn'
import ChallengeEndpointColumn from '../challenges/ChallengesEndpointColumn'
import ChallengeTaskColumn from '../challenges/tasks/ChallengeTaskColumn'
import ConnectionsColumn from '../connections/ConnectionsColumn'
import PageColumn from '../info/PageColumn'
import NewsColumn from '../news/NewsColumn'
import PostColumn from '../news/PostColumn'
import NotificationsColumn from '../notifications/NotificationsColumn'
import ParticipantColumn from '../participants/ParticipantColumn'
import QAndAColumn from '../q-and-as/QAndAColumn'
import RankingsColumn from '../rankings/RankingsColumn'
import SearchColumn from '../search/SearchColumn'

export default function renderNavColumn(path: string) {
  if (path === '/') {
    return <PageColumn slug=''/>
  } else if (/^\/news/.test(path)) {
    return <NewsColumn/>
  } else if (/^\/posts\/-\/(.*)$/.test(path)) {
    const match = path.match(/^\/posts\/-\/(.*)$/)!
    return <PostColumn postID={match[1]}/>
  } else if (/^\/search\/(.*)\/(.*)$/.test(path)) {
    const match = path.match(/^\/search\/(.*)\/(.*)$/)!
    return <SearchColumn resource={match[1]} query={match[2]} />
  } else if (/^\/search/.test(path)) {
    return <SearchColumn/>
  } else if (/^\/notifications/.test(path)) {
    return <NotificationsColumn/>
  } else if (/^\/-\/(.*)$/.test(path)) {
    const match = path.match(/^\/-\/(.*)$/)!
    return <PageColumn slug={match[1]}/>
  } else if (/^\/challenges\/-\/(.*)\/tasks\/(.*)$/.test(path)) {
    const match = path.match(/^\/challenges\/-\/(.*)\/tasks\/(.*)$/)!
    return <ChallengeTaskColumn challengeID={match[1]} taskUUID={match[2]}/>
  } else if (/^\/challenges\/-\/(.*)$/.test(path)) {
    const match = path.match(/^\/challenges\/-\/(.*)$/)!
    return <ChallengeColumn id={match[1]}/>
  } else if (/^\/challenges(?:\/(.*))?$/.test(path)) {
    const match = path.match(/^\/challenges(?:\/(.*))?$/)!
    if (match[1] === 'open') {
      return <ChallengeEndpointColumn which='open'/>
    } else if (match[1] === 'completed') {
      return <ChallengeEndpointColumn which='completed'/>
    } else {
      return <ChallengesColumn/>
    }
  } else if (/^\/q-and-as\/-\/(.*)$/.test(path)) {
    const match = path.match(/^\/q-and-as\/-\/(.*)$/)!
    return <QAndAColumn id={match[1]}/>
  } else if (/^\/connections$/.test(path)) {
    return <ConnectionsColumn/>
  } else if (/^\/participants\/-\/(.*)$/.test(path)) {
    const match = path.match(/^\/participants\/-\/(.*)$/)!
    return <ParticipantColumn id={match[1]}/>
  } else if (/^\/rankings\/-\/(.*)$/.test(path)) {
    const match = path.match(/^\/rankings\/-\/(.*)$/)!
    return <RankingsColumn competitionID={match[1]}/>
  } else if (/\/bewizr-courses\/-\/(.*)$/.test(path)) {
    const match = path.match(/\/bewizr-courses\/-\/(.*)$/)!
    return <BeWizrCourseColumn slug={match[1]}/>
  } else if (/\/bewizr-courses\/learning-tracks\/-\/(.*)$/.test(path)) {
    const match = path.match(/\/bewizr-courses\/learning-tracks\/-\/(.*)$/)!
    return <BeWizrLearningTrackColumn id={match[1]}/>
  } else if (/\/bewizr-courses/.test(path)) {
    return <BeWizrCoursesColumn/>
  } else {
    console.warn(`Unknown column path: ${path}`)
    return null
  }
}