import Timer from 'react-timer'
import { DateTime } from 'luxon'
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import { LiveSchedule, WebScheduleItem, WhatsOn } from '~/models'
import clockStore from '../clockStore'
import previewStore, { PreviewStore } from '../previewStore'

export default class LiveScheduleController {

  constructor(
    schedule: LiveSchedule,
  ) {
    this.schedule = schedule
    makeObservable(this)

    this.disposers = [
      reaction(() => clockStore.serverTime(DateTime.local()), () => this.reschedule()),
      reaction(() => this.keyTimes, () => this.reschedule()),
      reaction(() => previewStore.currentTime, () => this.updateTimeFromPreview()),
    ]
  }

  private disposers: IReactionDisposer[] = []

  public dispose() {
    this.disposers.forEach(disposer => disposer())
  }

  @observable
  public schedule: LiveSchedule

  @observable
  private lastKeyTime: DateTime | null = null

  @action
  public setSchedule(schedule: LiveSchedule) {
    this.schedule = schedule
  }

  @computed
  public get scheduleItems(): WebScheduleItem[] {
    return this.schedule.items
      .filter(it => it.showInSchedule)
      .sort((a, b) => a.start.valueOf() - b.start.valueOf())
  }

  @computed
  public get scheduleItemsFlattened(): WebScheduleItem[] {
    const items:          WebScheduleItem[] = []
    const permanentStack: WebScheduleItem[] = []
    const dayStack:       WebScheduleItem[] = []

    const ops: Array<[DateTime, WebScheduleOperation]> = []
    for (const item of this.scheduleItems) {
      const stack = item.permanent ? permanentStack : dayStack
      ops.push([item.start, {push: item, stack}])
      ops.push([item.end, {remove: item, stack}])
    }
    ops.sort((a, b) => a[0].diff(b[0]).milliseconds)

    for (const [index, [time, operation]] of ops.entries()) {
      if ('push' in operation) {
        operation.stack.push(operation.push)
      } else {
        operation.stack.splice(operation.stack.indexOf(operation.remove), 1)
      }

      const stack       = [...permanentStack, ...dayStack]
      const topMostItem = stack[stack.length - 1]
      if (topMostItem == null) { continue }

      const nextTime = ops[index + 1]?.[0] ?? null
      const possibleEndTimeValues = [
        topMostItem.end.valueOf(),
        ...(nextTime == null ? [] : [nextTime.valueOf()]),
      ]
      const start = time
      const end   = DateTime.fromMillis(Math.min(...possibleEndTimeValues))

      items.push({...topMostItem, start, end} as WebScheduleItem)
    }

    return items
  }

  @computed
  public get whatsOn() {
    return new WhatsOn(this.whatsOnItems)
  }

  private get whatsOnItems() {
    const time = this.lastKeyTime
    if (time == null) { return [] }

    return this.schedule.items.filter(item => {
      if (time < item.start) { return false }
      if (!item.keepRunning && time >= item.end) { return false }
      return true
    })
  }

  @computed
  public get current(): WebScheduleItem | null {
    const time = this.lastKeyTime
    if (time == null) { return null }

    const scheduleItemsReversed = [...this.schedule.items].reverse()
    return scheduleItemsReversed.find(item => {
      if (time < item.start) { return false }
      if (!item.keepRunning && time >= item.end) { return false }
      return true
    }) ?? null
  }

  @computed
  public get nextUp(): WebScheduleItem | null {
    const time = this.lastKeyTime
    if (time == null) { return null }

    return this.scheduleItems.find(it => it.start > time) ?? null
  }

  //------
  // Scheduling

  private timers: Timer[] = []

  @computed
  private get keyTimes() {
    const timestamps = new Set<number>()
    for (const {start, end} of this.schedule.items) {
      timestamps.add(start.valueOf())
      timestamps.add(end.valueOf())
    }

    return Array.from(timestamps)
      .map(ts => DateTime.fromMillis(ts))
      .filter(time => time.diffNow().milliseconds >= 0)
  }

  @action
  private updateTimeFromPreview() {
    if (!PreviewStore.previewing) {
      this.lastKeyTime = DateTime.local()
    } else {
      this.lastKeyTime = previewStore.currentTime
    }
  }

  @action
  private reschedule() {
    this.timers.forEach(timer => timer.dispose())

    const currentTime = PreviewStore.previewing ? previewStore.currentTime : DateTime.local()
    const now = clockStore.serverTime(currentTime)
    if (now == null) { return }

    for (const serverTime of this.keyTimes) {
      const msFromNow = serverTime.diff(now).milliseconds
      const timer = new Timer()
      timer.setTimeout(() => this.tick(serverTime), msFromNow)
      this.timers.push(timer)
    }

    this.tick(now)
  }

  @action
  private tick = (time: DateTime) => {
    this.lastKeyTime = time
  }

}

type WebScheduleOperation =
  | {push: WebScheduleItem, stack: WebScheduleItem[]}
  | {remove: WebScheduleItem, stack: WebScheduleItem[]}