import { DateTime } from 'luxon'
import { SVGName } from '~/ui/components/SVG'
import { CustomImage, SVGImage } from './CustomImage'
import { Link } from './Link'
import { Model } from './Model'
import { datetime } from './serialization'
import { Language } from './Translations'

export class Project extends Model {

  public code!: string
  public name!: string
  public logo!: CustomImage

  @datetime()
  public startDate!: DateTime | null

  @datetime()
  public endDate!: DateTime | null

  public features!: ProjectFeature[]
  public tabs!:     ClientTab[]
  public web!:      WebConfig

  public apnTopic!:  string | null
  public urlScheme!: string | null

  public feature(name: string) {
    const feature = this.features.find(f => f.name === name)
    if (feature == null || !feature.enabled) { return null }

    return feature
  }

}

export interface WebConfig {
  layout: WebLayout
}

export type WebLayout = 'live' | 'chat' | 'full'

export interface ProjectFeature {
  name:    string
  enabled: boolean
  options: AnyObject
}

export interface ClientTab {
  link:    Link
  caption: Record<Language, string>
  icon:    {
    type:  'named'
    value: SVGName
  } | {
    type: 'custom'
    value: SVGImage
  }
}

export interface ProjectLocation {
  // The center coordinate of this Project's location.
  coordinate: {latitude: number, longitude: number}

  // The radius for the location.
  radius: number

  // An optional name for the location.
  name: string | null
}