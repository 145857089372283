import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { breakoutStore, ChatService, chatStore, profileStore } from '~/stores'
import { registerChatMessageNotification } from '~/ui/app/notifications/ChatMessageNotification'
import { observer } from '~/ui/component'

interface HomeChatContext {
  service: ChatService | null
}

const HomeChatContext = React.createContext<HomeChatContext>({
  service: null,
})

export interface HomeChatContainerProps {
  children: React.ReactNode
}

export const HomeChatContainer = observer('HomeChatContainer', (props: HomeChatContainerProps) => {

  const {children} = props

  const {participantID} = profileStore

  const service = React.useMemo(() => {
    if (participantID == null) { return null }

    const initialBreakoutGroupID = breakoutStore.breakoutGroupID
    return chatStore.chatService(participantID, {initialBreakoutGroupID})
  }, [participantID])

  const breakoutGroupID = breakoutStore.breakoutGroupID
  React.useEffect(() => {
    if (service == null) { return }
    service.switchToBreakoutGroup(breakoutGroupID)
  }, [breakoutGroupID, service])

  React.useEffect(() => {
    if (service == null) { return }
    return registerChatMessageNotification(service)
  }, [service])

  useOnDemandService(service)

  const context = React.useMemo((): HomeChatContext => ({
    service,
  }), [service])

  return (
    <HomeChatContext.Provider value={context}>
      {children}
    </HomeChatContext.Provider>
  )

})

export function useHomeChat() {
  return React.useContext(HomeChatContext)
}