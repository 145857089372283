import React from 'react'
import { NotificationHost } from 'react-notifications'
import { Route, Switch } from 'react-router-dom'
import HomeChat from '~/ui/app/home/HomeChat'
import { memo } from '~/ui/component'
import { Panel, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { height as panelNavHeaderHeight } from '../PanelNavHeader'
import ProfileContainer from '../profile/ProfileContainer'
import { NavigationStateContainer } from './NavigationStateContext'
import NavigationTabBar from './NavigationTabBar'
import NavPanel from './NavPanel'

const CollapsedNavContainer = memo('CollapsedNavContainer', () => {

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.CollapsedNavContainer}>
        <VBox flex classNames={$.content}>
          <Switch>
            <Route path='/chat' exact component={ChatPanelWrapper}/>
            <Route path='/profile' component={ProfileWrapper}/>
            <Route component={NavPanelWrapper}/>
          </Switch>
        </VBox>
        <NavigationTabBar/>
        <NotificationHost
          className={$.notificationHost}
          padding={layout.padding.inline.l}
        />
      </VBox>
    )
  }

  return render()

})

const ChatPanelWrapper = memo('ChatPanelWrapper', () => {
  const $ = useStyles()
  return (
    <NavigationStateContainer>
      <HomeChat classNames={$.panel} />
    </NavigationStateContainer>
  )
})

const ProfileWrapper = memo('ProfileWrapper', () => {
  const $ = useStyles()
  return (
    <Panel flex classNames={$.panel}>
      <ProfileContainer/>
    </Panel>
  )
})

const NavPanelWrapper = memo('NavPanelWrapper', () => {
  const $ = useStyles()
  return (
    <NavigationStateContainer>
      <NavPanel flex classNames={$.panel} />
    </NavigationStateContainer>
  )
})

export default CollapsedNavContainer

const useStyles = createUseStyles(theme => {
  const tabBarHasBorder = theme.guide.web.tabBar.border().width > 0
  return {
    CollapsedNavContainer: {
    },

    content: {
      position: 'relative',
      zIndex:   layout.z.body + 1,
    },

    panel: {
      borderBottomLeftRadius:  tabBarHasBorder && 0,
      borderBottomRightRadius: tabBarHasBorder && 0,
    },

    notificationHost: {
      top: panelNavHeaderHeight,
      zIndex: layout.z.bodyTop,
    },
  }
})