import { findLast } from 'lodash'
import { computed } from 'mobx'
import { Document } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrCourse, BeWizrCourseItem } from '~/models'

export default class BeWizrCourseDocument extends Document<BeWizrCourse> {

  @computed
  public get slug() {
    return this.id
  }

  @computed
  public get lastVisitedCourseItem(): BeWizrCourseItem | null {
    const items = this.data?.items ?? []
    if (items.length === 0) { return null }

    const allItems = items.reduce((items: BeWizrCourseItem[], item) => ([
      ...items,
      item,
      ...item.items ?? [],
    ]), [])
    const item = findLast(allItems, item => item.stats != null && item.stats.status != null) ?? items[0]

    if (item.items != null && item.items.length > 0) {
      return item.items[0]
    } else {
      return item
    }
  }

  protected async performFetch() {
    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get(`course/${this.id}/`)
    if (response.status !== 200) { return response }

    const data = BeWizrCourse.deserialize(response.data)

    return {
      data,
      meta: {},
    }
  }

}