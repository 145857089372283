import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { ClientTab } from '~/models'
import { bewizrStore, connectionsStore, projectStore } from '~/stores'
import { useHomeChat } from '~/ui/app/home/HomeChatContext'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import AppSideBarMoreButton from './AppSideBarMoreButton'
import AppSideBarNavigationButton from './AppSideBarNavigationButton'

const AppSideBarNavigationTabs = observer('AppSideBarNavigationTabs', () => {

  const [t] = useTranslation()

  const tabs = React.useMemo(
    () => projectStore.tabs.filter(it => /^(?!\/\/chat).*/.test(it.link.href)),
    [],
  )

  const {service: chatService} = useHomeChat()

  const activeTabForPath = React.useCallback(
    (path: string) => projectStore.activeTabForPath(path),
    [],
  )

  const badgeForTab = React.useCallback((tab: ClientTab) => {
    if (/^\/\/connections/.test(tab.link.href)) {
      return connectionsStore.requestCount
    } else if (/^\/\/chat/.test(tab.link.href)) {
      return chatService?.totalUnreadCount
    }
  }, [chatService])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.xl} classNames={$.AppSideBarNavigation}>
        <VBox gap={layout.padding.inline.s}>
          {renderTabs()}
        </VBox>
        <VBox gap={layout.padding.inline.s}>
          {renderDashboardButton()}
          <AppSideBarMoreButton/>
        </VBox>
      </VBox>
    )
  }

  const renderTabs = React.useCallback(() => tabs.map((tab): React.ReactNode => (
      <AppSideBarNavigationButton
        key={tab.link.href}
        icon={tab.icon.value}
        link={tab.link}
        caption={tab.caption[i18next.language]}
        badge={badgeForTab(tab)}
        active={path => tab === activeTabForPath(path)}
      />
  )), [activeTabForPath, badgeForTab, tabs])

  function renderDashboardButton() {
    if (!bewizrStore.profile?.hasDashboardAccess) { return null }

    return (
      <AppSideBarNavigationButton
        icon='column-chart'
        caption={t('app:nav.dashboard')}
        link={{href: 'https://be.wizr.eu/dashboard/'}}
      />
    )
  }

  return render()

})

export default AppSideBarNavigationTabs

const useStyles = createUseStyles(theme => ({
  AppSideBarNavigation: {
    margin: [0, layout.padding.inline.l * -1],
  },
}))