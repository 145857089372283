import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useHistory } from '~/history'
import SummaryPanel from '~/ui/app/live/SummaryPanel'
import CollapsedNavContainer from '~/ui/app/navigation/CollapsedNavContainer'
import Schedule from '~/ui/app/schedule/Schedule'
import { memo } from '~/ui/component'
import { HBox, SegmentedButton, VBox } from '~/ui/components'
import { ButtonSegment } from '~/ui/components/SegmentedButton'
import { createUseStyles, layout } from '~/ui/styling'
import { useLiveService } from '../live/LiveContext'
import WebViewer from '../live/WebViewer'

export interface Props {}

const MobileLiveLayout = memo('MobileLiveLayout', (props: Props) => {

  const [t] = useTranslation('webcast')

  const history  = useHistory()
  const location = useLocation()

  const service = useLiveService()
  const current = service.current

  const [view, setView] = React.useState<MobileView>(
    current == null ? 'chat' : 'live',
  )

  const viewSegments = React.useMemo((): ButtonSegment<MobileView>[] => [
    {value: 'live', caption: t('viewer.title'), icon: 'webcast'},
    {value: 'chat', caption: t('chat.title'), icon: 'chat'},
    {value: 'schedule', caption: t('schedule.title'), icon: 'info'},
  ], [t])

  const handleViewChange = React.useCallback((value: MobileView) => {
    if (location.pathname !== '/') {
      history.replace('/')
    }
    if (view !== value) {
      setView(value)
    }
  }, [history, location.pathname, view])

  // When navigating to a page, ensure the correct tab opens to display it
  React.useEffect(() => {
    if (location.pathname !== '/') {
      setView('chat')
    }
  }, [location])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex align='stretch'>
        <HBox padding={layout.padding.xs} justify='center'>
          <SegmentedButton segments={viewSegments} onChange={handleViewChange} selectedValue={view} />
        </HBox>
        <VBox flex='both' justify='top'>
          <VBox flex='both' classNames={view !== 'live' && $.hidden}>
            <WebViewer />
          </VBox>
          <VBox flex='both' classNames={view !== 'chat' && $.hidden}>
            <CollapsedNavContainer />
          </VBox>
          <VBox flex='both' classNames={view !== 'schedule' && $.hidden}>
            <SummaryPanel/>
            <Schedule/>
          </VBox>
        </VBox>
      </VBox>
    )
  }

  return render()

})

export default MobileLiveLayout

const useStyles = createUseStyles({
  hidden: {
    opacity: 0,
    position: 'absolute',
    pointerEvents: 'none',
  },
})

export type MobileView = 'live' | 'chat' | 'schedule'