import React from 'react'
import { useLocation } from 'react-router-dom'
import Color from 'color'
import i18next from 'i18next'
import { useHistory } from '~/history'
import { ClientTab, isCustomImage, Link, SVGImage } from '~/models'
import { isInternalHref, resolveHref } from '~/navigation'
import { connectionsStore, projectStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import {
  Badge,
  BrandedComponent,
  Center,
  HBox,
  panelBorderRadius,
  SVG,
  Tappable,
  TappableProps,
  VBox,
} from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { useHomeChat } from '../home/HomeChatContext'
import { ImageView } from '../media'

const NavigationTabBar = observer('NavigationTabBar', () => {

  const tabs = projectStore.tabs

  const {service: chatService} = useHomeChat()

  const {guide} = useStyling()

  const activeTabForPath = React.useCallback(
    (path: string) => projectStore.activeTabForPath(path),
    [],
  )

  const badgeForTab = React.useCallback((tab: ClientTab) => {
    if (/^\/\/connections/.test(tab.link.href)) {
      return connectionsStore.requestCount
    } else if (/^\/\/chat/.test(tab.link.href)) {
      return chatService?.totalUnreadCount
    }
  }, [chatService])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (tabs.length < 2) { return null }

    return (
      <BrandedComponent classNames={$.NavigationTabBar} branding={guide.web.tabBar} height={height}>
        <HBox flex align='stretch'>
          {renderTabs()}
        </HBox>
      </BrandedComponent>
    )
  }

  const renderTabs = React.useCallback(() => tabs.map((tab): React.ReactNode => (
      <NavigationTab
        key={tab.link.href}
        icon={tab.icon.value}
        link={tab.link}
        caption={tab.caption[i18next.language]}
        badge={badgeForTab(tab)}
        active={path => tab === activeTabForPath(path)}
      />
  )), [activeTabForPath, badgeForTab, tabs])

  return render()

})

export default NavigationTabBar

interface NavigationTabProps {
  icon?:   SVGImage | SVGName
  caption: string
  link:    Link
  badge?:  Badge
  active:  (path: string) => boolean
  onTap?:  TappableProps['onTap']
}

const NavigationTab = memo('NavigationTab', (props: NavigationTabProps) => {

  const location = useLocation()
  const history  = useHistory()

  const {icon, caption, link, badge, onTap: props_onTap} = props
  const active = props.active(location.pathname)

  const {guide} = useStyling()

  const href = link != null? resolveHref(link?.href) : undefined

  const branding = guide.web.tabBar

  const onTap = React.useCallback((event: React.SyntheticEvent<any, any>) => {
    if (href != null && isInternalHref(href)) {
      history.reset(href)
      event.preventDefault()
    }
    props_onTap?.(event)
  }, [history, href, props_onTap])

  //------
  // Rendering

  const iconColor       = branding.resolve('iconColor')
  const activeIconColor = branding.resolve('activeIconColor')

  const fgColor   = guide.colors.resolve(active ? activeIconColor : iconColor)
  const overrides = React.useMemo(() => ({
    fg: {
      normal: fgColor,
    },
  }), [fgColor])

  const $ = useStyles(activeIconColor)

  function render() {
    return (
      <ThemeProvider overrides={overrides}>
        <Tappable flex classNames={[$.NavigationTab, {active}]} onTap={onTap} showFocus={true} title={caption} href={href} target={link?.target}>
          <VBox flex justify='middle' gap={layout.padding.inline.s}>
            <Center classNames={$.navigationTabContent}>
              {renderIcon()}
            </Center>
            <Badge
              classNames={$.navigationTabBadge}
              value={badge}
            />
          </VBox>
        </Tappable>
      </ThemeProvider>
    )
  }

  function renderIcon() {
    if (isCustomImage(icon)) {
      return (
        <ImageView
          source={icon}
          size={layout.icon.l}
          classNames={[$.navigationTabIcon, {active}]}
        />
      )
    }
    return (
      <SVG
        name={icon}
        size={layout.icon.l}
      />
    )
  }

  return render()

})

export const height   = layout.barHeight.xl

const useStyles = createUseStyles(theme => {
  const overshoot    = panelBorderRadius(theme)
  const borderRadius = theme.guide.web.tabBar.borderRadius(height)
  const hasBorder    = theme.guide.web.tabBar.border().width > 0

  return {
    NavigationTabBar: {
      position: hasBorder && 'relative',

      // If the tab bar has a border, place it over the column content. Otherwise, place it behind.
      zIndex:   hasBorder && layout.z.body + 4,

      height: hasBorder ? height : height + overshoot,

      marginTop:  !hasBorder && -overshoot,
      paddingTop: !hasBorder && overshoot,

      borderTopLeftRadius:  0,
      borderTopRightRadius: 0,

      borderLeftWidth:   0,
      borderBottomWidth: 0,
      borderRightWidth:  0,
    },

    NavigationTab: {
      position:     'relative',
      zIndex:       layout.z.body + 4,
      padding:      layout.padding.inline.m,
      borderRadius: [0, 0, borderRadius, borderRadius],

      '&:hover': {
        background: theme.colors.bg.light.hover,
      },
    },

    navigationTabContent: {
      position: 'relative',
    },

    navigationTabIcon: (activeIconColor: Color) => ({
      '& [fill]': {
        fill: 'currentColor',
      },
      '&.active [fill]': {
        fill: activeIconColor,
      },
    }),

    navigationTabBadge: {
      position: 'absolute',
      top:      -badgeSize.normal.height * 2 / 3,
      right:    -badgeSize.normal.minWidth * 2 / 3,
    },
  }
})

export type Badge = string | number | true | null