import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { LiveService, liveStore } from '~/stores'
import { memo } from '~/ui/component'

interface LiveContext {
  service: LiveService | null
}

const LiveContext = React.createContext<LiveContext>({
  service: null,
})

export interface LiveContainerProps {
  children?: React.ReactNode
}

export const LiveContainer = memo('LiveContainer', (props: LiveContainerProps) => {

  const {children} = props

  const service = useOnDemandService(liveStore.liveService())
  const context = React.useMemo((): LiveContext => ({
    service,
  }), [service])

  return (
    <LiveContext.Provider value={context}>
      {children}
    </LiveContext.Provider>
  )

})

export function useLiveService() {
  const context = React.useContext(LiveContext)
  if (context.service == null) {
    throw new Error("useLiveService() must be called from within <LiveContainer/>")
  }

  return context.service
}