import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { isPlainObject } from 'lodash'
import config from '~/config'
import { OAuthProvider } from '~/models'
import { appStore, authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { BrandedComponent, HBox, Label, SVG, Tappable } from '~/ui/components'
import { createUseStyles, layout, shadows, useStyling } from '~/ui/styling'
import { ImageView } from '../../media'

export interface Props {
  provider: OAuthProvider
}

const OAuthButton = observer('OAuthButton', (props: Props) => {

  const {provider} = props
  const {name, logo, translations} = provider

  const app = appStore.app

  const [t] = useTranslation('auth')

  const caption = React.useMemo(() => {
    const language = i18next.language
    return translations[language].caption ?? t('login.oauth.caption', {
      name: translations[language].name ?? name,
    })
  }, [translations, name, t])

  const authWindowRef = React.useRef<Window | null>(null)

  const handleWindowMessage = React.useCallback((event: MessageEvent) => {
    if (!isPlainObject(event.data)) { return }
    if (event.origin !== config.urls.api) { return }
    if (event.source !== authWindowRef.current) { return }

    if (event.data.type === 'oauth:complete') {
      authenticationStore.storeOAuthTokens(name, event.data.payload.oAuthTokens)
      authenticationStore.authenticateWithToken(event.data.payload.authToken)
      authWindowRef.current?.close()
    }
  }, [name])

  const startAuthenticationFlow = React.useCallback(async () => {
    const url = new URL(`${config.urls.api}/v3/oauth/${name}/init`)
    url.searchParams.set('platform', 'web')
    if (app != null) {
      url.searchParams.set('app', app.id)
    }

    authWindowRef.current = window.open(url, undefined, 'width=640,height=560')
    window.addEventListener('message', handleWindowMessage)

    return () => {
      window.removeEventListener('message', handleWindowMessage)
      authWindowRef.current = null
    }
  }, [handleWindowMessage, name, app])

  //------
  // Rendering

  const $ = useStyles()

  const {guide} = useStyling()

  const branding = guide.web.login.oAuthButton
  const height   = branding.resolve('height')

  function render() {
    return (
      <BrandedComponent classNames={$.OAuthButton} branding={branding} height={height} style={{height}}>
        <Tappable flex onTap={startAuthenticationFlow}>
          <HBox flex padding={layout.padding.inline.l} gap={layout.padding.inline.m} justify='center' align='middle'>
            {renderLogo()}
            {renderCaption()}
          </HBox>
        </Tappable>
      </BrandedComponent>
    )
  }

  function renderLogo() {
    if (logo == null) {
      return (
        <SVG
          name='login'
          size={layout.icon.s}
        />
      )
    }

    if (logo.type === 'svg') {
      return (
        <SVG
          svg={logo.data}
          size={logoSize}
        />
      )
    } else {
      return (
        <ImageView
          source={`data:image/png;base64,${logo.base64}`}
          size={logoSize}
        />
      )
    }
  }

  function renderCaption() {
    return (
      <Label small bold>
        {caption}
      </Label>
    )
  }

  return render()

})

export default OAuthButton

export const logoSize = layout.icon.l

const useStyles = createUseStyles(theme => ({
  OAuthButton: {
    '&:focus-within > *': {
      outline:      'none',
      borderRadius: 'inherit',
      boxShadow: [
        shadows.depth(theme.guide.web.login.oAuthButton.resolve('depth') ?? 1),
        shadows.focus.bold(theme),
      ],
    },

    '& > ::before': {
      content:       '""',
      pointerEvents: 'none',
      ...layout.overlay,
    },

    '&:hover > ::before': {
      background: theme.colors.bg.hover,
    },

    '&:active > ::before': {
      background: theme.colors.bg.light.active,
    },
  },
}))