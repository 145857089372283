import { Document } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLearningTrack } from '~/models'

export default class BeWizrLearningTrackDocument extends Document<BeWizrLearningTrack, string, {}> {

  protected async performFetch() {
    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get(`learning_track/${this.id}/`)
    if (response.status !== 200) { return response }

    const data = BeWizrLearningTrack.deserialize(response.data)

    return {
      data,
      meta: {},
    }
  }
}