import { DateTime } from 'luxon'
import { CustomImage } from '~/models'
import { Model } from './Model'
import { datetime } from './serialization/serializers'

export class LiveSchedule extends Model {

  public static empty() {
    return LiveSchedule.deserialize({
      items: [],
    })
  }

  @datetime('start')
  @datetime('end')
  public items!: WebScheduleItem[]

}

export interface WebScheduleItem<T extends WebContentItem = WebContentItem> extends WebContentItemCommon {
  uuid:    string
  type:    T['type']

  start:       DateTime
  end:         DateTime
  permanent:   boolean
  keepRunning: boolean

  content: WebScheduleContent<T>[]

  breakout:       boolean
  showInSchedule: boolean
}

export interface WebScheduleContent<T extends WebContentItem> {
  group: GroupDescriptor | null
  item:  Omit<T, 'type' | keyof WebContentItemCommon>
}

export interface GroupDescriptor {
  id:   string
  name: string
}

export type WebContentItem = Webcast | CallToAction | WebInfo

export interface WebContentItemCommon {
  title:   string
  image:   CustomImage | null
  detail:  string | null
}

export interface Webcast extends WebContentItemCommon {
  type:         'webcast'
  announcement: string | null
  content:      WebcastContent | null
}

export interface WebInfo extends WebContentItemCommon {
  type:  'info'
  body:  string
}

export interface CallToAction extends WebContentItemCommon {
  type:    'call-to-action'
  href:    string
  prompt:  string | null
  caption: string
}

export type WebcastContent = WebcastURLContent | WebcastConferenceContent

export interface WebcastURLContent {
  type: 'url'
  url:  string
}

export interface WebcastConferenceContent {
  type:     'conference'
  roomName: string
  url:      string
  token:    string
}