import React from 'react'
import { useSize } from 'react-measure'
import { DateTime } from 'luxon'
import config from '~/config'
import { useClock } from '~/socket/useClock'
import { observer } from '~/ui/component'
import { BrandedComponent } from '~/ui/components'
import { createUseStyles, useStyling } from '~/ui/styling'
import { useLiveService } from '../live/LiveContext'
import ScheduleAxis from './ScheduleAxis'
import ScheduleLayout from './ScheduleLayout'
import ScheduleTimeline from './ScheduleTimeline'

export interface Props {
  startTime?: DateTime
  endTime?:   DateTime
  padding?:   number
}

const Schedule = observer('Schedule', (props: Props) => {

  const service = useLiveService()
  const items = service.scheduleItemsFlattened

  const {currentTime} = useClock()
  const {guide} = useStyling()

  const {
    startTime = currentTime ?? DateTime.local(),
    endTime   = (currentTime ?? DateTime.local()).plus(config.schedule.lookAhead),
    padding   = 0,
  } = props

  //------
  // Layout

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [size, setSize] = React.useState<Size>({width: 0, height: 0})
  useSize(containerRef, setSize)

  const layout = React.useMemo(
    () => new ScheduleLayout(startTime, endTime, size, padding),
    [endTime, padding, size, startTime],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (currentTime == null) { return null }

    return (
      <BrandedComponent.Selector branding={guide.web.schedule} classNames={$.schedule} ref={containerRef}>
        <div classNames={$.timelineContainer}>
          <ScheduleTimeline
            items={items}
            layout={layout}
            currentTime={currentTime}
          />
        </div>
        <ScheduleAxis
          layout={layout}
          currentTime={currentTime}
        />
      </BrandedComponent.Selector>
    )
  }

  return render()

})

export default Schedule

const useStyles = createUseStyles({
  schedule: {
    overflow: 'hidden',
  },

  timelineContainer: {
    position: 'relative',
  },
})