import React from 'react'
import bewizrStore from '~/stores/bewizrStore'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller } from '~/ui/components'
import { NavColumnHelmet } from '../navigation'
import BeWizrCourseView from './courses/BeWizrCourseView'

export interface Props {
  slug: string
}

const BeWizrCourseColumn = observer('BeWizrCourseColumn', (props: Props) => {

  const {slug} = props

  const authenticated = bewizrStore.authenticated

  const document    = bewizrStore.courses.document(slug)
  const course      = document.data
  const fetchStatus = document.fetchStatus

  React.useEffect(() => {
    if (!authenticated) { return }
    document?.fetch()
  }, [document, authenticated])

  //------
  // Rendering

  function render() {
    return (
      <>
        <NavColumnHelmet
          title={course?.title ?? null}
          fetchStatus={fetchStatus}
        />
        {renderContent()}
      </>
    )
  }

  function renderContent() {
    if (course == null || fetchStatus !== 'done') {
      return renderEmpty()
    }

    return (
      <Scroller>
        <BeWizrCourseView course={course}/>
      </Scroller>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        flex
        status={fetchStatus}
      />
    )
  }

  return render()

})

export default BeWizrCourseColumn