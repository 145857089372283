import React from 'react'
import { useMediaQuery } from 'react-responsive'
import MobileLiveLayout from '~/ui/app/home/MobileLiveLayout'
import { LiveContainer } from '~/ui/app/live/LiveContext'
import TabletLiveLayout from '~/ui/app/live/TabletLiveLayout'
import { observer } from '~/ui/component'
import { AppHeader } from '~/ui/layouts'
import { screenMinWidths } from '~/ui/styling/layout'

const LiveLayout = observer('LiveLayout', () => {

  const isTablet  = useMediaQuery({minWidth: screenMinWidths.tablet})
  const isMobile  = !isTablet

  function render() {
    return (
      <LiveContainer>
        <AppHeader/>
        {isMobile ? (
          <MobileLiveLayout/>
        ) : (
          <TabletLiveLayout/>
        )}
      </LiveContainer>
    )
  }

  return render()

})

export default LiveLayout