import React from 'react'
import { useTranslation } from 'react-i18next'
import { Ranking } from '~/models'
import { rankingsStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Center, EmptyOrFetching, Label, List, ListBar } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider, useTheme } from '~/ui/styling'
import { Avatar } from '../media'
import { NavColumnHelmet } from '../navigation'

export interface Props {
  competitionID: string
}

const RankingsColumn = observer('RankingsColumn', (props: Props) => {

  const {competitionID} = props

  const [t] = useTranslation('participants')

  const rankingsEndpoint = rankingsStore.rankings

  const fetch = React.useCallback(() => {
    rankingsStore.fetch(competitionID)
  }, [competitionID])

  React.useEffect(fetch, [fetch])

  const fetchStatus = rankingsEndpoint?.fetchStatus ?? 'idle'

  //------
  // Rendering

  function render() {
    return (
      <>
        <NavColumnHelmet
          title={rankingsEndpoint?.meta?.competitionCaption ?? null}
          reload={fetch}
        />
        <List
          data={rankingsEndpoint?.data ?? []}
          renderItem={renderRanking}
          EmptyComponent={renderEmpty}
          contentPadding={layout.padding.s}
          itemGap={layout.padding.xs}
          scrollable
        />
      </>
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        flex
        status={fetchStatus}
        {...t('profile.rankings.empty')}
      />
    )
  }, [fetchStatus, t])

  const renderRanking = React.useCallback((ranking: Ranking) => {
    return (
      <RankingBar ranking={ranking}/>
    )
  }, [])

  return render()

})

export default RankingsColumn

export interface RankingBarProps {
  ranking: Ranking
}

const RankingBar = memo('RankingBar', (props: RankingBarProps) => {

  const {ranking} = props

  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListBar
        image={renderAvatar()}
        caption={ranking.player.name}
        accessory={renderScore()}
        selected={ranking.isMe}
      />
    )
  }

  function renderAvatar() {
    return (
      <Center classNames={$.rankingAvatar}>
        <Avatar
          source={ranking.player.photoURL}
          size={avatarSize}
          firstName=''
        />
        {renderRank()}
      </Center>
    )
  }

  function renderRank() {
    return (
      <ThemeProvider dark>
        <Center classNames={$.rank}>
          <Label h3>
            {ranking.rank}
          </Label>
        </Center>
      </ThemeProvider>
    )
  }

  function renderScore() {
    return (
      <Label bold color={theme.semantic.primary}>
        {ranking.normalized?.formatted ?? ranking.score.formatted}
      </Label>
    )
  }

  return render()

})

export const avatarSize = layout.icon.xxl

const useStyles = createUseStyles({
  RankingsColumn: {
    flex: 1,
  },

  rankingAvatar: {
    position: 'relative',
  },

  rank: {
    ...layout.overlay,
    background: colors.shim.dark,
    borderRadius: avatarSize.height / 2,
  },
})