import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from '~/history'
import { Answer, validateFeedback } from '~/models'
import { challengesStore } from '~/stores'
import NavColumnHelmet from '~/ui/app/navigation/NavColumnHelmet'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Label, Scroller, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ChallengeTaskContent from './ChallengeTaskContent'
import ChallengeTaskNavigation from './ChallengeTaskNavigation'
import ChallengeTaskNavigationContext from './ChallengeTaskNavigationContext'

export interface Props {
  challengeID: string
  taskUUID:    string
}

const ChallengeTaskColumn = observer('ChallengeTaskColumn', (props: Props) => {

  const {challengeID, taskUUID} = props

  const document  = challengesStore.challenges.document(challengeID)
  const challenge = document.data ?? null
  const state     = document.state
  const task      = challenge?.tasks.find(it => it.uuid === taskUUID) ?? null
  const taskIndex = challenge?.tasks.findIndex(it => it.uuid === taskUUID) ?? -1

  const previousTask   = challenge?.tasks?.[taskIndex - 1] ?? null
  const nextTask       = challenge?.tasks?.[taskIndex + 1] ?? null
  const mayAnswer      = (document.meta?.mayAnswer && document.answers[taskUUID] == null) ?? false
  const results        = document.results.filter(result => result.uuid === taskUUID)
  const correctAnswers = document.correctAnswers[taskUUID] ?? []
  const answer         = document.answers[taskUUID] as Answer | null
  const currentAnswer  = challengesStore.getCurrentAnswer(challengeID, taskUUID)

  const mayContinue = React.useMemo(() => {
    if (task == null) { return false }
    if (task.question == null) { return true }
    if (task.question.skippable) { return true }

    return validateFeedback(task.question, currentAnswer)
  }, [currentAnswer, task])


  const [t] = useTranslation('challenges')

  const fetch = React.useCallback(() => {
    document?.fetch()
  }, [document])

  React.useEffect(() => {
    document?.fetchIfNeeded()
  }, [document])

  //------
  // Callbacks

  const history = useHistory()

  const close = React.useCallback(() => {
    history.goBack({
      fallbackPath: `/challenges/-/${challengeID}`,
    })
  }, [challengeID, history])

  const goToPreviousTask = React.useCallback(() => {
    if (previousTask == null) { return }

    history.replace(`/challenges/-/${challengeID}/tasks/${previousTask.uuid}`)
  }, [challengeID, history, previousTask])

  const goToNextTask = React.useCallback(() => {
    if (nextTask == null) { return }
    history.replace(`/challenges/-/${challengeID}/tasks/${nextTask.uuid}`)
  }, [challengeID, history, nextTask])

  const completeTask = React.useCallback(async () => {
    if (challenge == null) { return false }

    challengesStore.completeTask(challengeID, taskUUID)

    if (nextTask != null) {
      goToNextTask()
      return true
    } else {
      const success = await challengesStore.completeChallenge(challengeID)
      if (success) { close() }
      return success
    }
  }, [challenge, challengeID, close, goToNextTask, nextTask, taskUUID])

  const context = React.useMemo((): ChallengeTaskNavigationContext => ({
    currentTaskIndex: taskIndex,
    hasPrevious:      previousTask != null,
    hasNext:          nextTask != null,
    previousTask:     goToPreviousTask,
    completeTask:     completeTask,
  }), [completeTask, goToPreviousTask, nextTask, previousTask, taskIndex])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ChallengeTaskNavigationContext.Provider value={context}>
        <NavColumnHelmet
          title={task?.title ?? null}
          caption={caption}
          fetchStatus={document?.fetchStatus}
          reload={fetch}
        />

        {renderContent()}
      </ChallengeTaskNavigationContext.Provider>
    )
  }

  const caption = React.useMemo(() => {
    if (task == null) { return null }

    return (
      <VBox gap={-4}>
        <Label h3>
          {task.title}
        </Label>
        {task.subtitle != null && (
          <Label small dim>
            {task.subtitle}
          </Label>
        )}
      </VBox>
    )
  }, [task])

  function renderContent() {
    if (challenge == null || task == null) {
      return renderEmpty()
    }

    return (
      <VBox flex>
        <Scroller flex contentPadding={layout.padding.inline.l} contentClassNames={$.scrollerContent}>
          <VBox gap={layout.padding.m} classNames={$.task}>
            <ChallengeTaskContent
              challenge={challenge}
              state={state}
              task={task}
              answer={answer}
              results={results}
              mayAnswer={mayAnswer}
              correctAnswers={correctAnswers}
            />
          </VBox>
        </Scroller>
        {mayAnswer && (
          <VBox padding={layout.padding.s}>
            <ChallengeTaskNavigation
              challenge={challenge}
              task={task}
              hasAnswer={answer != null}
              mayContinue={mayContinue}
            />
          </VBox>
        )}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={document?.fetchStatus}
        {...t('task.empty')}
        flex
      />
    )
  }

  return render()

})

export default ChallengeTaskColumn

const useStyles = createUseStyles({
  scrollerContent: {
    alignItems:     'center',
    justifyContent: 'center',
  },

  task: {
    width:    layout.contentWidth,
    maxWidth: '100%',
  },
})