import React from 'react'
import { useAutofocus } from 'react-autofocus'
import { useSize } from 'react-measure'
import { isFunction } from 'lodash'
import { UnicodeString } from 'unicode'
import { forwardRef } from '~/ui/component'
import { Center, HBox, Label, SVG, Tappable } from '~/ui/components'
import { FieldChangeCallback, InlineFormContext, invokeFieldChangeCallback } from '~/ui/form'
import { assignRef } from '~/ui/hooks'
import { colors, createUseStyles, fonts, layout, presets, shadows, useTheme } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  value:     string
  onChange?: FieldChangeCallback<string> | ((value: string) => any)
  onClear?:  () => any

  onCommit?: (value: string, event: React.SyntheticEvent) => any
  onCancel?: (event: React.SyntheticEvent) => any
  commitOnBlur?: boolean

  accessoryLeft?:  React.ReactNode
  accessoryRight?: React.ReactNode

  type?:      string

  emptyLabel?:    string
  placeholder?:   string
  autoFocus?:     boolean
  selectOnFocus?: boolean
  align?:         'left' | 'center' | 'right'
  height?:        number
  small?:         boolean

  multiline?:     boolean
  rows?:          number
  maxHeight?:     number
  resize?:        'both' | 'horizontal' | 'vertical' | 'none'

  inputStyle?:    InputStyle

  maxLength?: number
  transform?: 'none' | 'uppercase' | 'lowercase' | ((text: string) => string)

  invalid?: boolean

  name?:            string
  readOnly?:        boolean
  autoComplete?:    React.InputHTMLAttributes<InputElement>['autoComplete']
  enabled?:         boolean
  showClearButton?: 'always' | 'never' | 'notempty'

  mono?: boolean

  classNames?:      React.ClassNamesProp
  inputAttributes?: React.InputHTMLAttributes<InputElement>
}

export type InputStyle = 'normal' | 'light' | 'dark'

export type InputElement = HTMLInputElement | HTMLTextAreaElement
export type InputRef     = React.Ref<HTMLInputElement | HTMLTextAreaElement>

const TextField = forwardRef('TextField', (props: Props, ref: InputRef) => {

  const {
    value,
    onChange: props_onChange,
    onClear: props_onClear,
    onCommit,
    onCancel,
    commitOnBlur = true,

    accessoryLeft,
    accessoryRight,

    type = 'text',

    emptyLabel,
    placeholder,
    autoFocus,
    selectOnFocus = true,
    align,
    multiline,
    rows = 2,
    resize = 'vertical',
    height,
    small = false,
    maxLength,
    transform = 'none',

    invalid,
    autoComplete,

    name,
    readOnly,
    enabled         = true,
    mono            = false,
    inputStyle      = 'normal',
    showClearButton = 'never',
    inputAttributes: {
      onFocus:   props_onFocus,
      onBlur:    props_onBlur,
      onKeyDown: props_onKeyDown,
      ...inputAttributes
    } = {},
  } = props

  const $ = useStyles()
  const theme = useTheme()

  const accessoryLeftRef  = React.useRef<HTMLDivElement | null>(null)
  const accessoryRightRef = React.useRef<HTMLDivElement | null>(null)
  const empty             = value === ''

  const internalRef = React.useRef<HTMLInputElement>(null)

  const connectElement = React.useCallback((element: HTMLInputElement) => {
    assignRef(ref, element)
    assignRef(internalRef, element)
  }, [ref])

  //------
  // Callbacks

  const onChange = React.useCallback((event: React.SyntheticEvent<InputElement>) => {
    let string = new UnicodeString(event.currentTarget.value)

    if (maxLength != null) {
      string = string.slice(0, maxLength)
    }
    if (transform === 'uppercase') {
      string = string.toUpperCase()
    }
    if (transform === 'lowercase') {
      string = string.toLowerCase()
    }
    if (isFunction(transform)) {
      string = new UnicodeString(transform(string.string))
    }

    invokeFieldChangeCallback(props_onChange, string.toString(), true)
  }, [maxLength, props_onChange, transform])

  const onClearTap = React.useCallback(() => {
    props_onClear?.()
    props_onChange?.('')
  }, [props_onChange, props_onClear])

  const inlineForm = React.useContext(InlineFormContext)

  const onKeyDown = React.useCallback((event: React.KeyboardEvent<InputElement>) => {
    if (event.key === 'Enter' && (!multiline || event.metaKey)) {
      onCommit?.(value, event)
    }
    if (event.key === 'Escape') {
      onCancel?.(event)
      inlineForm?.stopEditing()
    }

    props_onKeyDown?.(event)
  }, [inlineForm, multiline, onCancel, onCommit, props_onKeyDown, value])

  //------
  // Focus handling

  const onFocus = React.useCallback((event: React.FocusEvent<InputElement>) => {
    if (selectOnFocus) {
      event.currentTarget.select()
    }
    props_onFocus?.(event)
  }, [props_onFocus, selectOnFocus])

  const onBlur = React.useCallback((event: React.FocusEvent<InputElement>) => {
    if (commitOnBlur && props_onChange) {
      props_onChange(value)
    }
    props_onBlur?.(event)
  }, [commitOnBlur, props_onBlur, props_onChange, value])

  const performAutoFocus = React.useCallback(() => {
    if (!autoFocus) { return }
    if (internalRef.current == null) { return }
    if (document.activeElement === internalRef.current) { return }

    internalRef.current.focus()
  }, [autoFocus])

  useAutofocus(performAutoFocus)

  //------
  // Dynamic padding

  const [accessoryLeftWidth, setAccessoryLeftWidth] = React.useState<number>(0)
  const [accessoryRightWidth, setAccessoryRightWidth] = React.useState<number>(0)

  const accessoryPadding = React.useMemo(() => {
    const paddingLeft  = Math.max(accessoryLeftWidth, presets.fieldPadding.horizontal)
    const paddingRight = Math.max(accessoryRightWidth, presets.fieldPadding.horizontal)
    return {paddingLeft, paddingRight}
  }, [accessoryLeftWidth, accessoryRightWidth])

  useSize(accessoryLeftRef, size => { setAccessoryLeftWidth(size.width) })
  useSize(accessoryRightRef, size => { setAccessoryRightWidth(size.width) })

  React.useLayoutEffect(() => {
    const accessoryLeftElement = accessoryLeftRef.current
    const accessoryRightElement = accessoryRightRef.current

    if (accessoryLeft == null) {
      setAccessoryLeftWidth(0)
    } else {
      setAccessoryLeftWidth(accessoryLeftElement?.offsetWidth ?? 0)
    }
    if (accessoryRight == null) {
      setAccessoryRightWidth(0)
    } else {
      setAccessoryRightWidth(accessoryRightElement?.offsetWidth ?? 0)
    }
  }, [accessoryLeft, accessoryRight])

  //------
  // Rendering

  function render() {
    const classNames = [
      $.textField,
      {invalid, disabled: !enabled, small, multiline: multiline === true, readOnly},
      inputStyle,
      props.classNames,
    ]

    return (
      <div classNames={classNames}>
        <HBox flex classNames={$.inputContainer}>
          {renderInput()}
          {renderAccessoryLeft()}
          {renderAccessoryRight()}
        </HBox>
        {renderClearButton()}
      </div>
    )
  }

  function renderInput() {
    const classNames = [
      $.input,
      {disabled: !enabled},
      {mono, small, multiline: multiline === true},
      inputAttributes.classNames,
    ]

    const style = {
      ...accessoryPadding,
      textAlign: align,
      resize: multiline ? resize : '',
      height,
    }

    return React.createElement(multiline ? 'textarea' : 'input', {
      ref: connectElement,
      type,
      name,

      ...inputAttributes,

      classNames,
      style,

      rows: multiline ? rows : undefined,

      placeholder:  readOnly ? emptyLabel : placeholder,
      disabled:     !enabled,
      readOnly:     readOnly,
      autoComplete: autoComplete,

      value:     value ?? '', // Note: even though the type of value is always string, in some form models it may be that `null` is input.
      onChange:  onChange,
      onFocus:   onFocus,
      onBlur:    onBlur,
      onKeyDown: onKeyDown,
    })
  }

  function renderAccessoryLeft() {
    if (accessoryLeft == null) { return null }

    const children = isReactText(accessoryLeft)
      ? <Label>{accessoryLeft}</Label>
      : accessoryLeft

    return (
      <Center ref={accessoryLeftRef} classNames={[$.accessory, $.accessoryLeft]}>
        {children}
      </Center>
    )
  }

  function renderAccessoryRight() {
    if (accessoryRight == null) { return null }

    const children = isReactText(accessoryRight)
      ? <Label>{accessoryRight}</Label>
      : accessoryRight

    return (
      <Center ref={accessoryRightRef} classNames={[$.accessory, $.accessoryRight]}>
        {children}
      </Center>
    )
  }

  function renderClearButton() {
    if (showClearButton === 'never') { return null }
    if (showClearButton === 'notempty' && empty) { return null }
    if (readOnly) { return null }

    return (
      <Tappable classNames={[$.clearButton, {disabled: !enabled || empty}]} showFocus={false} onTap={onClearTap} enabled={enabled && !empty}>
        <SVG name='cross' size={layout.icon.m} color={theme.colors.fg.dark.dim}/>
      </Tappable>
    )
  }

  return render()

})

export default TextField

const useStyles = createUseStyles(theme => ({
  textField: {
    ...presets.field(theme),
    ...layout.flex.row,
    padding: 0,

    '&.invalid': {
      ...presets.invalidField(theme),
    },

    '&.small': {
      minHeight: presets.fieldHeight.small,
    },
  },

  inputContainer: {
    position: 'relative',
  },

  input: {
    ...presets.clearInput(theme),
    background: 'transparent',
    height:     '100%',
    padding:    presets.fieldPadding,
    lineHeight: '1.2em',

    '&.disabled': {
      opacity: 0.6,
    },

    minHeight: presets.fieldHeight.normal,
    '&.small': {
      minHeight: presets.fieldHeight.small,
    },

    '&.multiline': {
      padding: presets.fieldPaddingMultiline,
    },

    '&.mono': {
     ...fonts.responsiveFontStyle(theme.guide.fonts.mono),
    },
  },

  accessory: {
    position: 'absolute',
    top:      0,
    bottom:   0,
  },

  accessoryLeft: {
    left: 0,
    paddingLeft:  presets.fieldPadding.left,
    paddingRight: layout.padding.inline.m,
  },

  accessoryRight: {
    right: 0,
    paddingRight: presets.fieldPadding.right,
    paddingLeft:  layout.padding.inline.m,
  },

  clearButton: {
    ...layout.flex.center,

    padding:    [0, presets.fieldPadding.horizontal],
    background: theme.colors.bg.light.semi,

    borderTopRightRadius:    presets.fieldBorderRadius,
    borderBottomRightRadius: presets.fieldBorderRadius,

    '&:not(.disabled):hover': {
      ...colors.overrideForeground(theme.semantic.primary),
    },
    '&:not(.disabled):focus': {
      ...colors.overrideForeground(theme.semantic.primary),
      ...shadows.focus.subtle(theme),
    },
    '&.disabled': {
      opacity: 0.6,
      ...colors.overrideForeground(theme.fg.dimmer),
    },
  },
}))