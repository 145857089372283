import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { breakoutStore } from '~/stores'
import { VBox, Label, SelectField } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { layout } from '~/ui/styling'

const BreakoutSwitcher = observer(() => {

  const {availableGroups, breakoutGroupID} = breakoutStore

  const [t] = useTranslation('webcast')

  //------
  // Rendering

  function render() {
    if (breakoutGroupID == null) {return null}
    return (
      <VBox gap={layout.padding.s}>
        <Label small dim truncate={false}>
          {t('breakout_switcher.caption')}
        </Label>

        <SelectField
          value={breakoutGroupID}
          onChange={handleChange}
          choices={choices}
        />
      </VBox>
    )
  }

  const handleChange = React.useCallback((groupID: string | null) => {
    if (groupID == null) { return }
    breakoutStore.switchToBreakout(groupID)
  }, [])

  const choices = React.useMemo((): Choice[] => availableGroups.map(group => ({
    value:   group.id,
    caption: group.name,
  })), [availableGroups])

  return render()

})

export default BreakoutSwitcher