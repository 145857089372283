import { escapeRegExp } from 'lodash'
import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLearningTrack } from '~/models'
import { SearchParams } from '../search'
import BeWizrLearningTrackDocument from './BeWizrLearningTrackDocument'

export default class BeWizrLearningTracksEndpoint extends Endpoint<BeWizrLearningTrackDocument, SearchParams> {

  protected async performFetch(params: SearchParams, options: CollectionFetchOptions) {
    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('learning_track/')
    if (response.status !== 200) { return response }

    let data: BeWizrLearningTrack[] = response.data.map(
      (raw: any) => BeWizrLearningTrack.deserialize(raw),
    )

    if (params.query != null) {
      const regExp = new RegExp(escapeRegExp(params.query), 'gmi')
      data = data.filter(course => regExp.test(course.title))
    }

    const total  = data.length
    const offset = options.offset ?? 0
    const limit  = options.limit ?? 20

    if (offset > 0) {
      data.splice(0, offset)
    }
    if (data.length > limit) {
      data.splice(limit)
    }
    const nextOffset = offset + limit > total ? null : offset + limit

    return {
      data: data,
      meta: {total, nextOffset},
    }
  }
}