import React from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from '~/history'
import { isCustomImage, Link, SVGImage } from '~/models'
import { isInternalHref, resolveHref } from '~/navigation'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Badge, Center, HBox, Label, Tappable, TappableProps } from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import SVG, { SVGName } from '~/ui/components/SVG'
import {
  createUseStyles,
  layout,
  themedBackgroundStyles,
  ThemeProvider,
  useStyling,
} from '~/ui/styling'

export interface Props {
  icon?:   SVGImage | SVGName
  caption: string
  link?:   Link
  badge?:  Badge
  active?: (path: string) => boolean
  onTap?:  TappableProps['onTap']
}

const AppSideBarNavigationButton = memo('AppSideBarNavigationButton', (props: Props) => {

  const location = useLocation()
  const history  = useHistory()

  const {icon, caption, link, badge, onTap: props_onTap} = props
  const active = props.active?.(location.pathname)

  const {guide} = useStyling()

  const href = link != null? resolveHref(link?.href) : undefined

  const onTap = React.useCallback((event: React.SyntheticEvent<any, any>) => {
    if (href != null && isInternalHref(href)) {
      history.reset(href)
      event.preventDefault()
    }
    props_onTap?.(event)
  }, [history, href, props_onTap])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark={active && guide.backgrounds.primary.theme === 'dark'}>
        <Tappable classNames={[$.AppSideBarNavigationButton, {active}]} onTap={onTap} showFocus={true} href={href} target={link?.target}>
          <HBox classNames={$.content} gap={layout.padding.inline.l} align='middle'>
            <Center>
              {renderIcon()}
              <Badge
                classNames={$.badge}
                value={badge}
              />
            </Center>
            <Label bold>
              {caption}
            </Label>
          </HBox>
        </Tappable>
      </ThemeProvider>
    )
  }

  function renderIcon() {
    if (isCustomImage(icon)) {
      return (
        <ImageView
          source={icon}
          size={layout.icon.l}
          classNames={[$.icon, {active}]}
        />
      )
    }
    return (
      <SVG
        name={icon}
        size={layout.icon.l}
      />
    )
  }

  return render()

})

export default AppSideBarNavigationButton

const useStyles = createUseStyles(theme => ({
  AppSideBarNavigationButton: {
    borderRadius: layout.radius.m,
    padding:      layout.padding.inline.l,
    '&.active': {
      ...themedBackgroundStyles(theme.guide, theme.guide.backgrounds.solid),
    },
  },

  content: {
    position: 'relative',
  },

  badge: {
    position: 'absolute',
    top:      -badgeSize.normal.height * 2 / 3,
    right:    -badgeSize.normal.minWidth * 2 / 3,
  },

  icon: {
    '& [fill]': {
      fill: 'currentColor',
    },
  },
}))

export type Badge = string | number | true | null