import React from 'react'
import { useTranslation } from 'react-i18next'
import { combinedFetchStatus } from '~/lib/mobx-document'
import { BeWizrCourse, BeWizrLearningTrack } from '~/models'
import { projectStore } from '~/stores'
import bewizrStore from '~/stores/bewizrStore'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { NavColumnHelmet } from '../navigation'
import { useColumnNavigationState } from '../navigation/NavigationStateContext'
import BeWizrGrid from './BeWizrGrid'
import BeWizrSection from './BeWizrSection'
import BeWizrCourseTile from './courses/BeWizrCourseTile'
import BeWizrLearningTrackTile from './learning-tracks/BeWizrLearningTrackTile'

const BeWizrCoursesColumn = observer('BeWizrCoursesColumn', () => {

  const [t] = useTranslation('bewizr')

  const {path} = useColumnNavigationState()

  const authenticated = bewizrStore.authenticated

  const learningTracksEndpoint = bewizrStore.myLearningTracks
  const coursesEndpoint        = bewizrStore.myCourses

  const reload = React.useCallback(() => {
    learningTracksEndpoint.resetAndFetch()
    coursesEndpoint.resetAndFetch()
  }, [learningTracksEndpoint, coursesEndpoint])

  const fetch = React.useCallback(() => {
    learningTracksEndpoint.fetch()
    coursesEndpoint.fetch()
  }, [learningTracksEndpoint, coursesEndpoint])

  React.useEffect(() => {
    if (!authenticated) { return }
    fetch()
  }, [fetch,authenticated])

  const fetchStatus = combinedFetchStatus(learningTracksEndpoint.fetchStatus, coursesEndpoint.fetchStatus)

  //------
  // Rendering

  const title = projectStore.captionForPath(path) ?? t('title')

  const empty = fetchStatus === 'fetching' || (learningTracksEndpoint.count + coursesEndpoint.count) === 0

  function render() {
    return (
      <>
        <NavColumnHelmet
          title={title}
          fetchStatus={fetchStatus}
          reload={reload}
        />
        {empty ? renderEmpty() : renderBody()}
      </>
    )
  }

  function renderBody() {
    return (
      <Scroller contentPadding={layout.padding.m}>
        <VBox gap={layout.padding.xl}>
          {learningTracksEndpoint.data.length > 0 && (
            <BeWizrSection title={t('learning-tracks.grid.title')}>
            <BeWizrGrid
              endpoint={learningTracksEndpoint}
              renderItem={renderLearningTrack}
            />
          </BeWizrSection>
          )}
          {coursesEndpoint.data.length > 0 && (
            <BeWizrSection title={t('courses.grid.title')}>
            <BeWizrGrid
              endpoint={coursesEndpoint}
              renderItem={renderCourse}
            />
          </BeWizrSection>
          )}
        </VBox>
      </Scroller>
    )
  }

  function renderCourse(course: BeWizrCourse) {
    return (
      <BeWizrCourseTile
        title={course.title}
        slug={course.slug}
        image={course.links.image}
        progress={course.progress}
      />
    )
  }

  function renderLearningTrack(learningTrack: BeWizrLearningTrack) {
    return (
      <BeWizrLearningTrackTile learningTrack={learningTrack}/>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        flex
      />
    )
  }

  return render()

})

export default BeWizrCoursesColumn