import { action, computed, makeObservable, observable } from 'mobx'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { LiveSchedule, Serialized, WhatsOn } from '~/models'
import LiveScheduleController from './LiveScheduleController'

export default class LiveService extends OnDemandService {

  constructor(socket: Socket) {
    super(socket)
    makeObservable(this)
  }

  public dispose() {
    this.scheduleController?.dispose()
  }

  //------
  // Schedule controller & derived props

  @observable
  private scheduleController: LiveScheduleController | null = null

  @computed
  public get scheduleItems() {
    return this.scheduleController?.scheduleItems ?? []
  }

  @computed
  public get scheduleItemsFlattened() {
    return this.scheduleController?.scheduleItemsFlattened ?? []
  }

  @computed
  public get whatsOn() {
    return this.scheduleController?.whatsOn ?? new WhatsOn([])
  }

  @computed
  public get current() {
    return this.scheduleController?.current
  }

  @computed
  public get nextUp() {
    return this.scheduleController?.nextUp ?? null
  }

  //------
  // Connection lifecycle

  public start() {
    super.startWithEvent('live:start')
  }

  @action
  protected onStarted(response: StartSuccess<LiveStartResponse>) {
    this.scheduleController?.dispose()

    if (response.ok) {
      const schedule = LiveSchedule.deserialize(response.data.schedule)
      this.scheduleController = new LiveScheduleController(schedule)

      this.socket.addEventListener('live:update', this.handleUpdate)
    } else {
      this.scheduleController = null
    }
  }

  private handleUpdate = (update: LiveUpdate) => {
    this.scheduleController?.setSchedule(update.schedule)
  }

  @action
  protected onStop() {
    this.scheduleController?.dispose()
    this.scheduleController = null
  }

}

export interface LiveStartResponse {
  schedule: Serialized<LiveSchedule>
}

export interface LiveUpdate {
  schedule: LiveSchedule
}