import React from 'react'
import { useTranslation } from 'react-i18next'
import { connectionsStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import NavColumnHelmet from '../navigation/NavColumnHelmet'
import { useColumnNavigationState } from '../navigation/NavigationStateContext'
import ConnectionList from './ConnectionList'
import ConnectionRequestsHeader from './ConnectionRequestsHeader'
import ConnectionsHeader from './ConnectionsHeader'

const ConnectionsColumn = observer('ConnectionsColumn', () => {

  const {path} = useColumnNavigationState()

  const endpoint = connectionsStore.confirmed

  const fetch = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  React.useEffect(fetch, [fetch])

  const [t] = useTranslation('connections')

  const title = projectStore.captionForPath(path) ?? t('title')

  //------
  // Rendering

  function render() {
    return (
      <VBox flex padding={layout.padding.m}>
        <NavColumnHelmet
          title={title}
          fetchStatus={endpoint.fetchStatus}
          reload={fetch}
        />
        <ConnectionRequestsHeader/>
        <ConnectionsHeader/>
        <ConnectionList
          endpoint={endpoint}
          requests={false}
        />
      </VBox>
    )
  }

  return render()

})

export default ConnectionsColumn