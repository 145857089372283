import React from 'react'
import { useTranslation } from 'react-i18next'
import { notificationsStore } from '~/stores'
import { observer } from '~/ui/component'
import { PushButton, VBox } from '~/ui/components'
import { NavColumnHelmet } from '../navigation'
import NotificationsList from './NotificationsList'

const NotificationsColumn = observer('NotificationsColumn', () => {

  const [t] = useTranslation('notifications')
  const endpoint = notificationsStore.myNotifications

  const markAllAsSeen = React.useCallback(() => {
    notificationsStore.markAllAsSeen()
  }, [])

  const reload = React.useCallback(
    () => endpoint.resetAndFetch(),
    [endpoint],
  )

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        <NavColumnHelmet
          title={t('title')}
          renderRight={renderHeaderRight}
          reload={reload}
        />
        <NotificationsList endpoint={endpoint} />
      </VBox>
    )
  }

  function renderHeaderRight() {
    return (
      <PushButton
        caption={t('mark_as_seen')}
        enabled={notificationsStore.hasUnseenNotifications}
        onTap={markAllAsSeen}
        small
      />
    )
  }

  return render()

})

export default NotificationsColumn