import * as H from 'history'
import Logger from 'logger'
import { select } from 'ytil'
import { assignGlobal } from '~/global'

const logger = new Logger('History')

export interface History extends H.History {
  stack:    HistoryEntry[]

  pushFrom(index: number, path: string): void
  goBack(options?: GoBackOptions): void
  reset(path?: string): void

  startTrace(): void
  stopTrace(): void
}

interface HistoryEntry {
  pathname:  string
}

export interface GoBackOptions {
  fallbackPath?: string
}

function createHistory(base: H.History): History {
  const history = base as History
  history.stack = [
    {pathname: history.location.pathname},
  ]

  history.pushFrom = (index, path) => {
    if (index === history.stack.length - 2) {
      history.replace(path)
    } else if (index < history.stack.length - 1) {
      history.go(index - history.stack.length + 1)
      setImmediate(() => { history.push(path) })
    } else {
      history.push(path)
    }
  }

  const origGoBack = history.goBack

  history.goBack = (options = {}) => {
    if (history.stack.length > 1) {
      origGoBack.call(history)
    } else if (options.fallbackPath != null) {
      history.replace(options.fallbackPath)
    }
  }

  history.reset = (path?: string) => {
    history.stack = []
    if (path == null) { return }
    history.push(path)
  }

  history.listen((location, action) => {
    const stack     = history.stack.map(it => ({...it}))
    const lastEntry = stack[stack.length - 1]

    if (action === 'REPLACE' && lastEntry != null) {
      lastEntry.pathname = location.pathname
    } else if (action === 'PUSH') {
      stack.push({pathname: location.pathname})
    } else if (action === 'POP') {
      stack.pop()
    }

    history.stack = stack
  })

  return history
}

const history = createHistory(H.createBrowserHistory())
export default history

if (process.env.NODE_ENV === 'development') {
  assignGlobal({appHistory: history})

  let disposer: () => void = () => null

  history.startTrace = function startTrace() {
    disposer = history.listen((location, action) => {
      const icon = select(action.toLowerCase(), {
        replace: '↧',
        push:    '↦',
        pop:     '↤',
        default: '?',
      })
      logger.info(`${icon} ${location.pathname}${location.search}${location.hash}`, [history.stack])
    })
  }

  history.stopTrace = function stopTrace() {
    disposer()
    disposer = () => null
  }
}