import React from 'react'
import { useTranslation } from 'react-i18next'
import { every } from 'lodash'
import { DateTime } from 'luxon'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { BeWizrLearningTrack, BeWizrLearningTrackItem, RemoteImage } from '~/models'
import { memo } from '~/ui/component'
import { Chip, HBox, Hero, Markdown, VBox } from '~/ui/components'
import Timeline, { TimelineItem } from '~/ui/components/Timeline'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import BeWizrSection from '../BeWizrSection'
import BeWizrCourseTile from '../courses/BeWizrCourseTile'
import BeWizrMeetingTile from '../meetings/BeWizrMeetingTile'

export interface Props {
  learningTrack: BeWizrLearningTrack
}

const BeWizrLearningTrackView = memo('BeWizrLearningTrackView', (props: Props) => {

  const [t, i18next] = useTranslation('bewizr')

  const {learningTrack} = props

  const {colors} = useStyling()

  const description = React.useMemo(() => {
    if (learningTrack.descriptionHtml == null || learningTrack.descriptionHtml.length === 0) { return null }
    // A little bit ridiculous, but this way we can use the styling from the <Markdown/> component
    return NodeHtmlMarkdown.translate(learningTrack.descriptionHtml)
  }, [learningTrack.descriptionHtml])

  const image: RemoteImage | undefined = learningTrack.links.image == null ? undefined : {
    type: 'remote',
    url:   learningTrack.links.image,
  }

  //------
  // Rendering

  const $ = useStyles()

  const renderItem = React.useCallback((item: BeWizrLearningTrackItem) => {
    if (item.type === 'course') {
      return (
        <BeWizrCourseTile
          title={item.title}
          slug={item.slug}
          progress={item.progress}
          image={item.links.image}
          locked={item.isLocked}
          horizontal
        />
      )
    } else {
      return (
        <BeWizrMeetingTile
          title={item.title}
          image={item.links.image}
          startDate={item.startDate}
          endDate={item.endDate}
          slug={item.slug}
          locked={item.isLocked}
          horizontal
        />
      )
    }
  }, [])

  const items = React.useMemo(() => {
    const now = DateTime.now()

    const items: TimelineItem[] = [{
      caption:   t('learning-tracks.start'),
      detail:    learningTrack.startDate?.toFormat('d-M-yyyy', {locale: i18next.language}),
      completed: learningTrack.startDate == null || learningTrack.startDate <= now,
    }]

    learningTrack.items?.forEach(item => {
      items.push({
        render:    () => renderItem(item),
        completed: item.type === 'course' ? item.progress === 100 : item.endDate != null && item.endDate < now,
      })
    })

    items.push({
      icon:      'finish-flag',
      detail:    learningTrack.endDate?.toFormat('d-M-yyyy', {locale: i18next.language}),
      completed: every(items, it => it.completed),
    })

    return items
  }, [learningTrack.startDate, learningTrack.endDate, learningTrack.items, renderItem, t, i18next])

  function render() {
    return (
      <VBox classNames={$.BeWizrLearningTrackView}>
        <Hero
          title={learningTrack.title}
          image={image}
          backgroundImage={image}
          children={renderHeroBody()}
        />
        <VBox align='center' padding={layout.padding.m}>
          {renderContent()}
        </VBox>
      </VBox>
    )
  }

  function renderHeroBody() {
    return (
      <VBox gap={layout.padding.xl}>
        {renderDescription()}
        {renderMetaData()}
      </VBox>
    )
  }

  function renderDescription() {
    if (description == null) { return null }

    return (
      <Markdown>
        {description}
      </Markdown>
    )
  }

  function renderMetaData() {
    return (
      <HBox>
        <Chip
          icon='unordered-list'
          backgroundColor={colors.fg.highlight}
          children={t('learning-tracks.items.count', {count: learningTrack.itemCount})}
        />
      </HBox>
    )
  }

  function renderContent() {
    return (
      <VBox classNames={$.content} gap={layout.padding.xl} paddingVertical={layout.padding.l}>
        <BeWizrSection title={t('learning-tracks.items.title')}>
          <Timeline items={items} large/>
        </BeWizrSection>
      </VBox>
    )
  }

  return render()

})

export default BeWizrLearningTrackView

const useStyles = createUseStyles({
  BeWizrLearningTrackView: {
  },

  content: {
    width:    layout.contentWidth,
    maxWidth: '100%',
  },
})