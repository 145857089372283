import { some } from 'lodash'
import { WebContentItem, WebScheduleContent, WebScheduleItem } from './LiveSchedule'

export class WhatsOn {

  constructor(
    private readonly items: WebScheduleItem[],
  ) {
    this.itemsReversed = [...items].reverse()
  }

  private readonly itemsReversed: WebScheduleItem[]

  public get isEmpty() {
    return this.content.length === 0
  }

  /**
   * Determines whether an item of this type is currently on.
   * @param type The item type.
   */
  public hasItem(type: WebContentItem['type']) {
    return some(this.items, it => it.type === type)
  }

  public get content() {
    const allContent: WhatsOnContentItem[] = []

    for (const item of this.items) {
      for (const content of item.content) {
        allContent.push({...item, content})
      }
    }

    return allContent
  }

  /**
   * Finds the most recent content of the given type for the given breakout group ID.
   *
   * @param type The type of content to look for.
   * @param groupID The currently selected breakout group ID, or `null` if no breakouts are available.
   */
  public contentAs<T extends WebContentItem>(type: T['type'], groupID: string | null): T | null {
    for (const item of this.itemsReversed) {
      if (item.type !== type) { continue }

      for (const content of item.content) {
        const contentGroupID = content.group?.id ?? null
        if (contentGroupID != null && contentGroupID !== groupID) { continue }

        return {type, ...content.item} as T
      }
    }

    return null
  }

}

export type WhatsOnContentItem = Omit<WebScheduleItem, 'content'> & {
  content: WebScheduleContent<WebContentItem>
}