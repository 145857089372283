import { some } from 'lodash'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { GroupDescriptor } from '~/models'
import liveStore from './liveStore'
import { register } from './support'

export class BreakoutStore {

  constructor() {
    makeObservable(this)
    reaction(() => this.availableGroups, () => this.checkValidGroupID())
  }

  @computed
  public get availableGroups() {
    const whatsOn = liveStore.currentLiveService?.whatsOn
    if (whatsOn == null) { return [] }

    const groups = new Map<string, GroupDescriptor>()
    for (const {content} of whatsOn.content) {
      if (content.group == null) { continue }
      groups.set(content.group.id, content.group)
    }

    return Array.from(groups.values())
  }


  @observable
  public breakoutGroupID: string | null = null

  @action
  public switchToBreakout(groupID: string) {
    this.breakoutGroupID = groupID
  }

  @action
  private checkValidGroupID() {
    if (!some(this.availableGroups, g => this.breakoutGroupID === g.id)) {
      this.breakoutGroupID = this.availableGroups[0]?.id ?? null
    }
  }

}

export default register(new BreakoutStore())