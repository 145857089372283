import React from 'react'
import { useTranslation } from 'react-i18next'
import DailyIframe, { DailyCall, DailyLanguage } from '@daily-co/daily-js'
import { WebcastConferenceContent } from '~/models'
import { memo } from '~/ui/component'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {
  content: WebcastConferenceContent
}

const WebcastConferenceCall = memo('WebcastConferenceCall', (props: Props) => {

  const {content} = props
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null)
  const [daily, setDaily] = React.useState<DailyCall | null>(null)
  const [joined, setJoined] = React.useState<boolean>(false)

  const {i18n}       = useTranslation('app')
  const currentLanguage = i18n.language as DailyLanguage

  const {colors} = useTheme()

  const previousContent = usePrevious(content)

  const dailyTheme = React.useMemo(() => ({
    colors: {
      accent: colors.semantic.primary.hex().toString(),
      accentText: colors.fg.light.normal.hex().toString(),
      baseText: colors.fg.normal.hex().toString(),
      background: colors.bg.alt.hex().toString(),
      backgroundAccent: colors.bg.normal.hex().toString(),
      border: colors.border.normal.hex().toString(),
      mainAreaText: colors.fg.normal.hex().toString(),
      mainAreaBg: colors.bg.subtle.hex().toString(),
      mainAreaBgAccent: colors.bg.dark.alt.hex().toString(),
      supportiveText: colors.semantic.secondary.hex().toString(),
    },
  }), [colors])

  // Init Daily.co
  React.useEffect(() => {
    if (iframeRef.current == null || daily != null) { return }
    setDaily(DailyIframe.wrap(iframeRef.current,{ // https://docs.daily.co/reference/daily-js/daily-iframe-class/properties
      showFullscreenButton: true,
      showLeaveButton: true,
      layoutConfig: {
        grid: {
          maxTilesPerPage: 10,
        },
      },
      theme: dailyTheme,
    }))
  }, [daily, dailyTheme])

  // Join a call
  // Or leave the current call and join the new one if room url or token changes
  const joinOrRejoin = React.useCallback(async () => {
    if (daily == null) { return }
    if (joined && content.url === previousContent?.url && content.token === previousContent.token) { return }

    if (joined) {
      await daily.leave()
    }

    await daily.join({
      url: content.url,
      token: content.token,
      lang: currentLanguage,
    })

    setJoined(true)
  }, [daily, content, previousContent, joined, currentLanguage])

  React.useEffect(() => {
    joinOrRejoin()
  }, [joinOrRejoin])


  // Update Daily UI language
  React.useEffect(() => {
    if (daily == null || !joined) { return }
    daily.setDailyLang(currentLanguage)
  }, [currentLanguage, daily, joined])

  // Update Daily UI theme
  React.useEffect(() => {
    if (daily == null || !joined) { return }
    daily.setTheme(dailyTheme)
  }, [dailyTheme, daily, joined])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <iframe title={content.roomName} classNames={$.conferenceCall} ref={iframeRef} allow="camera;microphone;fullscreen;display-capture" allowFullScreen />
    )
  }
  return render()

})

export default WebcastConferenceCall

const useStyles = createUseStyles({
  conferenceCall: {
    width:  '100%',
    height: '100%',
    border: 'none',
    borderRadius: layout.radius.m,
  },
})