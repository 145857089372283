import React from 'react'
import { BeWizrCourseItem } from '~/models'
import { memo } from '~/ui/component'
import { List } from '~/ui/components'
import { layout } from '~/ui/styling'
import BeWizrCourseListItem from './BeWizrCourseListItem'

export interface Props {
  items:              BeWizrCourseItem[]
  expandedItemIndex?: number
}

const BeWizrCourseItemList = memo('BeWizrCourseItemList', (props: Props) => {

  const {items, expandedItemIndex = 0} = props

  //------
  // Rendering

  function render() {
    return (
      <List
        flex={false}
        data={items}
        renderItem={renderItem}
        itemGap={layout.padding.inline.m}
      />
    )
  }

  function renderItem(item: BeWizrCourseItem, index: number) {
    return (
      <BeWizrCourseListItem
        item={item}
        expanded={index === expandedItemIndex}
      />
    )
  }

  return render()

})

export default BeWizrCourseItemList