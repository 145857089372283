import { initReactI18next } from 'react-i18next'
import I18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import config from '~/config'
import * as locales from '~/locales'

I18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist:     Object.keys(locales),
    fallbackLng:   'nl',
    resources:     locales,
    returnObjects: true,
    interpolation: {
      // Not necessary as React already escapes.
      escapeValue: false,
    },

    detection: {
      order:  ['querystring', 'cookie', 'navigator'],

      lookupQuerystring: 'lng',
      lookupCookie:      'i18next',
      lookupHeader:      'accept-language',

      caches:         ['cookie'],
      cookieDomain:   config.securityDomain,
      cookieSecure:   config.environment !== 'local',
      cookieSameSite: 'strict',
    },
  })