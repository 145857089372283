import './VirtualizedChatView.css'
import React from 'react'
import { useSize } from 'react-measure'
import { useTimer } from 'react-timer'
import cn from 'classnames'
import { range } from 'lodash'
import LayoutHelper from './LayoutHelper'
import ScrollHelper from './ScrollHelper'
import { RowRange, VirtualizedChatViewProps } from './types'
import { ArrayDiff } from './util/arrays'
import { VirtualizedChatContext } from './VirtualizedChatContext'

interface VirtualizedChatView {
  scrollToBottom(behavior?: 'smooth' | 'auto'): void
}

const VirtualizedChatView = React.forwardRef((props: VirtualizedChatViewProps, ref: React.Ref<VirtualizedChatView>) => {

  const {
    keys,
    renderMessage,
    onEndReached,
    containerClassName,
    contentClassName,
    contentPadding,
  } = props

  const containerRef = React.useRef<HTMLDivElement>(null)
  const spacerRef = React.useRef<HTMLDivElement>(null)

  const firstRenderRef = React.useRef<boolean>(true)
  const firstLayoutRef = React.useRef<boolean>(true)

  //------
  // Layout helper

  const layout = React.useMemo(
    () => new LayoutHelper(layout => {
      setListHeight(layout.listHeight)
      setRenderRange(layout.renderRange)
      layout.padding = contentPadding ?? 0
      firstLayoutRef.current = false
    }),
    [contentPadding],
  )

  const [renderRange, setRenderRange] = React.useState<RowRange>(layout.renderRange)
  const [listHeight, setListHeight]   = React.useState<number | string>('100%')

  // Continuously update the keys in the layout helper. Keep track of whether we've prepended keys in this
  // render cycle, as we will want to scroll smoothly. Never do this on the first render though.
  const diff             = layout.updateKeys(keys)
  const smoothScroll     = !firstRenderRef.current && ArrayDiff.isPrepend(diff)
  firstRenderRef.current = false

  React.useLayoutEffect(() => {
    layout.commit()
  })

  // Whenever the container changes, update the container height.
  useSize(containerRef, size => {
    layout.setContainerHeight(size.height)
    layout.commit()
  })

  //------
  // Scrolling

  const scrollHelper = React.useMemo(
    () => new ScrollHelper(containerRef),
    [],
  )

  scrollHelper.endReachedHandler = onEndReached
  scrollHelper.scrollSmoothNext(smoothScroll)

  scrollHelper.startSnapshotListener = React.useCallback(() => {
    const spacer = spacerRef.current
    if (spacer == null) { return }

    const height = Math.max(layout.containerHeight * 1.5 - layout.listHeight, 0)
    spacer.style.height = `${height}px`
  }, [layout.containerHeight, layout.listHeight])

  scrollHelper.endSnapshotListener = React.useCallback(() => {
    const spacer = spacerRef.current
    if (spacer == null) { return }

    const height = Math.max(layout.containerHeight - layout.listHeight, 0)
    spacer.style.height = `${height}px`
  }, [layout.containerHeight, layout.listHeight])

  const initialScrollPerformed = React.useRef<boolean>(false)

  // Upon first layout, scroll all the way down, immediately. On any subsequent layout, commit the snapshot.
  React.useLayoutEffect(() => {
    if (listHeight === '100%') { return }
    if (layout.initialLayout) { return }

    if (!initialScrollPerformed.current && layout.listHeight < layout.containerHeight) {
      scrollHelper.takeSnapshot()
      scrollHelper.commitSnapshot()
      initialScrollPerformed.current = true
    } else if (!initialScrollPerformed.current) {
      scrollHelper.scrollToBottom()
      initialScrollPerformed.current = true
    } else {
      scrollHelper.detectEndReached()
      scrollHelper.commitSnapshot()
    }
  }, [scrollHelper, listHeight, layout.initialLayout, layout.containerHeight, layout.listHeight, keys.length])

  const scrollThrottleTimer = useTimer()
  const handleScroll = React.useCallback(() => {
    if (!initialScrollPerformed.current) { return }

    // Keep the list at the bottom if already there. Do this by continuously taking a scroll snapshot.
    scrollHelper.takeSnapshot()

    // Continuously perform end-reached detection.
    scrollHelper.detectEndReached()

    // Update the current scroll top for virtualization.
    scrollThrottleTimer.throttle(() => {
      if (containerRef.current == null) { return }

      layout.setScrollTop(containerRef.current.scrollTop)
      layout.commit()
    }, 200)
  }, [layout, scrollHelper, scrollThrottleTimer])

  //------
  // Context

  const context = React.useMemo((): VirtualizedChatContext => ({
    recalculateRowHeight: (key) => {
      layout.recalculateRowHeight(key)
      layout.commit()
    },
  }), [layout])

  React.useImperativeHandle(ref, () => ({
    scrollToBottom: behavior => {
      scrollHelper.scrollToBottom(behavior)
    },
  }))

  //------
  // Rendering

  const containerClassNames = React.useMemo(
    () => cn('VirtualizedChatView--container', containerClassName),
    [containerClassName],
  )
  const contentClassNames = React.useMemo(
    () => cn('VirtualizedChatView--content', contentClassName),
    [contentClassName],
  )

  const listClassNames = React.useMemo(
    () => cn('VirtualizedChatView--list'),
    [],
  )

  const listStyle = React.useMemo((): React.CSSProperties => ({
    height: listHeight,
  }), [listHeight])

  function render() {
    return (
      <VirtualizedChatContext.Provider value={context}>
        <div ref={containerRef} className={containerClassNames} onScroll={handleScroll}>
          <div className={contentClassNames}>
            <div ref={spacerRef}/>
            <div className={listClassNames} style={listStyle}>
              {range(renderRange.start, renderRange.end).map(renderRow)}
            </div>
          </div>
        </div>
      </VirtualizedChatContext.Provider>
    )
  }

  function renderRow(index: number) {
    const key = keys[index]
    if (key == null) { return null }

    const style   = layout.rowStyle(index)
    const itemRef = layout.refFor(key)

    return renderMessage({key, index, style, itemRef})
  }

  return render()

})

export default VirtualizedChatView