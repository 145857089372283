import React from 'react'
import { useTranslation } from 'react-i18next'
import { CallToAction, WebInfo } from '~/models'
import { breakoutStore } from '~/stores'
import BreakoutSwitcher from '~/ui/app/breakouts/BreakoutSwitcher'
import CallToActionView from '~/ui/app/live/CallToActionView'
import SummaryInfo from '~/ui/app/live/SummaryInfo'
import { ImageView } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { Dimple, HBox, Label, Panel, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useLiveService } from './LiveContext'

export interface Props {
  classNames?: React.ClassNamesProp
}

const SummaryPanel = observer('SummaryPanel', (props: Props) => {

  const {classNames} = props

  const service = useLiveService()
  const whatsOn = service.whatsOn
  const {availableGroups, breakoutGroupID} = breakoutStore

  const callToAction = whatsOn.contentAs<CallToAction>('call-to-action', breakoutGroupID)
  const info         = whatsOn.contentAs<WebInfo>('info', breakoutGroupID)

  const {guide} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (whatsOn.isEmpty) { return null }

    return (
      <Panel classNames={classNames} branding={guide.web.summaryPanel}>
        <SummaryPanelHeader/>
        {renderInfo()}
        {renderBreakoutSwitcher()}
        {renderCallToAction()}
      </Panel>
    )
  }

  function renderInfo() {
    if (info == null) { return }

    return (
      <>
        <Dimple horizontal/>
        <VBox classNames={$.info}>
          <SummaryInfo info={info}/>
        </VBox>
      </>
    )
  }

  function renderBreakoutSwitcher() {
    if (availableGroups.length < 2) { return null }

    return (
      <>
        <Dimple horizontal/>
        <VBox classNames={$.breakoutSwitcher}>
          <BreakoutSwitcher/>
        </VBox>
      </>
    )
  }

  function renderCallToAction() {
    if (callToAction == null) { return null }

    return (
      <>
        <Dimple horizontal/>
        <VBox classNames={$.callToAction}>
          <CallToActionView
            callToAction={callToAction}
          />
        </VBox>
      </>
    )
  }

  return render()

})

const SummaryPanelHeader = observer('SummaryPanelHeader', () => {

  const {current} = useLiveService()

  const image   = current?.image
  const title   = current?.title
  const detail  = current?.detail

  const [t] = useTranslation('webcast')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.header} gap={layout.padding.inline.m}>
        <HBox gap={layout.padding.inline.l} align='top'>
          <HBox flex gap={layout.padding.inline.l}>
            {renderImage()}
            {renderLabels()}
          </HBox>
        </HBox>
      </VBox>
    )
  }

  function renderImage() {
    if (image == null) { return null }

    return (
      <ImageView
        classNames={$.image}
        source={image}
        size={imageSize}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox flex gap={layout.padding.inline.xs}>
        <Label h3>
          {title}
        </Label>
        {detail != null && (
          <Label small>
            {detail}
          </Label>
        )}
        <HBox gap={layout.padding.inline.s} align='baseline'>
          <Label small dim>
            {current?.breakout ? (
              t('breakout')
            ) : (
              t('plenary')
            )}
          </Label>
          <Label dim>•</Label>
          {renderTimes()}
        </HBox>
      </VBox>
    )
  }

  function renderTimes() {
    if (current == null) { return null }

    return (
      <>
        <Label light small caption dim>
          {current.start.toFormat('HH:mm')}
        </Label>
        {current.end != null && <Label>-</Label>}
        {current.end != null && (
          <Label light small caption dim>
            {current.end.toFormat('HH:mm')}
          </Label>
        )}
      </>
    )
  }

  return render()

})

export default SummaryPanel

export const imageSize = {width: 56, height: 56}

const useStyles = createUseStyles({
  header: {
    extend: 'section',
  },

  info: {
    extend: 'section',
  },

  breakoutSwitcher: {
    extend: 'section',
  },

  callToAction: {
    extend: 'section',
  },

  section: {
    padding: [layout.padding.inline.l, layout.padding.inline.l],
  },

  image: {
    borderRadius: layout.radius.m,
  },
})