import React from 'react'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import renderNavColumn from './columns'
import { useColumnNavigationStateProvider } from './NavigationStateContext'

export interface Props {
  index: number
  path:  string
}

const NavColumn = observer('NavColumn', (props: Props) => {

  const {index, path} = props

  const {setPath} = useColumnNavigationStateProvider()
  React.useEffect(() => {
    setPath(index, path)
  }, [index, path, setPath])

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        {renderNavColumn(path)}
      </VBox>
    )
  }

  return render()

})

export default NavColumn