import Color from 'color'
import { isFunction } from 'lodash'
import BrandingGuide from './BrandingGuide'

export default class ColorGuide {

  constructor(
    public readonly guide: BrandingGuide,
  ) {}

  public darkMode: boolean = false

  public readonly grayScale = {
    light: [
      new Color('#FFFFFF'),
      new Color('#E5EAE6'),
      new Color('#BDBDBD'),
      new Color('#777777'),
    ],
    dark: [
      new Color('#000000'),
      new Color('#212121'),
      new Color('#777777'),
      new Color('#AAAAAA'),
    ],
  }

  public readonly named = {
    transparent: new Color('transparent'),
    black:       new Color('black'),
    white:       new Color('white'),
    purple:      new Color('#E91D62'),
    blue:        new Color('#556AE6'),

    red:         new Color('#DC4356'),
    green:       new Color('#88D632'),
    lime:        new Color('#A9BB8E'),
    yellow:      new Color('#ffd948'),
    orange:      new Color('#DA5913'),
    pink:        new Color('#EF4078'),
    grayBlue:    new Color('#E2EAEB'),

    lightPurple: new Color('#F7F1F4'),
    lightGreen:  new Color('#C1EA95'),
    lightRed:    new Color('#F9DEE2'),
    lightBlue:   new Color('#99A6F0'),
  }

  //------
  // Semantic colors

  private _semantic?: SemanticColors & LightDarkColorMap<SemanticColors>

  public get semantic() {
    return this._semantic = this._semantic ?? createWithDefault(this, true, {
      dark: {
        primary:   this.named.purple,
        secondary: this.named.blue,
        positive:  this.named.green,
        negative:  this.named.red,
        neutral:   this.named.blue,
        warning:   this.named.yellow,
        focus:     this.named.blue,
      },
      light: {
        primary:   this.named.purple.lighten(0.2),
        secondary: this.named.blue.lighten(0.2),
        positive:  this.named.green.lighten(0.2),
        negative:  this.named.red.lighten(0.2),
        neutral:   this.named.blue.lighten(0.2),
        warning:   this.named.yellow.lighten(0.2),
        focus:     this.named.blue.lighten(0.2),
      },
    })
  }

  //------
  // Palette colors

  public palettes = {
    default: [
      this.named.green,
      this.named.yellow,
      this.named.orange,
      this.named.pink,
      this.named.purple,
      this.named.blue,
    ],
  }

  public palette(which: Palette, index: number, total: number = 1): Color {
    if (typeof which === 'string') {
      const palette    = this.palettes[which]
      const normalized = index % palette.length

      if (index < palette.length) {
        return palette[normalized]
      } else if (index < palette.length * 2) {
        return palette[normalized].darken(0.2)
      } else {
        return palette[normalized].lighten(0.2)
      }
    } else if ('alpha' in which) {
      return which.alpha.alpha(0.8 * (index / total))
    } else if ('fade' in which) {
      return which.fade.mix(this.named.white, 0.8 * (index / total))
    } else {
      return new Color()
    }
  }

  public paletteRandom(which: Exclude<PaletteKey, 'alpha'>) {
    const palette    = this.palettes[which]
    return this.palette(which, Math.floor(Math.random() * palette.length))
  }

  public resolve(arg: string | Color): Color {
    if (isFunction((arg as any)?.hex)) {
      return arg as Color
    }

    const nameOrHex = arg.toString()

    if (nameOrHex.startsWith('#')) {
      return new Color(nameOrHex)
    } else if (nameOrHex in (this.semantic.dark as any)) {
      return (this.semantic as any)[nameOrHex] as Color
    } else if (nameOrHex in (this.named as any)) {
      return (this.named as any)[nameOrHex] as Color
    } else if (/^(bg|fg|border)-([^-]+)$/.test(nameOrHex)) {
      const color = (this as any)[RegExp.$1][RegExp.$2]
      if (color != null) { return color }
    } else if (/^(bg|fg|border)-(light|dark)-(.+)$/.test(nameOrHex)) {
      const color = (this as any)[RegExp.$1][RegExp.$2][RegExp.$3]
      if (color != null) { return color }
    }

    console.warn(`Color "${nameOrHex}" could not be resolved`)
    return new Color('#ffffff')
  }

  //-------
  // Background, foreground, border

  private _bg?:     BackgroundColors & LightDarkColorMap<BackgroundColors>
  private _fg?:     ForegroundColors & LightDarkColorMap<ForegroundColors>
  private _border?: BorderColors & LightDarkColorMap<BorderColors>

  public get bg() {
    return this._bg = this._bg ?? createWithDefault(this, false, {
      dark:  {
        normal:    this.grayScale.dark[0],
        semi:      this.grayScale.dark[0].mix(this.grayScale.dark[1], 0.5),
        alt:       this.grayScale.dark[1],
        subtle:    this.grayScale.light[0].alpha(0.05),
        hover:     this.grayScale.light[0].alpha(0.1),
        active:    this.grayScale.light[0].alpha(0.2),
      },
      light: {
        normal:    this.named.lightPurple,
        semi:      this.named.lightPurple.mix(this.grayScale.light[0], 0.5),
        alt:       this.grayScale.light[0],
        subtle:    this.grayScale.dark[0].alpha(0.02),
        hover:     this.grayScale.dark[0].alpha(0.05),
        active:    this.grayScale.dark[0].alpha(0.1),
      },
    })
  }

  public get fg() {
    return this._fg = this._fg ?? createWithDefault(this, true, {
      light: {
        normal:      this.grayScale.light[0],
        dim:         this.grayScale.light[0].alpha(0.6),
        dimmer:      this.grayScale.light[0].alpha(0.2),
        highlight:   this.semantic.secondary.mix(this.grayScale.light[0], 0.7),
        placeholder: this.grayScale.light[1],
        error:       this.semantic.negative.lighten(0.7),
        link:        this.semantic.primary.mix(this.grayScale.light[0], 0.9),
      },
      dark: {
        normal:      this.grayScale.dark[1],
        dim:         this.grayScale.dark[1].alpha(0.5),
        dimmer:      this.grayScale.dark[1].alpha(0.3),
        highlight:   this.semantic.secondary,
        error:       this.semantic.negative,
        placeholder: this.grayScale.dark[3],
        link:        this.semantic.primary,
      },
    })
  }

  public get border() {
    return this._border = this._border ?? createWithDefault(this, true, {
      light: {
        normal: this.grayScale.light[0],
        dim:    this.grayScale.light[1],
        dimmer: this.grayScale.light[2].alpha(0.2),
      },
      dark: {
        normal: this.grayScale.dark[1],
        dim:    this.grayScale.dark[2],
        dimmer: this.grayScale.dark[3].alpha(0.2),
      },
    })
  }

  //------
  // Utility

  public lightColorThreshold = 0.7

  public isDark(color: Color) {
    return color.luminosity() < this.lightColorThreshold
  }

  public themeForBackground(color: Color) {
    return this.isDark(color) ? 'dark' : 'light'
  }

  public contrast(backgroundColor: Color): Color {
    return this.isDark(backgroundColor)
      ? this.fg.light.normal
      : this.fg.dark.normal
  }

}

function createWithDefault<M>(guide: ColorGuide, inverted: boolean, colors: Omit<LightDarkColorMap<M>, 'default'>): LightDarkColorMap<M> & M {
  return new Proxy({
    light: colors.light,
    dark:  colors.dark,
  }, {
    get: (target, key) => {
      if (key === 'light' || key === 'dark') {
        return target[key]
      } else if (guide.darkMode === inverted) {
        return (target.light as any)[key]
      } else {
        return (target.dark as any)[key]
      }
    },
  }) as LightDarkColorMap<M> & M
}

export type LightDarkColorMap<M> = {light: M, dark: M}

export interface SemanticColors {
  primary:   Color
  secondary: Color
  positive:  Color
  negative:  Color
  neutral:   Color
  warning:   Color
  focus:     Color
}

export interface BackgroundColors {
  normal:    Color
  semi:      Color
  alt:       Color
  subtle:    Color
  hover:     Color
  active:    Color
}

export interface ForegroundColors {
  normal:      Color
  dim:         Color
  dimmer:      Color
  highlight:   Color
  placeholder: Color
  error:       Color
  link:        Color
}

export interface BorderColors {
  normal: Color
  dim:    Color
  dimmer: Color
}

export type Palette =
  | PaletteKey
  | {fade: Color}
  | {alpha: Color}

export type PaletteKey = keyof ColorGuide['palettes']