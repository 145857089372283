// @index(Store\.ts$): export { default as ${variable} } from ${relpath}
export { default as appStore } from './appStore'
export { default as authenticationStore } from './authenticationStore'
export { default as bewizrStore } from './bewizrStore'
export { default as breakoutStore } from './breakoutStore'
export { default as challengesStore } from './challengesStore'
export { default as chatStore } from './chatStore'
export { default as clockStore } from './clockStore'
export { default as connectionsStore } from './connectionsStore'
export { default as infoStore } from './infoStore'
export { default as liveStore } from './liveStore'
export { default as mediaStore } from './mediaStore'
export { default as newsStore } from './newsStore'
export { default as notificationsStore } from './notificationsStore'
export { default as participantsStore } from './participantsStore'
export { default as previewStore } from './previewStore'
export { default as profileStore } from './profileStore'
export { default as projectStore } from './projectStore'
export { default as qAndAsStore } from './qAndAsStore'
export { default as rankingsStore } from './rankingsStore'
export { default as registrationStore } from './registrationStore'
export { default as searchStore } from './searchStore'
export { default as socketStore } from './socketStore'
// /index

// @index: export * from ${relpath}
export * from './appStore'
export * from './auth'
export * from './authenticationStore'
export * from './bewizr'
export * from './bewizrStore'
export * from './breakoutStore'
export * from './challenges'
export * from './challengesStore'
export * from './chat'
export * from './chatStore'
export * from './clockStore'
export * from './connections'
export * from './connectionsStore'
export * from './infoStore'
export * from './live'
export * from './liveStore'
export * from './mediaStore'
export * from './news'
export * from './newsStore'
export * from './notifications'
export * from './notificationsStore'
export * from './pages'
export * from './participants'
export * from './participantsStore'
export * from './previewStore'
export * from './profile'
export * from './profileStore'
export * from './projectStore'
export * from './q-and-as'
export * from './qAndAsStore'
export * from './rankings'
export * from './rankingsStore'
export * from './registration'
export * from './registrationStore'
export * from './search'
export * from './searchStore'
export * from './socketStore'
export * from './support'
// /index

Object.assign(global, {
  stores: {
    // @index(Store): ${variable/Store//:camel}: |>require(${relpath}).default,
    app:            require('./appStore').default,
    authentication: require('./authenticationStore').default,
    bewizr:         require('./bewizrStore').default,
    breakout:       require('./breakoutStore').default,
    challenges:     require('./challengesStore').default,
    chat:           require('./chatStore').default,
    clock:          require('./clockStore').default,
    connections:    require('./connectionsStore').default,
    info:           require('./infoStore').default,
    live:           require('./liveStore').default,
    media:          require('./mediaStore').default,
    news:           require('./newsStore').default,
    notifications:  require('./notificationsStore').default,
    participants:   require('./participantsStore').default,
    preview:        require('./previewStore').default,
    profile:        require('./profileStore').default,
    project:        require('./projectStore').default,
    qAndAs:         require('./qAndAsStore').default,
    rankings:       require('./rankingsStore').default,
    registration:   require('./registrationStore').default,
    search:         require('./searchStore').default,
    socket:         require('./socketStore').default,
    // /index
  },
})