import React from 'react'
import { useTranslation } from 'react-i18next'
import { Challenge, ChallengeTask } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, PushButton, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { useChallengeTaskNavigation } from './ChallengeTaskNavigationContext'

export interface Props {
  challenge:   Challenge
  task:        ChallengeTask
  hasAnswer:   boolean
  mayContinue: boolean
}

const ChallengeTaskNavigation = memo('ChallengeTaskNavigation', (props: Props) => {

  const {challenge, task, mayContinue} = props
  const {currentTaskIndex, previousTask, hasNext, completeTask} = useChallengeTaskNavigation()

  const isSkippable = task.question == null || task.question.skippable

  const [t] = useTranslation('challenges')

  //------
  // Rendering

  function render() {
    if (task == null || challenge == null) { return null }

    return (
      <VBox gap={layout.padding.inline.m}>
        {currentTaskIndex > 0 && (
          <HBox justify='center'>
            <ClearButton
              icon='arrow-left'
              caption={t('task.navigation.previous')}
              onTap={previousTask}
              small
            />
          </HBox>
        )}
        {!hasNext ? (
          <PushButton
            caption={t('task.navigation.complete')}
            enabled={mayContinue}
            onTap={completeTask}
          />
        ) : isSkippable ? (
          <PushButton
            icon='arrow-right'
            iconSide='right'
            caption={t('task.navigation.skip')}
            onTap={completeTask}
          />
        ) : (
          <PushButton
            icon='arrow-right'
            iconSide='right'
            caption={t('task.navigation.continue')}
            enabled={mayContinue}
            onTap={completeTask}
          />
        )}
      </VBox>
    )
  }

  return render()

})

export default ChallengeTaskNavigation