import '../init/app'
import './i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import { JssProvider } from 'react-jss'
import { Router } from 'react-router-dom'
import App from '~/ui/app/App'
import jss from '~/ui/styling/jss'
import history from './history'

ReactDOM.render(
  (
    <Router history={history}>
      <JssProvider jss={jss}>
        <App />
      </JssProvider>
    </Router>
  ),
  document.getElementById('root'),
)