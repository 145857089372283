import React from 'react'
import Color from 'color'
import { DateTime } from 'luxon'
import { VBox, Label } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { ResponsiveSize } from '~/ui/styling/layout'
import ScheduleLayout from './ScheduleLayout'

export interface Props {
  layout:      ScheduleLayout
  currentTime: DateTime
}

const ScheduleAxis = React.memo((props: Props) => {

  const {layout, currentTime} = props
  const {guide} = useStyling()

  const tickColor       = guide.web.schedule.resolve('tickColor')
  const activeTickColor = guide.web.schedule.resolve('activeTickColor')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.scheduleAxis}>
        {layout.axisDates.map((date, index) => {
          return renderAxisLabel(date, index, false)
        })}
        {currentTime != null && renderAxisLabel(currentTime, -1, true)}
      </div>
    )
  }

  function renderAxisLabel(date: DateTime, index: number, now: boolean) {
    const left = layout.dateToX(date)
    if (left == null) { return null }
    if (!now && left < 80) { return null }

    const color = guide.colors.resolve(now ? activeTickColor : tickColor)

    return (
      <VBox key={date.valueOf()} classNames={[$.axisLabel, {now}]} style={{left}}>
        <Label
          key={index}
          caption
          tiny
          bold={now}
          color={color}
          children={date.toFormat('HH:mm')}
        />
      </VBox>
    )
  }

  return render()

})

export default ScheduleAxis

const axisPadding = layout.padding.inline.s
const axisHeight  = (size: ResponsiveSize) => layout.padding.l[size]

const useStyles = createUseStyles(theme => ({
  scheduleAxis: {
    position: 'relative',
    overflow: 'hidden',

    ...layout.responsive(size => ({
      height: axisHeight(size),
    })),

    lineHeight: 1,
  },

  axisLabel: {
    position:   'absolute',
    top:        0,

    borderLeft:  [1, 'solid', theme.colors.resolve(theme.guide.web.schedule.resolve('tickColor'))],
    boxShadow:   ['inset', 2, 1, 0, -1, new Color('white')],
    paddingTop:  axisPadding,
    paddingLeft: layout.padding.inline.s,

    '&.now': {
      borderColor: theme.colors.resolve(theme.guide.web.schedule.resolve('activeTickColor')),
    },
  },
}))