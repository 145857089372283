import { observable } from 'mobx'
import socket from 'socket.io-react'
import { LiveService } from './live'
import { register } from './support'

export class LiveStore {

  @observable
  public currentLiveService: LiveService | null = null

  public liveService() {
    if (this.currentLiveService != null) {
      return this.currentLiveService
    }

    this.currentLiveService = new LiveService(socket)
    this.currentLiveService.addStopListener(() => {
      this.currentLiveService = null
    })

    return this.currentLiveService
  }

  public stopLiveService() {
    this.currentLiveService?.dispose()
    this.currentLiveService = null
  }

  public deinit() {
    this.currentLiveService?.dispose()
    this.currentLiveService = null
  }


}

const liveStore = register(new LiveStore())
export default liveStore