import React from 'react'
import { memo } from '~/ui/component'
import { BackButton, Dimple, HBox, Label, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  icon?:     SVGName
  caption?:  React.ReactNode
  right?:    React.ReactNode

  backHref?:  string
  onBackTap?: () => any
}

const PanelNavHeader = memo('PanelNavHeader', (props: Props) => {

  const {
    icon,
    caption,
    right,

    backHref,
    onBackTap,
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.panelNavHeader}>
        {renderMain()}
        <Dimple horizontal/>
      </VBox>
    )
  }

  function renderMain() {
    return (
      <HBox flex classNames={$.main} gap={layout.padding.inline.m}>
        {renderBackButton()}
        {icon && (
          <SVG
            name={icon}
            size={layout.icon.m}
          />
        )}
        {renderLabels()}
        {right}
      </HBox>
    )
  }

  function renderBackButton() {
    if (backHref == null && onBackTap == null) { return null }

    return (
      <BackButton
        href={backHref}
        onTap={onBackTap}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox flex>
        {isReactText(caption) ? (
          <Label h3>
            {caption}
          </Label>
        ) : caption}
      </VBox>
    )
  }

  return render()

})

export default PanelNavHeader

export const height = layout.barHeight.m + 2

const useStyles = createUseStyles({
  panelNavHeader: {
    height: height,
  },

  main: {
    padding: [layout.padding.inline.s, layout.padding.inline.l],
  },
})