import React from 'react'
import { profileStore } from '~/stores'
import { observer } from '~/ui/component'
import { Scroller, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import ParticipantProfile from './ParticipantProfile'

const ProfileContainer = observer('ProfileContainer', () => {

  const participant = profileStore.participant
  const padding   = layout.padding.inline.l

  //------
  // Rendering

  function render() {
    if (participant == null) { return null }

    return (
      <Scroller contentPadding={padding}>
        <VBox flex='both' align='center'>
          <ParticipantProfile
            participant={participant}
            editable={true}
          />
        </VBox>
      </Scroller>
    )
  }

  return render()

})

export default ProfileContainer