import React from 'react'
import { Helmet } from 'react-helmet'
import { FetchStatus } from 'mobx-document'
import { memo } from '~/ui/component'

export interface NavigationStateProviderContext {
  setPath:        (index: number, path: string) => any
  setTitle:       (title: string | null) => void
  setHeaderProps: (props: HeaderProps) => void
}

const NavigationStateProviderContext = React.createContext<NavigationStateProviderContext>({
  setPath:        () => void 0,
  setTitle:       () => void 0,
  setHeaderProps: () => void 0,
})

export interface NavigationStateContext {
  index:  number
  title:  string | null
  path:   string
  header: HeaderProps
}

export interface HeaderProps {
  caption:     React.ReactNode
  fetchStatus: FetchStatus | null
  renderRight: (() => React.ReactNode) | null
  reload:      (() => Promise<void>) | null
}

const NavigationStateContext = React.createContext<NavigationStateContext>({
  index:  0,
  title:  null,
  path:   '/',
  header: {
    caption:     null,
    fetchStatus: 'idle',
    renderRight: null,
    reload:      () => Promise.resolve(void 0),
  },
})

export function useColumnNavigationStateProvider() {
  return React.useContext(NavigationStateProviderContext)
}

export function useColumnNavigationState() {
  return React.useContext(NavigationStateContext)
}

export interface NavigationStateContainerProps {
  children?: React.ReactNode
}

export const NavigationStateContainer = memo('NavigationStateContainer', (props: NavigationStateContainerProps) => {

  const {children} = props

  const [index, setIndex] = React.useState<number>(0)
  const [path, setPath] = React.useState<string>('/')
  const [title, setTitle] = React.useState<string | null>(null)

  const [headerProps, setHeaderProps] = React.useState<HeaderProps>({
    caption:      '',
    fetchStatus: 'idle',
    renderRight: null,
    reload:      () => Promise.resolve(void 0),
  })

  const providerContext = React.useMemo((): NavigationStateProviderContext => ({
    setPath: (index, path) => {
      setIndex(index)
      setPath(path)
    },
    setTitle,
    setHeaderProps,
  }), [])

  const consumerContext = React.useMemo(() => ({
    index,
    path,
    title,
    header: headerProps,
  }), [headerProps, index, title, path])

  return (
    <NavigationStateProviderContext.Provider value={providerContext}>
      <NavigationStateContext.Provider value={consumerContext}>
        {title != null && <Helmet title={title}/>}
        {children}
      </NavigationStateContext.Provider>
    </NavigationStateProviderContext.Provider>
  )

})