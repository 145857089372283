import React from 'react'
import socket from 'socket.io-react'
import { useClock } from '~/socket/useClock'
import { observer } from '~/ui/component'
import { Label, LabelProps, Spinner } from '~/ui/components'

export interface Props extends Omit<LabelProps, 'children'> {
  showSpinnerWhenConnecting?: boolean
}

const Clock = observer('Clock', (props: Props) => {

  const {showSpinnerWhenConnecting = false} = props

  const {currentTime} = useClock()
  const socketStatus  = socket.status
  const connected     = socketStatus === 'ready'

  //------
  // Rendering

  function render() {
    if (connected) {
      return renderClock()
    } else if (showSpinnerWhenConnecting) {
      return <Spinner/>
    } else {
      return null
    }
  }

  function renderClock() {
    if (currentTime == null) { return null }

    return (
      <Label h2 {...props}>
        {currentTime.toFormat('HH:mm')}
      </Label>
    )
  }

  return render()

})

export default Clock