import React from 'react'
import { Webcast } from '~/models'
import { breakoutStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, ThemeProvider } from '~/ui/styling'
import { useLiveService } from './LiveContext'
import WaitingRoom from './WaitingRoom'
import WebcastConferenceCall from './WebcastConferenceCall'
import WebcastIFrame from './WebcastIFrame'

const WebViewer = observer('WebViewer', () => {

  const service = useLiveService()
  const whatsOn = service.whatsOn

  const groupID = breakoutStore.breakoutGroupID

  const webcast = whatsOn.contentAs<Webcast>('webcast', groupID)
  const content = webcast?.content ?? null

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (content == null) {
      return renderWaitingRoom()
    } else {
      return renderViewer()
    }
  }

  function renderWaitingRoom() {
    return <WaitingRoom/>
  }

  function renderViewer() {
    return (
      <ThemeProvider dark>
        <VBox flex classNames={$.viewer}>
          {renderContent()}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderContent() {
    if (content == null) { return null }

    switch (content.type) {
      case 'url':
        return <WebcastIFrame content={content}/>
      case 'conference':
        return <WebcastConferenceCall content={content} />
    }
  }

  return render()

})

export default WebViewer

const useStyles = createUseStyles({
  viewer: {
    position: 'relative',
    overflow: 'hidden',
  },
})