import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { HBox, Label, SVG } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ChannelHeader from './ChannelHeader'
import { useChat } from './ChatContext'

const ChatHeader = observer(() => {

  const {channel} = useChat()

  const [t] = useTranslation('chat')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (channel == null) {
      return renderDefaultHeader()
    } else {
      return (
        <ChannelHeader
          controller={channel}
        />
      )
    }
  }

  function renderDefaultHeader() {
    return (
      <HBox gap={layout.padding.inline.m} classNames={$.defaultHeader}>
        <SVG
          name='chat'
          size={layout.icon.m}
        />
        <Label bold>
          {t('title')}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default ChatHeader

export const height = layout.barHeight.m

const useStyles = createUseStyles({
  defaultHeader: {
    height,
    padding: [
      layout.padding.inline.m,
      layout.padding.inline.l,
    ],
  },
})