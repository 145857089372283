import React from 'react'
import { useSize } from 'react-measure'
import { useMediaQuery } from 'react-responsive'
import { range } from 'lodash'
import { MenuPage, MenuPageItem } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Scroller, Tile, VBox } from '~/ui/components'
import { layout, useStyling } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import MenuItemSignup from './MenuItemSignup'

export interface Props {
  page: MenuPage

  HeaderComponent?: React.ComponentType<any> | null
  EmptyComponent?:  React.ComponentType<any> | null
}

const MenuGridView = memo('MenuGridView', (props: Props) => {

  const {
    page,
    HeaderComponent,
    EmptyComponent,
  } = props

  const isMobile  = !useMediaQuery({minWidth: screenMinWidths.tablet})

  const gridRef = React.useRef<HTMLDivElement>(null)
  const [gridSize, setGridSize] = React.useState<Size>({width: 0, height: 0})
  useSize(gridRef, {debounce: 100}, setGridSize)

  const availableWidth = React.useMemo(
    () => gridSize.width - itemGap,
    [gridSize.width],
  )

  const {items = []} = page

  const columns = React.useMemo(() => {
    if (gridSize.width === 0 || isMobile) { return 2 }
    return Math.max(1, Math.floor(availableWidth / (minTileWidth + itemGap)))
  }, [availableWidth, gridSize, isMobile])

  const rows = React.useMemo(
    () => Math.ceil(items.length / columns),
    [items, columns],
  )

  const columnWidth = availableWidth / columns - itemGap
  const minTileHeight = columnWidth * 1.1

  //------
  // Rendering

  function render() {
    return (
      <Scroller>
        {items.length === 0 ? (
          EmptyComponent && <EmptyComponent/>
        ) : (
          renderGrid()
        )}
      </Scroller>
    )
  }

  function renderGrid() {
    return (
      <VBox gap={itemGap}>
        {HeaderComponent && <HeaderComponent />}
        <VBox ref={gridRef} gap={itemGap} padding={gridPadding}>
          {range(0, rows).map(renderRow)}
        </VBox>
      </VBox>
    )
  }

  function renderRow(row: number) {
    return (
      <HBox align='stretch' justify={isMobile ? 'left' : 'center'} key={row} gap={itemGap}>
        {items.slice(columns * row, columns * (row + 1)).map(item => (
          <VBox key={item.link.href} flex style={{maxWidth: columnWidth}}>
            <MenuGridTile
              item={item}
              minHeight={minTileHeight}
            />
          </VBox>
        ))}
      </HBox>
    )
  }

  return render()

})

export default MenuGridView

interface MenuGridTileProps {
  item:      MenuPageItem
  minHeight: number
}

const MenuGridTile = memo('MenuGridTile', (props: MenuGridTileProps) => {

  const {item, minHeight} = props

  const {guide} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <Tile
        image={item.image}
        label={item.caption}
        accessory={renderExtra()}
        href={item.link.href}
        target={item.link.target}
        align='center'
        minHeight={minHeight}
        branding={guide.menu.tile}
      />
    )
  }

  function renderExtra() {
    return (
      <>
        {item.extra.signup && <MenuItemSignup item={item} layout='grid'/>}
      </>
    )
  }

  return render()

})

export const gridPadding  = layout.padding.inline.l
export const itemGap      = layout.padding.inline.l
export const minTileWidth = 170
