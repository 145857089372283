import React from 'react'
import SummaryPanel from '~/ui/app/live/SummaryPanel'
import WebViewer from '~/ui/app/live/WebViewer'
import CollapsedNavContainer from '~/ui/app/navigation/CollapsedNavContainer'
import Schedule from '~/ui/app/schedule/Schedule'
import { observer } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {}

const TabletLiveLayout = observer('TabletLiveLayout', (props: Props) => {

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox flex align='stretch' padding={contentPadding} gap={contentGap}>
        <VBox flex={3} gap={layout.padding.m}>
          <WebViewer />
          {renderSchedule()}
        </VBox>

        <VBox flex={1} classNames={$.sidebar} gap={layout.padding.m}>
          <SummaryPanel/>
          <CollapsedNavContainer />
        </VBox>
      </HBox>
    )
  }

  function renderSchedule() {
    return(
      <VBox classNames={$.schedule}>
        <Schedule
          padding={layout.padding.l.desktop}
        />
      </VBox>
    )
  }

  return render()

})

const contentPadding = layout.padding.m
const contentGap     = layout.padding.m

export default TabletLiveLayout

const useStyles = createUseStyles({
  sidebar: {
    minWidth: 320,
  },

  schedule: layout.responsive(size => ({
    marginLeft:  -contentPadding[size],
    marginRight: -contentGap[size],
  })),
})