import React from 'react'
import { useTranslation } from 'react-i18next'
import { Webcast } from '~/models'
import { Countdown } from '~/ui/app/clock'
import { ImageView } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { Center, HBox, Label, Markdown, Panel, VBox } from '~/ui/components'
import { animation, createUseStyles, fonts, layout } from '~/ui/styling'
import { useLiveService } from '../live/LiveContext'

export interface Props {
  collapsed?: boolean
}

const NextUp = observer('NextUp', (props: Props) => {

  const service = useLiveService()
  const nextUp  = service.nextUp
  const content = nextUp?.content[0] ?? null

  const startTime    = nextUp?.start
  const image        = nextUp?.image
  const title        = nextUp?.title
  const detail       = nextUp?.detail
  const announcement = nextUp?.type === 'webcast' ? (content?.item as Webcast).announcement : null

  const [t] = useTranslation('home')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.nextUp}>
        <Panel flex='both' contentClassNames={$.content}>
          <VBox gap={layout.padding.m}>
            {renderHeader()}
            {renderAnnouncement()}
          </VBox>
        </Panel>
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <HBox gap={layout.padding.l} align='stretch'>
        <HBox flex gap={layout.padding.m}>
          {renderImage()}
          {renderHeaderMain()}
        </HBox>
        {renderCountdown()}
      </HBox>
    )
  }

  function renderImage() {
    if (image == null) { return null }

    return (
      <ImageView
        source={image}
        classNames={$.image}
        size={imageSize}
      />
    )
  }

  function renderHeaderMain() {
    return (
      <VBox gap={layout.padding.s} classNames={$.headerMain}>
        <Label h3>
          {startTime?.toFormat('H:mm')}
        </Label>
        <VBox gap={layout.padding.inline.xs}>
          <Label bold>
            {title}
          </Label>
          {detail != null && (
            <Label small dim>
              {detail}
            </Label>
          )}
        </VBox>
      </VBox>
    )
  }

  function renderCountdown() {
    if (startTime == null) { return null }

    return (
      <VBox>
        <Center classNames={$.countdown} gap={layout.padding.inline.xs}>
          <Label tiny dim align='center'>
            {t('next_up.starts_in')}
          </Label>
          <Countdown
            to={startTime}
            align='right'
            h3
          />
        </Center>
      </VBox>
    )
  }

  function renderAnnouncement() {
    if (announcement == null) { return null }

    return (
      <VBox classNames={$.instructions}>
        <Markdown
          children={announcement}
        />
      </VBox>
    )
  }

  return render()

})

export default NextUp

const imageSize = {
  width:  64,
  height: 64,
}

const useStyles = createUseStyles(theme => ({
  nextUp: {
    minWidth: 320,
    maxWidth: 720,
  },

  headerMain: {
    flex: [1, 1, '240px'],
  },

  content: {
    ...layout.responsiveProp({
      padding: layout.padding.m,
    }),
  },

  image: {
    borderRadius: layout.radius.m,
  },

  countdown: {
    padding:      layout.padding.inline.l,
    background:   theme.bg.subtle,
    borderRadius: layout.radius.s,
    minHeight:    64,
  },

  instructions: {
    willChange: 'opacity',
    transition: animation.transitions.medium('opacity'),

    ...fonts.responsiveFontStyle(theme.fonts.small),
    color: theme.fg.normal,
  },
}))