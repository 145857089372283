import React from 'react'
import { VBox } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'

export interface Props {
  children?: React.ReactNode
}

export default function AppLayout(props: Props) {

  const {children} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.content} flex='grow'>
        {children}
      </VBox>
    )
  }

  return render()

}

const useStyles = createUseStyles({
  content: {
    position: 'relative',
  },
})