import { DateTime } from 'luxon'
import { datetime } from '../serialization'
import { BeWizrModel } from './BeWizrModel'

export class BeWizrLearningTrack extends BeWizrModel {

  public title!: string

  public itemCount: number = 0

  @datetime()
  public startDate!: DateTime | null

  @datetime()
  public endDate!: DateTime | null

  public descriptionHtml!: string

  public links!: {
    image: string | null
  }

  public items?: BeWizrLearningTrackItem[]

  protected afterDeserialize() {
    this.items = this.raw.items?.map(
      (it: any) => BeWizrLearningTrackItem.deserialize(it),
    )
  }

}

export class BeWizrLearningTrackItem extends BeWizrModel {

  public title!: string
  public slug!:  string

  public type!: 'course' | 'meeting'

  @datetime()
  public startDate!: DateTime | null

  @datetime()
  public endDate!: DateTime | null

  public progress!: number | null

  public links!: {
    self:  string
    image: string | null
  }

  public isLocked?: boolean

}