import { DateTime } from 'luxon'
import { datetime } from '../serialization'
import { BeWizrModel } from './BeWizrModel'

export class BeWizrCourse extends BeWizrModel {

  public slug!:  string
  public title!: string

  public isLocked!: boolean

  public progress!: number | null

  public links!: {
    home:             string
    image:            string | null
    continue:         string | null
    backgroundImage: string | null
    certificatePdf:  string | null
  }

  @datetime()
  public lastLoginAt?: DateTime | null

  public descriptionHtml?: string | null

  @datetime()
  public certificatePassedAt?: DateTime | null

  public certificateScore?: number | null

  public deliverCertificate: boolean = false

  public items?: BeWizrCourseItem[]

  protected afterDeserialize() {
    this.items = this.raw.courseitems?.map(
      (it: any) => BeWizrCourseItem.deserialize(it),
    )
  }
}

export class BeWizrCourseItem extends BeWizrModel {

  public slug!:  string
  public title!: string

  public courseTitle!: string

  public isLocked!: boolean

  public items?:       BeWizrCourseItem[]
  public assignments!: BeWizrCourseItem[]

  public stats!: BeWizrCourseItemStats | null

  public links!: {
    elearning: string
  }

  protected afterDeserialize() {
    this.items = this.raw.courseitems
      ?.filter((it: any) => it.blocks.length === 0)
      .map((it: any) => BeWizrCourseItem.deserialize(it))
    this.assignments = this.raw.courseitems
      ?.filter((it: any) => it.blocks.length > 0)
      .map((it: any) => BeWizrCourseItem.deserialize(it))

    this.stats = this.raw.stats == null
      ? null
      : BeWizrCourseItemStats.deserialize(this.raw.stats)
  }

}

export class BeWizrCourseItemStats extends BeWizrModel {

  public status!: BeWizrCourseItemStatus | null

  @datetime()
  public completedAt!: DateTime | null

  @datetime()
  public failedAt!: DateTime | null

  @datetime()
  public visitedAt!: DateTime | null

  protected afterDeserialize() {
    this.status = this.raw.status?.toLowerCase()
  }
}

export type BeWizrCourseItemStatus = 'completed' | 'in_progress' | 'failed'