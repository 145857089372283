import React from 'react'
import { useTranslation } from 'react-i18next'
import { IndexEntry, IndexPage } from '~/models'
import { infoStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, HBox, List, Markdown, SearchField, VBox } from '~/ui/components'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import renderIndexItem from './items'

export interface Props {
  page: IndexPage
}

const IndexPageView = observer('IndexPageView', (props: Props) => {

  const {page} = props
  const document = infoStore.pages.document(page.slug)
  const endpoint = document.indexEndpoint
  const entries  = endpoint?.entriesWithItems ?? []

  const fetchMore = React.useCallback(() => {
    return endpoint?.fetchNextPage()
  }, [endpoint])

  const search = React.useCallback((query: string | null) => {
    endpoint?.setSearchQuery(query)
  }, [endpoint])

  const [t] = useTranslation('info')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (endpoint == null) { return null }

    return (
      <List
        data={entries}
        contentClassNames={$.content}
        HeaderComponent={renderHeader}
        EmptyComponent={renderEmpty}
        keyExtractor={keyExtractor}
        renderItem={renderEntry}
        onEndReached={fetchMore}
        itemGap={layout.padding.inline.s}
        scrollable
      />
    )
  }

  const renderHeader = React.useCallback(() => {
    return (
      <VBox classNames={$.header} gap={layout.padding.s}>
        {page.caption != null && (
          <HBox justify='center'>
            <Markdown classNames={$.caption}>
              {page.caption}
            </Markdown>
          </HBox>
        )}
        <SearchField
          onSearch={search}
          inputStyle='light'
        />
      </VBox>
    )
  }, [$.caption, $.header, page.caption, search])

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        flex
        {...t('index_empty')}
      />
    )
  }, [t])

  const keyExtractor = React.useCallback(
    (entry: IndexEntry) => entry.id,
    [],
  )

  const renderEntry = React.useCallback((entry: IndexEntry & {item: any}) => {
    if (entry.item == null) { return null }

    return renderIndexItem(entry.item, page)
  }, [page])

  return render()

})

export default IndexPageView

const useStyles = createUseStyles(theme => ({
  content: {
    padding: layout.padding.inline.l,
  },

  header: {
    ...layout.responsiveProp({
      marginBottom: layout.padding.s,
    }),
  },

  caption: {
    width: layout.contentWidth,
    maxWidth: '100%',

    ...fonts.responsiveFontStyle(theme.guide.fonts.small),
    color:         theme.colors.fg.dark.dim,
    textAlign:     'center',
  },
}))