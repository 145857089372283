import React from 'react'
import { useHistory } from '~/history'
import { getWellKnownBackHref } from '~/navigation'
import { connectionsStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, HBox, Spinner } from '~/ui/components'
import { layout } from '~/ui/styling'
import { useColumnNavigationState } from '../navigation/NavigationStateContext'
import PanelNavHeader from '../PanelNavHeader'

const NavColumnHeader = observer('NavColumnHeader', () => {

  const {path, header: {caption, fetchStatus, renderRight, reload}} = useColumnNavigationState()

  const history   = useHistory()
  const backHref  = getWellKnownBackHref(path, connectionsStore.participantsPage)
  const canGoBack = history.stack.length > 1 || backHref != null

  const goBack = React.useCallback(() => {
    history.goBack({
      fallbackPath: backHref ?? undefined,
    })
  }, [backHref, history])

  //------
  // Rendering

  function render() {
    return (
      <PanelNavHeader
        onBackTap={canGoBack ? goBack : undefined}
        caption={caption}
        right={renderHeaderRight()}
      />
    )
  }

  function renderHeaderRight() {
    return (
      <HBox gap={layout.padding.inline.l}>
        {renderRight?.()}
        {renderFetchingOrReload()}
      </HBox>
    )
  }

  function renderFetchingOrReload() {
    if (fetchStatus === 'fetching') {
      return (
        <Spinner size={8}/>
      )
    } else if (reload != null) {
      return (
        <ClearButton
          icon='reload'
          onTap={reload}
        />
      )
    }
  }


  return render()

})

export default NavColumnHeader