import React from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from '~/history'
import { memo } from '~/ui/component'
import { Panel, PanelProps } from '~/ui/components'
import ErrorBoundary from '../ErrorBoundary'
import NavColumn from './NavColumn'
import NavColumnHeader from './NavColumnHeader'

export interface Props extends Omit<PanelProps, 'header'> {}

const NavPanel = memo('NavPanel', (props: Props) => {

  const location =     useLocation()
  const {stack}  =     useHistory()

  //------
  // Rendering

  function render() {
    return (
      <ErrorBoundary>
        <Panel header={renderHeader()} {...props}>
          {renderBody()}
        </Panel>
      </ErrorBoundary>
    )
  }

  function renderHeader() {
    return (
      <NavColumnHeader/>
    )
  }

  function renderBody() {
    return (
      <NavColumn
        index={stack.length - 1}
        path={location.pathname}
      />
    )
  }

  return render()

})

export default NavPanel