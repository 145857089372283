import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSocketStatus } from 'socket.io-react'
import { appStore, authenticationStore, profileStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import {
  Center,
  ConfirmBox,
  HBox,
  Label,
  PopupMenu,
  PopupMenuItem,
  Spinner,
  SVG,
  Tappable,
  Tooltip,
  VBox,
} from '~/ui/components'
import {
  animation,
  createUseStyles,
  layout,
  shadows,
  ThemeProvider,
  useStyling,
} from '~/ui/styling'

const AuthStatus = observer('AuthStatus', () => {

  const connected   = useSocketStatus() === 'ready'
  const offline     = !appStore.online
  const participant = profileStore.participant

  const [t] = useTranslation('auth')

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!connected) {
      return renderConnecting()
    } else if (participant != null) {
      return (
        <HBox gap={layout.padding.m}>
          <PopupMenu items={popupMenuItems}>
            {renderAvatar}
          </PopupMenu>
        </HBox>
      )
    } else {
      return null
    }
  }

  function renderConnecting() {
    if (offline) {
      return (
        <Tooltip renderTooltip={t('app:offline')}>
          <SVG
            name='offline'
            size={layout.icon.m}
            dim
          />
        </Tooltip>
      )
    } else {
      return (
        <Center classNames={$.connecting}>
          <Spinner size={12}/>
        </Center>
      )
    }
  }

  function renderAvatar(toggle: () => any) {
    if (participant == null) { return null }

    return (
      <Tappable classNames={$.avatar} onTap={toggle}>
        <Avatar
          source={participant.photoURL}
          firstName={participant.firstName}
          lastName={participant.lastName}
          size={avatarSize}
        />
      </Tappable>
    )
  }

  const renderPopupMenuHeader = React.useCallback(() => {
    return (
      <ThemeProvider contrast='primary'>
        <VBox>
          <Label bold>
            {participant?.name}
          </Label>
          <Label small>
            {participant?.email}
          </Label>
        </VBox>
      </ThemeProvider>
    )
  }, [participant])

  //------
  // Menu actions

  const logOut = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('logout.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    authenticationStore.logOut()
  }, [t])

  const popupMenuItems = React.useMemo((): PopupMenuItem[] => participant == null ? [] : [{
    section:         renderPopupMenuHeader(),
    backgroundColor: colors.semantic.secondary,
  }, {
    icon:     'user',
    caption:  t('profile.caption'),
    href:     '/profile',
  }, {
    section: '-',
  }, {
    caption: t('app:nav.privacy'),
    href:    'https://groundcontrol.app/privacy',
    color:    colors.fg.dim,
  }, {
    caption: t('app:nav.terms'),
    href:    'https://groundcontrol.app/terms',
    color:    colors.fg.dim,
  }, {
    section: '-',
  }, {
    icon:     'logout',
    caption:  t('logout.caption'),
    color:    colors.semantic.negative,
    onSelect: logOut,
  }], [participant, renderPopupMenuHeader, colors.semantic.secondary, colors.semantic.negative, colors.fg.dim, t, logOut])

  return render()

})

export default AuthStatus

const avatarSize = {
  width:  40,
  height: 40,
}

const useStyles = createUseStyles({
  connecting: {
    ...avatarSize,
  },

  avatar: {
    ...avatarSize,
    background:   'white',
    borderRadius: avatarSize.width / 2,

    willChange: 'box-shadow',
    transition: animation.transitions.medium('box-shadow'),

    boxShadow: shadows.depth(1),
    '&:hover': {
      boxShadow: shadows.depth(4),
    },
  },
})