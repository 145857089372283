import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuPage } from '~/models'
import { memo } from '~/ui/component'
import { Empty, HBox, Markdown } from '~/ui/components'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import MenuGridView from './MenuGridView'
import MenuListView from './MenuListView'

export interface Props {
  page: MenuPage
}

const MenuPageView = memo('MenuPageView', (props: Props) => {

  const {page} = props
  const [t] = useTranslation('info')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (page?.layout === 'list') {
      return (
        <MenuListView
          page={page}
          HeaderComponent={renderHeader}
          EmptyComponent={renderEmpty}
        />
      )
    } else {
      return (
        <MenuGridView
          page={page}
          HeaderComponent={renderHeader}
          EmptyComponent={renderEmpty}
        />
      )
    }
  }

  const renderHeader = React.useMemo(() => {
    if (page.caption == null) { return null }

    return () => (
      <HBox justify='center'>
        <Markdown classNames={$.caption}>
          {page.caption}
        </Markdown>
      </HBox>
    )
  }, [$.caption, page.caption])

  const renderEmpty = React.useCallback(() => {
    return (
      <Empty
        flex
        {...t('menu_empty')}
      />
    )
  }, [t])

  return render()

})

export default MenuPageView

const useStyles = createUseStyles(theme => ({
  caption: {
    width: layout.columnWidth,
    maxWidth: '100%',
    ...fonts.responsiveFontStyle(theme.guide.fonts.small),
    color:     theme.colors.fg.dark.dim,
    padding:   layout.padding.inline.l,
    textAlign: 'center',
  },
}))