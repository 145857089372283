import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { resolveAppHref } from '~/navigation'
import { appStore, authenticationStore, projectStore } from '~/stores'
import { useLocationChange, useQueryParam } from '~/ui/hooks'
import { screenWidths } from '~/ui/styling/layout'
import AuthNavigator from '../navigator/AuthNavigator'
import RootNavigator from './RootNavigator'

const AppNavigator = observer(() => {

  const location   = useLocation()
  const history    = useHistory()

  const appReady = appStore.ready
  const {isLoggedIn} = authenticationStore

  const [magicLoginToken] = useQueryParam('auth')

  const isOnAuthPage  = /^\/(login|auth(\/.*))(\/|$)/.test(location.pathname)
  const isOnAuthReset = /^\/auth\/reset/.test(location.pathname)
  const isMagicLogin  = /^\/login$/.test(location.pathname) && magicLoginToken != null

  const isDesktop = useMediaQuery({minWidth: screenWidths.desktop})

  const project = projectStore?.project

  const tabs = React.useMemo(() => {
    if (isDesktop && project?.web.layout === 'full') {
      return projectStore.tabs.filter(it => /^(?!\/\/chat).*/.test(it.link.href))
    }
    return projectStore.tabs
  }, [isDesktop, project])

  const defaultPath = React.useMemo(
    () => tabs.length > 0 ? resolveAppHref(tabs[0].link.href) : null,
    [tabs],
  )

  const isOnRootPage  = location.pathname == null || location.pathname === '/'

  //------
  // App links

  const [applink, setApplink] = useQueryParam('applink', 'string')

  React.useEffect(() => {
    if (applink == null || !appReady || !isLoggedIn) { return }
    const href = resolveAppHref(applink)
    history.push(href)
    setApplink(null)
  }, [applink, appReady, isLoggedIn, history, setApplink])

  //------
  // Auto-redirect

  const autoRedirect = React.useCallback(() => {
    if (!appReady) { return }

    // If the user visits the auth reset page or if this is a magic login, log them out.
    if (isLoggedIn && (isOnAuthReset || isMagicLogin)) {
      authenticationStore.logOut()
    }

    // If the user is not logged in, redirect him to the login page.
    else if (!isOnAuthPage && !isLoggedIn) {
      history.replace('/login')
    }

    // On the other hand, if the user is logged in, redirect him to the default path.
    else if (isLoggedIn && defaultPath != null && (isOnAuthPage || (isOnRootPage &&  location.pathname !== defaultPath))) {
      history.replace(defaultPath)
    }
  }, [appReady, isLoggedIn, isOnAuthReset, isMagicLogin, isOnAuthPage, isOnRootPage, location.pathname, history, defaultPath])

  useLocationChange(autoRedirect)

  //------
  // Rendering

  function render() {
    if (isLoggedIn) {
      return <RootNavigator/>
    } else {
      return <AuthNavigator/>
    }
  }

  return render()

})

export default AppNavigator
