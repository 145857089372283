import React from 'react'

interface PageColumnContext {
  addReloadHandler: (handler: () => void) => () => void
}

const PageColumnContext = React.createContext<PageColumnContext>({
  addReloadHandler: () => () => void 0,
})

export function usePageReloadHandler(handler: () => void) {
  const {addReloadHandler} = React.useContext(PageColumnContext)

  React.useEffect(
    () => addReloadHandler(handler),
    [addReloadHandler, handler],
  )
}

export default PageColumnContext