import { action, computed, makeObservable, observable } from 'mobx'
import socket, { SendResponse } from 'socket.io-react'
import { Participant, ProfileDatum } from '~/models'
import authenticationStore from './authenticationStore'
import participantsStore from './participantsStore'
import { GroupSignup } from './profile'
import { register, SubmitResult } from './support'
import { submitResultForResponse } from './support/responses'

export class ProfileStore {

  constructor() {
    makeObservable(this)
  }

  @observable
  public participantID: string | null = null

  @computed
  public get participant() {
    if (this.participantID == null) { return null }

    const document = participantsStore.participants.document(this.participantID)
    return document?.data ?? null
  }

  public requestUpdate() {
    socket.emit('profile:request')
  }

  @action
  private onLogOut = () => {
    this.participantID = null
  }

  //------
  // Groups

  public groupSignup(signupID: string, groupID: string) {
    return new GroupSignup(signupID, groupID)
  }

  //------
  // Editing

  @observable
  public editing: boolean = false

  @action
  public startEdit() {
    this.editing = true
  }

  @action
  public stopEdit() {
    this.editing = false
  }

  public getDatum(name: string): ProfileDatum | null {
    const {participant} = this
    if (participant == null) { return null }

    return participant.profileData?.find(datum => datum.name === name) ?? null
  }

  //------
  // Updates

  @action
  public async updateProfile(updates: AnyObject) {
    const response = await socket.send('profile:update', updates)
    return this.onUpdateProfileComplete(response)
  }

  @action
  private onUpdateProfileComplete = (response: SendResponse<{participant: Participant, weblink: boolean}>): SubmitResult => {
    if (response.ok) {
      const participant = Participant.deserialize(response.body.participant)
      const document    = participantsStore.participants.store(participant)
      this.participantID = document.id
    }

    return submitResultForResponse(response)
  }

  //------
  // Initialization

  public init() {
    authenticationStore.on('logout', this.onLogOut)
    socket.addEventListener('profile:update', this.onProfileUpdate)
  }

  @action
  private onProfileUpdate = (json: AnyObject) => {
    const participant = Participant.deserialize(json.participant)
    const document    = participantsStore.participants.store(participant)

    this.participantID = document.id
  }

}

export default register(new ProfileStore())