import React from 'react'
import { CallToAction } from '~/models'
import { VBox, Markdown } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import CallToActionButton from './CallToActionButton'

export interface Props {
  callToAction: CallToAction
}

const CallToActionView = React.memo((props: Props) => {

  const {callToAction} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderPrompt()}
        {renderButton()}
      </VBox>
    )
  }

  function renderPrompt() {
    if (callToAction.prompt == null) { return null }

    return (
      <Markdown classNames={$.prompt}>
        {callToAction.prompt}
      </Markdown>
    )
  }

  function renderButton() {
    return (
      <CallToActionButton
        caption={callToAction.caption}
        href={callToAction.href}
      />
    )
  }

  return render()

})

export default CallToActionView

const useStyles = createUseStyles({
  prompt: {
    textAlign:  'center',

    '& img': {
      display: 'block',
      margin:  [0, 'auto'],
    },
  },
})