import React from 'react'
import { CustomImage } from '~/models'
import { memo } from '~/ui/component'
import { Center, HBox, Label, LinkLabel, Panel, PushButton, Tappable, VBox } from '~/ui/components'
import SVG, { SVGName } from '~/ui/components/SVG'
import { colors, createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { ImageView } from '../media'

export interface Props {
  title:         string
  href:          string
  buttonCaption: string
  image:         string | CustomImage | null
  subtitle?:     React.ReactNode
  icon?:         SVGName
  detail?:       React.ReactNode
  children?:     React.ReactNode
  horizontal?:   boolean
  locked?:       boolean
}

const BeWizrTile = memo('BeWizrTile', (props: Props) => {

  const {title, href, buttonCaption, subtitle, detail, icon, image, children, locked, horizontal} = props

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.BeWizrTile} backgroundColor={colors.bg.alt}>
        {horizontal ? (
          <HBox align='stretch'>
            {renderContent()}
          </HBox>
        ) : (
          <VBox>
            {renderContent()}
          </VBox>
        )}
      </Panel>
    )
  }

  function renderContent() {
    return (
      <>
        {renderImage()}
        {renderBody()}
      </>
    )
  }

  function renderImage() {
    return (
      <VBox classNames={$.image} flex={horizontal}>
        <Tappable flex href={href}>
        {renderDetail()}
        {renderLocked()}
        {image != null && (
          <ImageView
            source={image}
            objectFit='cover'
            flex
          />
        )}
        </Tappable>
      </VBox>
    )
  }

  function renderDetail() {
    if (detail == null && icon == null) { return null }
    return (
      <VBox classNames={$.detail} align='left'>
        {renderIcon()}
        {detail}
      </VBox>
    )
  }

  function renderLocked() {
    if (!locked) { return null }

    return (
      <Center classNames={$.locked}>
        <ThemeProvider dark>
          <SVG
            name='lock'
            size={layout.icon.xxl}
          />
        </ThemeProvider>
      </Center>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }
    return (
      <Center classNames={$.icon}>
        <SVG
          name={icon}
          size={layout.icon.s}
        />
      </Center>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.s} padding={layout.padding.inline.l} flex={horizontal}>
        <VBox gap={layout.padding.inline.s}>
          {renderTitle()}
          {renderSubtitle()}
        </VBox>
        <VBox>
          {children}
        </VBox>
        {renderButtons()}
      </VBox>
    )
  }

  function renderTitle() {
    return (
      <LinkLabel
        h3
        truncate={false}
        children={title}
        color={colors.fg.normal}
        href={href}
      />
    )
  }

  function renderSubtitle() {
    if (subtitle == null) { return null }
    return (
      <Label dim small>
        {subtitle}
      </Label>
    )
  }

  function renderButtons() {
    return (
      <VBox flex='grow' justify='bottom'>
        <PushButton
          href={href}
          caption={buttonCaption}
        />
      </VBox>
    )
  }

  return render()

})

export default BeWizrTile

const useStyles = createUseStyles(theme => ({
  BeWizrTile: {
    borderRadius: 10,
    overflow:     'hidden',
    position:     'relative',
  },

  image: {
    position: 'relative',
    backgroundColor: theme.fg.dimmer,
    aspectRatio: 3 / 2,
  },

  icon: {
    padding: layout.padding.inline.s,
    borderRadius: '50%',
    backgroundColor: theme.colors.named.white,
  },

  detail: {
    position: 'absolute',
    top:   layout.padding.inline.m,
    left:  layout.padding.inline.m,
    right: layout.padding.inline.m,
    zIndex: 20,
  },

  locked: {
    ...layout.overlay,
    backgroundColor: colors.shim.dark,
  },
}))