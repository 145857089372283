import React from 'react'
import { WebcastURLContent } from '~/models'
import { createUseStyles, layout, presets, shadows } from '~/ui/styling'

export interface Props {
  content: WebcastURLContent
}

const WebcastIFrame = React.memo((props: Props) => {

  const {content} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <iframe
        classNames={$.webcastIFrame}
        src={content.url}
        title={content.url}
      />
    )
  }

  return render()

})

export default WebcastIFrame

const useStyles = createUseStyles(theme => ({
  webcastIFrame: {
    width:        '100%',
    height:       '100%',
    border:       'none',
    background:   theme.colors.bg.dark.alt,
    borderRadius: layout.radius.m,

    ...presets.overlayAfter({
      boxShadow: ['inset', 2, 2, 4, shadows.shadowColor],
    }),
  },
}))