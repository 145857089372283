import React from 'react'
import { WebInfo } from '~/models'
import { memo } from '~/ui/component'
import { Markdown } from '~/ui/components'

export interface Props {
  info: WebInfo
}

const SummaryInfo = memo('SummaryInfo', (props: Props) => {

  const {info} = props

  //------
  // Rendering

  function render() {
    return (
      <Markdown>
        {info.body}
      </Markdown>
    )
  }

  return render()

})

export default SummaryInfo