import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContentPage, IndexPage, MenuPage } from '~/models'
import { infoStore } from '~/stores'
import { observer } from '~/ui/component'
import { Chip, EmptyOrFetching, HBox, Label, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import NavColumnHelmet from '../navigation/NavColumnHelmet'
import ContentPageView from './content/ContentPageView'
import IndexPageView from './index/IndexPageView'
import MenuPageView from './menu/MenuPageView'
import PageColumnContext from './PageColumnContext'

export interface Props {
  slug: string
}

const PageColumn = observer('PageColumn', (props: Props) => {

  const {slug} = props

  const [t] = useTranslation('info')

  const document    = infoStore.pages.document(slug)
  const fetchStatus = document.fetchStatus
  const page        = document.data

  const fetch = React.useCallback(async () => {
    await document.fetch()
  }, [document])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  const indexEndpoint = document.indexEndpoint
  const indexTotal    = indexEndpoint?.meta?.total
  const caption = React.useMemo(() => {
    if (page == null) { return null }

    if (indexTotal != null) {
      return (
        <HBox flex='shrink' gap={layout.padding.inline.m}>
          <Label flex='shrink' h3>
            {page.title}
          </Label>
          <Chip small dim>
            {indexTotal}
          </Chip>
        </HBox>
      )
    } else {
      return page.title
    }
  }, [indexTotal, page])

  //------
  // Reload handlers

  const reloadHandlersRef = React.useRef<Set<() => void>>(new Set())

  const context = React.useMemo((): PageColumnContext => ({
    addReloadHandler: (handler: () => void) => {
      reloadHandlersRef.current.add(handler)
      return () => {
        reloadHandlersRef.current.delete(handler)
      }
    },
  }), [])

  const reload = React.useCallback(() => {
    for (const handler of reloadHandlersRef.current) {
      handler()
    }
    fetch()
  }, [fetch])

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        <NavColumnHelmet
          title={page?.title ?? null}
          caption={caption}
          fetchStatus={fetchStatus}
          reload={reload}
        />

        <PageColumnContext.Provider value={context}>
          {renderContent()}
        </PageColumnContext.Provider>
      </VBox>
    )
  }

  function renderContent() {
    if (page == null || fetchStatus === 'fetching') {
      return renderEmpty()
    }

    if (page instanceof MenuPage) {
      return <MenuPageView page={page}/>
    } else if (page instanceof IndexPage) {
      return <IndexPageView page={page}/>
    } else if (page instanceof ContentPage) {
      return <ContentPageView page={page}/>
    } else {
      console.warn(`Unknown page type: ${page.constructor.name}`)
      return null
    }
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        {...t('page_not_found', {slug})}
        flex
      />
    )
  }

  return render()

})

export default PageColumn