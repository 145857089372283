import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { WebScheduleItem } from '~/models'
import { memo } from '~/ui/component'
import { Empty, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import ScheduleLayout from './ScheduleLayout'
import TimelineBar, { height as itemBarHeight } from './TimelineBar'

export interface Props {
  items:       WebScheduleItem[]
  layout:      ScheduleLayout
  currentTime: DateTime
}

const ScheduleTimeline = memo('ScheduleTimeline', (props: Props) => {

  const {items, layout, currentTime} = props

  const [t] = useTranslation('schedule')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.scheduleTimeline}>
        <div>
          {renderCurrentTime()}
          {items.length === 0 ? (
            renderEmpty()
          ) : (
            items
            .filter(item => layout.isVisible(item))
            .map(renderEntry)
          )}
        </div>
      </div>
    )
  }

  function renderEmpty() {
    return (
      <Empty
        flex
        {...t('empty')}
      />
    )
  }

  function renderEntry(item: WebScheduleItem) {
    const itemLayout = layout.itemLayout(item)
    if (itemLayout == null) { return null }

    const key = `${item.start.valueOf()}:${item.uuid}`
    return (
      <VBox key={key} classNames={$.item} style={itemLayout}>
        <TimelineBar
          item={item}
          barStyle='timeline'
          paddingLeft={Math.max(0, layout.margin - itemLayout.left)}
          nowPlaying={isNowPlaying(item)}
        />
      </VBox>
    )
  }

  function isNowPlaying(item: WebScheduleItem) {
    if (currentTime == null) { return false }

    if (item.start > currentTime) { return false }
    if (item.end != null && item.end < currentTime) { return false }
    return true
  }

  function renderCurrentTime() {
    const left = currentTime == null ? null : layout.dateToX(currentTime)
    if (left == null) { return null }

    return (
      <div
        classNames={$.currentTime}
        style={{left}}
      />
    )
  }

  return render()

})

export default ScheduleTimeline

const useStyles = createUseStyles(theme => {
  const depth   = theme.guide.web.schedule.resolve('depth')
  const padding = depth > 0 ? layout.padding.inline.m : 2
  const height  = itemBarHeight + 2 * padding

  const activeTickColor = theme.guide.web.schedule.resolve('activeTickColor')

  return {
    scheduleTimeline: {
      position: 'relative',
      overflow: 'hidden',
      height:   height,

      // Give a negative margin to prevent showing the shadow on the sides. Compensate in a content div.
      margin:   [0, -2],

      '& > *': {
        ...layout.overlay,
        ...layout.flex.column,
        left:  2,
        right: 2,
      },

      background: depth > 0 ? (theme.isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)') : undefined,
      boxShadow:  shadows.depth(-depth / 2),
    },

    item: {
      position: 'absolute',
      top:      padding,
      bottom:   padding,
    },

    currentTime: {
      position:   'absolute',
      top:        0,
      bottom:     0,
      borderLeft: [1, 'solid', theme.guide.colors.resolve(activeTickColor)],
    },
  }
})