import React from 'react'
import { useTranslation } from 'react-i18next'
import clipboard from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { MenuPageItem } from '~/models'
import { memo } from '~/ui/component'
import { Center, HBox, KebabMenu, Label, ListItem, PopupMenuItem, SVG, VBox } from '~/ui/components'
import { imageSize as listItemImageSize } from '~/ui/components/ListItem'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import MenuItemSignup from './MenuItemSignup'

export interface Props {
  item:      MenuPageItem
  hasImages: boolean
}

const MenuListItem = memo('MenuListItem', (props: Props) => {

  const {item, hasImages} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListItem
        classNames={$.menuListItem}
        image={item.image ?? renderEmptyImage()}
        imageBorderRadius={layout.radius.s}
        caption={item.caption}
        detail={renderDetail()}
        href={item.link.href}
        target={item.link.target}
        accessory={renderAccessory()}
      />
    )
  }

  function renderDetail() {
    return (
      <HBox gap={layout.padding.inline.m} classNames={$.menuListItemDetail}>
        {isReactText(item.detail) ? (
          <Label flex='shrink' small dim>
            {item.detail}
          </Label>
        ) : item.detail != null ? (
          <VBox flex='shrink'>
            {item.detail}
          </VBox>
        ) : null}
        {item.extra.signup && <MenuItemSignup item={item} layout='list'/>}
      </HBox>
    )
  }

  function renderAccessory() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <SVG name='chevron-right' size={layout.icon.m} dimmer/>
        <MenuListItemMenu item={item}/>
      </HBox>
    )
  }

  function renderEmptyImage() {
    if (!hasImages) { return null }
    return (
      <Center style={listItemImageSize}/>
    )
  }

  return render()

})

export default MenuListItem

interface MenuListItemMenuProps {
  item: MenuPageItem
}

const MenuListItemMenu = memo('MenuListItemMenu', (props: MenuListItemMenuProps) => {

  const {item} = props

  const [t] = useTranslation('info')

  const copyURL = React.useCallback(() => {
    clipboard.write([
      {type: 'text/plain', data: item.link.href},
      {type: ClipboardType.RICH_LINK, data: {
        caption: item.caption,
        link:    item.link,
      }},
    ])
  }, [item])

  const menuItems = React.useMemo((): PopupMenuItem[] => [{
    icon:     'link',
    caption:  t('copy_url'),
    onSelect: copyURL,
  }], [t, copyURL])

  //------
  // Rendering

  function render() {
    return (
      <KebabMenu items={menuItems}/>
    )
  }

  return render()

})

const useStyles = createUseStyles({
  menuListItem: {
    height:  layout.barHeight.l,
  },

  menuListItemDetail: {
    marginTop: layout.padding.inline.s,
  },
})