import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { observer } from 'mobx-react'
import { Center, Empty, Label, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import NextUp from '../home/NextUp'
import { useLiveService } from './LiveContext'

const WaitingRoom = observer(() => {

  const {current, nextUp} = useLiveService()

  const [t] = useTranslation('home')
  const {colors} = useStyling()

  const isTablet = useMediaQuery({minWidth: screenMinWidths.tablet})

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.waitingRoom} flex gap={layout.padding.l}>
        {renderContent()}
      </VBox>
    )
  }

  function renderContent() {
    return (
      <Center classNames={$.content} flex gap={layout.padding.l} padding={layout.padding.l}>
        {renderImage()}

        {nextUp != null ? (
          renderNextUp()
        ) : (
          renderEmpty()
        )}
      </Center>
    )
  }

  function renderImage() {
    const lit = current != null || nextUp != null
    return (
      <SVG
        name='tv'
        classNames={$.image}
        dimmer={!lit}
        color={lit ? colors.semantic.primary : undefined}
      />
    )
  }

  function renderEmpty() {
    if (isTablet) { return null }
    return (
      <Empty
        h2
        {...t(`empty`)}
      />
    )
  }

  function renderNextUp() {
    return (
      <VBox align='center' gap={layout.padding.s}>
        <Label h2>
          {t('next_up.title')}
        </Label>
        <NextUp/>
      </VBox>
    )
  }

  return render()

})

export default WaitingRoom

const imageSize = {
  width:  96,
  height: 96,
}

const useStyles = createUseStyles(theme => ({
  waitingRoom: {
    position:     'relative',
    borderRadius: layout.radius.l,
    border:       [1, 'solid', theme.fg.normal.alpha(0.1)],
  },

  image: {
    width: imageSize.width / 3,
    height: imageSize.height / 3,
    ...layout.tablet({
      ...imageSize,
    }),
  },

  content: {
    position: 'relative',
  },
}))