import React from 'react'
import { observer } from 'mobx-react'
import { projectStore } from '~/stores'
import { AppLayout } from '~/ui/layouts/app'
import CollapsedLayout from './CollapsedLayout'
import FullLayout from './FullLayout'
import { HomeChatContainer } from './HomeChatContext'
import LiveLayout from './LiveLayout'

const HomeScreen = observer(() => {

  const {project} = projectStore
  const layout = project?.web.layout

  //------
  // Rendering

  function render() {
    return (
      <AppLayout>
        <HomeChatContainer>
          {renderLayout()}
        </HomeChatContainer>
      </AppLayout>
    )
  }

  function renderLayout() {
    if (layout == null) { return null }

    switch (layout) {
      case 'live': return <LiveLayout/>
      case 'chat': return <CollapsedLayout/>
      case 'full': return <FullLayout/>
    }
  }

  return render()

})

export default HomeScreen