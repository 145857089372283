import React from 'react'
import { FetchStatus } from 'mobx-document'
import { observer } from '~/ui/component'
import { useColumnNavigationStateProvider } from '../navigation/NavigationStateContext'

export interface Props {
  title:        string | null
  caption?:     React.ReactNode
  fetchStatus?: FetchStatus
  renderRight?: () => React.ReactNode
  reload?:      () => any
}

const NavColumnHelmet = observer('NavColumnHelmet', (props: Props) => {

  const {
    title,
    caption = title,
    fetchStatus,
    renderRight,
    reload,
  } = props

  const {setHeaderProps, setTitle} = useColumnNavigationStateProvider()

  React.useEffect(() => {
    setHeaderProps({
      caption:     caption,
      fetchStatus: fetchStatus ?? null,
      renderRight: renderRight ?? null,
      reload:      reload ?? null,
    })
  }, [caption, fetchStatus, reload, renderRight, setHeaderProps])

  React.useEffect(() => {
    setTitle(title)
  }, [setTitle, title])

  return null

})

export default NavColumnHelmet