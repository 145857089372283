import React from 'react'
import { BrandedComponent, HBox, Label, SVG, Tappable, TappableProps, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, presets, shadows, useStyling } from '~/ui/styling'

export interface Props extends Omit<TappableProps, keyof React.HTMLAttributes<any>> {
  icon?:       SVGName
  caption?:    string
  classNames?: React.ClassNamesProp
}

const CallToActionButton = React.memo((props: Props) => {

  const {
    icon,
    caption,
    enabled = true,
    classNames: props_classNames,
    ...rest
  } = props

  //------
  // Rendering

  const {guide} = useStyling()
  const $       = useStyles()

  const borderRadius = guide.callToActionButton.borderRadius(height)

  function render() {
    const classNames = [
      $.callToActionButton,
      {disabled: !enabled},
      props_classNames,
    ]

    return (
      <Tappable classNames={classNames} enabled={enabled} style={{borderRadius}} {...rest}>
        <BrandedComponent flex branding={guide.callToActionButton} height={height}>
          {renderContent()}
        </BrandedComponent>
      </Tappable>
    )
  }

  function renderContent() {
    return (
      <HBox flex gap={layout.padding.inline.m} classNames={$.content} justify='center'>
        {renderIcon()}
        {renderCaption()}
      </HBox>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }
    return <SVG name={icon} size={layout.icon.m}/>
  }

  function renderCaption() {
    if (caption == null) { return null }

    return (
      <VBox flex='shrink'>
        <Label caption align='center'>
          {props.caption}
        </Label>
      </VBox>
    )
  }

  return render()

})

export default CallToActionButton

export const height   = 40
export const minWidth = 128

const useStyles = createUseStyles(theme => ({
  callToActionButton: {
    position: 'relative',
    height:   height,
    minWidth: minWidth,

    '&.disabled': {
      opacity: 0.6,
    },

    ...presets.overlayAfter(),

    '&:focus, &:focus-within': {
      boxShadow: shadows.focus.bold(theme),
    },

    '&:hover::after': {
      background: theme.guide.callToActionButton.background({}).theme === 'dark'
        ? theme.colors.bg.dark.hover
        : theme.colors.bg.light.hover,
    },

    '&:active::after': {
      background: theme.guide.callToActionButton.background({}).theme === 'dark'
        ? theme.colors.bg.dark.active
        : theme.colors.bg.light.active,
    },
  },

  content: {
    padding:  [layout.padding.inline.xs, layout.padding.inline.l],
  },
}))