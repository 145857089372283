import { makeAutoObservable } from 'mobx'
import { Document, Endpoint } from 'mobx-document'
import authenticationStore from './authenticationStore'
import { SearchMeta, SearchParams } from './search'
import { register } from './support'

export class SearchStore {

  constructor() {
    makeAutoObservable(this)
  }

  public readonly endpoints: Map<string, SearcheableEndpoint<any>> = new Map()

  public registerEndpoint<E extends SearcheableEndpoint<any, any, any>>(resource: string, endpoint: E) {
    this.endpoints.set(resource, endpoint)
  }

  public unregisterEndpoint(resource: string) {
    this.endpoints.delete(resource)
  }

  public getEndpoint(resource: string) {
    const endpoint = this.endpoints.get(resource)
    if (endpoint == null) {
      console.warn(`Endpoint for resource "${resource}" not available`)
    }

    return endpoint
  }

  public get allEndpoints() {
    return Array.from(this.endpoints.values())
  }

  //------
  // Searching

  public query: string | null = null

  public search(query: string | null) {
    this.query = query

    if (query == null) {
      this.allEndpoints.forEach(it => it.clear())
    } else {
      this.allEndpoints.forEach(it => it.fetchWithParams({query}, {limit: 10}))
    }
  }

  //------
  // Log out

  public init() {
    authenticationStore.on('logout', this.onLogOut)
  }

  public onLogOut = () => {
    for (const endpoint of this.endpoints.values()) {
      endpoint.clear()
    }
  }
}

export default register(new SearchStore())

export type SearcheableEndpoint<
  D extends Document<any>,
  P extends SearchParams = SearchParams,
  M extends SearchMeta = SearchMeta,
> = Endpoint<D, P, M> & {
  fetchMore: () => Promise<any>
}