import React from 'react'
import { useTimer } from 'react-timer'
import { omit } from 'lodash'
import Modernizr from 'modernizr'
import { forwardRef } from '~/ui/component'
import { VBoxProps } from '~/ui/components'
import { assignRef } from '~/ui/hooks'
import { createUseStyles, layout, presets, shadows } from '~/ui/styling'
import { closest, isInteractiveElement } from '~/ui/util'
import { flexStyle } from '../layout/styles'
import { TappableContext } from './TappableContext'
import { TappableState } from './types'
import { getXYFromEvent } from './util'

export interface Props extends React.HTMLAttributes<any> {
  tag?:            string
  enabled?:        boolean

  onTap?:          (event: React.SyntheticEvent) => any
  onSecondaryTap?: () => void
  onStateChange?:  (state: TappableState) => void

  longTapDuration?: number

  href?:   string | null
  target?: string

  preventDefault?: boolean
  cancelOnMove?:   boolean | number
  autoFocus?:      boolean
  focusable?:      boolean

  showFocus?:  boolean
  noFeedback?: boolean
  flex?:       VBoxProps['flex']

  classNames?:   React.ClassNamesProp
  style?:        React.CSSProperties
  children?:     React.ReactNode
}

const Tappable = forwardRef('Tappable', (props: Props, ref: React.Ref<HTMLElement>) => {

  //------
  // State

  const [focused, setFocused] = React.useState(false)
  const [hover, setHover]     = React.useState(false)
  const [active, setActive]   = React.useState(false)

  const {
    href:   props_href,
    target: props_target,
    onTap,
    onSecondaryTap,
    longTapDuration = 500,

    tag          = props_href == null ? 'div' : 'a',
    enabled      = true,
    focusable    = true,
    showFocus    = false,
    noFeedback   = false,
    cancelOnMove = true,

    onStateChange,
    classNames,
    flex,
    style,

    ...other
  } = props

  const cancelOnMoveThreshold = !cancelOnMove ? null : cancelOnMove === true ? 1 : cancelOnMove

  const {
    targetForHref,
    resolveHref,
    navigate,
  } = React.useContext(TappableContext)

  const target = props_target ?? (props_href != null ? targetForHref(props_href) : undefined)
  const href   = props_href != null ? resolveHref(props_href) : props_href

  //------
  // Rendering

  const $      = useStyles()
  const domRef = React.useRef<HTMLElement>()

  const connect = React.useCallback((element: HTMLElement | null) => {
    assignRef(domRef, element)
    assignRef(ref, element)
  }, [ref])

  function render() {
    const allClassNames = [
      $.tappable,
      enabled && focusable && showFocus && $.showFocus,
      {disabled: !enabled, noFeedback},
      classNames,
    ]

    const styles = {
      ...flexStyle(flex),
      ...style,
    }

    return React.createElement(tag, {
      ref: connect,

      classNames: allClassNames,
      style:      styles,
      role:       href == null ? 'button' : undefined,
      tabIndex:   (!focusable || !enabled) ? -1 : 0,

      href,
      target,

      // Inject all other properties.
      ...omit(other, 'onTap', 'onStateChange', 'preventDefault', 'target', 'staticContext', 'match', 'location', 'history'),

      // Override event handlers.
      onFocus:       onFocus,
      onBlur:        onBlur,
      onMouseEnter:  onMouseEnter,
      onMouseLeave:  onMouseLeave,
      onTouchStart:  onTouchStart,
      onTouchCancel: onTouchCancel,
      onMouseDown:   onMouseDown,
      onMouseUp:     onMouseUp,
      onTouchEnd:    onTouchEnd,
      onClick:       onClick,
      onDoubleClick: onDoubleClick,
      onKeyDown:     onKeyDown,
      onContextMenu: onContextMenu,
    })
  }

  //------
  // Derived

  const isOwnTarget = React.useCallback((event: React.SyntheticEvent<any>) => {
    const closestInteractive = closest(event.target as HTMLElement, isInteractiveElement)
    return closestInteractive === event.currentTarget
  }, [])

  //------
  // Tap handling

  const performTap = React.useCallback((event: React.SyntheticEvent) => {
    if (!enabled || !isOwnTarget(event)) { return }

    const isCanceled = () => !event.cancelable || event.isDefaultPrevented()

    if (!isCanceled()) {
      onTap?.(event)
    }

    if (href != null) {
      if (!isCanceled()) {
        navigate?.(href, target, event)
      }
    } else if (props.preventDefault !== false && event.cancelable) {
      event.preventDefault()
    }
  }, [enabled, isOwnTarget, props.preventDefault, onTap, navigate, href, target])

  //------
  // TappableState

  React.useEffect(() => {
    if (!enabled) {
      const tappableState = {focused: false, hover: false, active: false}
      onStateChange?.(tappableState)
    } else {
      const tappableState = {focused, hover, active}
      onStateChange?.(tappableState)
    }
  }, [enabled, focused, hover, active, onStateChange])

  const onFocus = React.useCallback((event: React.FocusEvent) => {
    if (enabled) { setFocused(true) }
    props.onFocus?.(event)
  }, [enabled, props])

  const onBlur = React.useCallback((event: React.FocusEvent) => {
    setFocused(false)
    props.onBlur?.(event)
  }, [props])

  //------
  // Cancel on move

  const canceledRef  = React.useRef<boolean>(false)
  const moveStartRef = React.useRef<Point | null>()

  const checkCancelOnMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (cancelOnMoveThreshold == null) { return }
    if (moveStartRef.current == null) { return }

    const xy = getXYFromEvent(event)
    if (Math.abs(xy.x - moveStartRef.current.x) > cancelOnMoveThreshold) {
      canceledRef.current = true
    }
    if (Math.abs(xy.y - moveStartRef.current.y) > cancelOnMoveThreshold) {
      canceledRef.current = true
    }
  }, [cancelOnMoveThreshold])

  const startCancelOnMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (cancelOnMoveThreshold == null) { return }
    if (moveStartRef.current != null) { return }

    moveStartRef.current = getXYFromEvent(event)
    canceledRef.current = false

    window.addEventListener('mousemove', checkCancelOnMove)
    window.addEventListener('touchmove', checkCancelOnMove)
  }, [cancelOnMoveThreshold, checkCancelOnMove])

  const stopCancelOnMove = React.useCallback(() => {
    if (moveStartRef.current == null) { return }

    window.removeEventListener('mousemove', checkCancelOnMove)
    window.removeEventListener('touchmove', checkCancelOnMove)

    moveStartRef.current = null
    return canceledRef.current
  }, [checkCancelOnMove])

  //------
  // Mouse / touch events

  const longTouchTimer = useTimer()

  const onMouseEnter = React.useCallback((event: React.MouseEvent) => {
    if (enabled) { setHover(true) }
    props.onMouseEnter?.(event)
  }, [enabled, props])

  const onMouseLeave = React.useCallback((event: React.MouseEvent) => {
    setHover(false)
    setActive(false)
    stopCancelOnMove()
    props.onMouseLeave?.(event)
  }, [props, stopCancelOnMove])

  const onTouchStart = React.useCallback((event: React.TouchEvent) => {
    if (isOwnTarget(event) && enabled) {
      setActive(true)
    }

    startCancelOnMove(event.nativeEvent)
    props.onTouchStart?.(event)

    if (onSecondaryTap != null) {
      longTouchTimer.clearAll()
      longTouchTimer.setTimeout(() => {
        onSecondaryTap?.()
      }, longTapDuration)
    }
  }, [enabled, isOwnTarget, longTapDuration, longTouchTimer, onSecondaryTap, props, startCancelOnMove])

  const onTouchEnd = React.useCallback((event: React.TouchEvent) => {
    if (isOwnTarget(event)) { setActive(false) }
    stopCancelOnMove()

    props.onTouchEnd?.(event)

    longTouchTimer.clearAll()

    if (!canceledRef.current) {
      performTap(event)
    }
  }, [isOwnTarget, longTouchTimer, performTap, props, stopCancelOnMove])

  const onTouchCancel = React.useCallback((event: React.TouchEvent) => {
    if (isOwnTarget(event)) {
      setHover(false)
      setActive(false)
    }
    stopCancelOnMove()
    props.onTouchCancel?.(event)
    longTouchTimer.clearAll()
  }, [isOwnTarget, longTouchTimer, props, stopCancelOnMove])

  const onMouseDown = React.useCallback((event: React.MouseEvent) => {
    if (isOwnTarget(event)) {
      setActive(true)
      setHover(true)
    }

    props.onMouseDown?.(event)
    startCancelOnMove(event.nativeEvent)

    if (isOwnTarget(event)) {
      event.preventDefault()
    }
  }, [isOwnTarget, props, startCancelOnMove])

  const onMouseUp = React.useCallback((event: React.MouseEvent) => {
    if (isOwnTarget(event)) {
      setActive(false)
    }
    if (!stopCancelOnMove()) {
      event.preventDefault()
    }

    props.onMouseUp?.(event)
  }, [isOwnTarget, props, stopCancelOnMove])

  const onClick = React.useCallback((event: React.MouseEvent) => {
    props.onClick?.(event)
    if (canceledRef.current) {
      event.preventDefault()
    } else {
      performTap(event)
    }
  }, [performTap, props])

  const onDoubleClick = React.useCallback((event: React.MouseEvent) => {
    props.onDoubleClick?.(event)
    if (canceledRef.current) {
      event.preventDefault()
    } else {
      onSecondaryTap?.()
    }
  }, [onSecondaryTap, props])

  const onKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    props.onKeyDown?.(event)

    const isSpaceOrReturn = event.which === 0x20 || event.which === 0x0D
    if (isSpaceOrReturn) {
      performTap(event)
    }
  }, [performTap, props])

  const onContextMenu = React.useCallback((event: React.SyntheticEvent) => {
    if (onSecondaryTap != null && !Modernizr.pointerevents) {
      event.preventDefault()
    }
  }, [onSecondaryTap])

  //------
  // Interface

  const {autoFocus} = props

  React.useEffect(() => {
    if (autoFocus) {
      domRef.current?.focus()
    }
  }, [autoFocus])

  return render()

})

export default Tappable

const useStyles = createUseStyles(theme => ({
  tappable: {
    cursor:         'pointer',
    userSelect:     'none',
    outline:        'none',
    textDecoration: 'none',

    ...layout.flex.column,

    '&.disabled': {
      cursor: 'default',
    },

    '&:not(.disabled):not(.noFeedback)': {
      position: 'relative',
      ...presets.overlayBefore({
        borderRadius: 'inherit',
      }),
      '&:hover::before': {
        background: theme.bg.hover,
      },
      '&:active::before': {
        background: theme.bg.active,
      },
    },
  },

  showFocus: {
    '&:focus:not(:active)': {
      boxShadow: shadows.focus.bold(theme),
    },
  },
}))