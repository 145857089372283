import React from 'react'
import { useTranslation } from 'react-i18next'
import { WebScheduleItem } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, Label, Tappable, Tooltip, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'

export interface Props {
  item:         WebScheduleItem
  barStyle:     'list' | 'timeline'
  onTap?:       (item: WebScheduleItem) => any
  paddingLeft?: number
  nowPlaying?:  boolean
}

const TimelineBar = memo('TimelineBar', (props: Props) => {

  const {item, onTap, barStyle, paddingLeft, nowPlaying = false}  = props
  const {image, title, detail} = item

  const {guide} = useStyling()
  const branding = guide.web.schedule.itemBar

  const nowPlayingColor = guide.web.schedule.resolve('nowPlayingColor')

  const [t] = useTranslation('schedule')

  const tap = React.useMemo(() => onTap == null ? undefined : () => {
    onTap(item)
  }, [item, onTap])

  //------
  // Rendering

  const $ = useStyles()
  const flex = barStyle === 'list' ? 'grow' : true

  const overrides = React.useMemo(() => {
    if (nowPlaying) {
      return {
        fg: {
          normal: guide.colors.resolve(nowPlayingColor),
        },
      }
    } else {
      return {}
    }
  }, [nowPlaying, guide.colors, nowPlayingColor])

  function render() {
    const style = {
      paddingLeft: Math.max(layout.padding.inline.l, paddingLeft ?? 0),
    }

    return (
      <BrandedComponent
        classNames={$.scheduleItemBar}
        branding={branding}
        variant={{nowplaying: nowPlaying}}
        height={height}
      >
        <ThemeProvider overrides={overrides}>
          <Tappable flex onTap={tap}>
            <HBox flex={flex} classNames={$.content} style={style} gap={layout.padding.inline.m}>
              {image != null && (
                <ImageView
                  classNames={$.image}
                  source={image}
                  size={imageSize}
                />
              )}
              {renderLabels()}
            </HBox>
          </Tappable>
        </ThemeProvider>
      </BrandedComponent>
    )
  }

  function renderLabels() {
    return (
      <VBox flex gap={layout.padding.inline.xs}>
        <Label light tiny caption dim>
          {nowPlaying ? t('now_playing').toLocaleUpperCase() : item.start.toFormat('HH:mm')}
        </Label>
        {renderTitle()}
        {renderDetail()}
      </VBox>
    )
  }

  function renderTitle() {
    if (barStyle === 'list') {
      return (
        <Label h3 truncate={false}>
          {title}
        </Label>
      )
    } else {
      return (
        <Tooltip preferredSide='near' crossAlign='near' renderTooltip={title}>
          <Label h3>
            {title}
          </Label>
        </Tooltip>
      )
    }
  }

  function renderDetail() {
    if (detail == null) { return null }

    if (barStyle === 'list') {
      return (
        <Label small dim truncate={false}>
          {detail}
        </Label>
      )
    } else {
      return (
        <Label small dim>
          {detail}
        </Label>
      )
    }
  }

  return render()

})

export default TimelineBar

export const height    = 64
export const imageSize = {width: 48, height: 48}

const useStyles = createUseStyles({
  scheduleItemBar: {
    minHeight: height,
  },

  content: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  image: {
    borderRadius: layout.radius.m,
  },

  time: {
    width: '2.2em',
  },
})