import { Document, DocumentFetchResponse } from 'mobx-document'
import { BeWizrCourseItem } from '~/models'

export default class BeWizrCourseItemDocument extends Document<BeWizrCourseItem> {

  protected async performFetch(): Promise<DocumentFetchResponse<BeWizrCourseItem | null, any> | null | undefined> {
    // Not supported
    return null
  }

}