import React from 'react'
import { useTranslation } from 'react-i18next'
import { BeWizrLearningTrack } from '~/models'
import { memo } from '~/ui/component'
import { Chip } from '~/ui/components'
import { colors } from '~/ui/styling'
import BeWizrTile from '../BeWizrTile'

export interface Props {
  learningTrack: BeWizrLearningTrack
  horizontal?:  boolean
}

const BeWizrLearningTrackTile = memo('BeWizrLearningTrackTile', (props: Props) => {

  const {learningTrack, horizontal} = props

  const [t] = useTranslation('bewizr')

  //------
  // Rendering

  function render() {
    return (
      <BeWizrTile
        title={learningTrack.title}
        detail={renderDetail()}
        href={`//bewizr-learning-track/${learningTrack.id}`}
        buttonCaption={t('learning-tracks.view')}
        image={learningTrack.links.image}
        horizontal={horizontal}
      />
    )
  }

  function renderDetail() {
    return (
      <Chip
        backgroundColor={colors.white}
        small
        icon='unordered-list'
        children={t('learning-tracks.items.count', {count: learningTrack.itemCount})}
      />
    )
  }

  return render()

})

export default BeWizrLearningTrackTile