import React from 'react'
import { useTranslation } from 'react-i18next'
import * as locales from '~/locales'
import { Center, HBox, Label, PopupMenu, PopupMenuItem, SVG, Tappable, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  small?: boolean
}

const LanguageSwitcher = React.memo((props: Props) => {

  const {small = false} = props

  const {t, i18n}       = useTranslation('app')
  const languages       = Object.keys(locales)
  const currentLanguage = i18n.language

  const $ = useStyles()

  const items = React.useMemo((): PopupMenuItem[] => languages.map(lng => ({
    value: lng,
    render: () => (
      <HBox gap={layout.padding.inline.m} classNames={$.languageItem}>
        <LanguageFlag small language={lng}/>
        <VBox flex>
          <Label truncate small bold>
            {t(`languages.${lng}`)}
          </Label>
        </VBox>
      </HBox>
    ),
  })), [$.languageItem, languages, t])

  const switchToLanguage = React.useCallback((language: string) => {
    i18n.changeLanguage(language)
  }, [i18n])

  return (
    <PopupMenu items={items} onValueSelect={switchToLanguage}>
      {toggle => (
        <LanguageFlag
          language={currentLanguage}
          onTap={toggle}
          small={small}
        />
      )}
    </PopupMenu>
  )

})

interface LanguageFlagProps {
  language: string
  small?:    boolean
  onTap?:    () => any
}

const LanguageFlag = React.memo((props: LanguageFlagProps) => {

  const {language, small, onTap} = props

  const $ = useStyles()

  function render() {
    if (onTap) {
      return <Tappable onTap={onTap} children={renderFlag()} noFeedback/>
    } else {
      return renderFlag()
    }
  }

  function renderFlag() {
    return (
      <Center classNames={[$.languageFlag, {small}]}>
        <SVG name={`lang.${language}` as SVGName}/>
      </Center>
    )
  }

  return render()
})

export default LanguageSwitcher

export const flagSize = {
  normal: 24,
  small:  16,
}

const useStyles = createUseStyles(theme => ({
  languageFlag: {
    width:        flagSize.normal,
    height:       flagSize.normal,
    borderRadius: flagSize.normal / 2,

    '&.small': {
      width:        flagSize.small,
      height:       flagSize.small,
      borderRadius: flagSize.small / 2,
    },

    border:       [2, 'solid', theme.colors.fg.light.normal],
    overflow:     'hidden',

    '& > svg': {
      width:  '100%',
      height: '100%',
    },

    '&:hover': {
      borderColor: theme.colors.fg.light.highlight,
    },
  },

  languageItem: {
    minHeight: 40,
    padding:   [layout.padding.inline.s, layout.padding.inline.m],
  },
}))