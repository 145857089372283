import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { CustomImage, RemoteImage } from '~/models'
import { memo } from '~/ui/component'
import { colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { ImageView } from '../app/media'
import { screenMinWidths } from '../styling/layout'
import { Label } from './label'
import { HBox, VBox } from './layout'

export interface Props {
  title?:           string
  image?:           CustomImage
  children?:        React.ReactNode
  backgroundImage?: RemoteImage
}

const Hero = memo('Hero', (props: Props) => {

  const {title, image, children, backgroundImage} = props

  //------
  // Rendering

  const $ = useStyles()

  const isMobile = !useMediaQuery({minWidth: screenMinWidths.tablet})

  const inlineStyle = backgroundImage == null ? {} : {
    backgroundImage: `url(${backgroundImage.url})`,
  }

  function render() {
    return (
      <ThemeProvider dark>
        <VBox align='center' classNames={$.Hero} style={inlineStyle}>
          {renderContent()}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderContent() {
    if (isMobile) {
      return (
        <VBox classNames={$.content}>
          {renderBody()}
        </VBox>
      )
    }

    return (
      <HBox classNames={$.content} gap={layout.padding.xl}>
        <VBox flex>
          {renderBody()}
        </VBox>
        {image != null && (
          <VBox flex>
            {renderImage()}
          </VBox>
        )}
      </HBox>
    )
  }

  function renderBody() {
    return (
      <VBox flex='grow' gap={layout.padding.l}>
        {renderTitle()}
        {isMobile && renderImage()}
        {children}
      </VBox>
    )
  }

  function renderTitle() {
    if (title == null) { return null }
    return (
      <Label h1 truncate={false}>
        {title}
      </Label>
    )
  }

  function renderImage() {
    if (image == null) { return null }

    return (
      <ImageView
        classNames={$.image}
        source={image}
      />
    )
  }

  return render()

})

export default Hero

export const imageRadius  = 6
export const contentWidth = 1024

const useStyles = createUseStyles(theme => ({
  Hero: {
    position:           'relative',
    backgroundColor:    theme.colors.bg.dark.alt,
    backgroundSize:     'cover',
    backgroundPosition: 'center center',

    '&::before': {
      display: 'block',
      content: '""',
      backgroundColor: colors.shim.dark,
      ...layout.overlay,
    },
  },

  content: {
    position: 'relative',
    zIndex: 20,
    maxWidth: contentWidth,
    padding: layout.padding.xl.mobile,
    ...layout.tablet({
      padding: layout.padding.xl.tablet,
    }),
    ...layout.widescreen({
      padding: [layout.padding.xxl.desktop, layout.padding.xl.desktop],
    }),
  },

  image: {
    borderRadius: 6,
  },
}))