import React from 'react'
import { Socket } from 'socket.io-react'
import defaultSocket from '../defaultSocket'
import { SocketStatus } from '../types'

export function useSocketStatus(socket: Socket = defaultSocket) {
  const [status, setStatus] = React.useState<SocketStatus>(socket.status)

  React.useEffect(() => {
    return socket.addStatusChangeListener((_, status) => {
      setStatus(status)
    })
  }, [socket])

  return status
}