import Semaphore from 'semaphore'
import socketIO, * as SocketIOClient from 'socket.io-client'
import config from './config'
import setupLogging, { logEmit, logSend, logSendTimeout } from './logging'
import SocketError from './SocketError'
import {
  ConnectListener,
  ConnectOptions,
  DisconnectListener,
  SendOptions,
  SendResponse,
  SocketListener,
  SocketStatus,
  SocketSubscription,
  StatusChangeListener,
} from './types'

export default class Socket {

  private socket: SocketIOClient.Socket | null = null

  private uri: string | null = null
  private connectOptions: ConnectOptions = {}

  //------
  // Connection & status

  public status: SocketStatus = 'disconnected'

  private setStatus(status: SocketStatus) {
    if (status === this.status) { return }
    this.status = status
    this.emitStatusChange()
  }

  public connect(uri: string, options: ConnectOptions = {}) {
    if (this.socket != null) { return }

    this.setStatus('connecting')

    config.logger.info(`Connecting to ${uri}`, options)
    this.uri    = uri
    this.connectOptions = options
    this.socket = socketIO(uri, options)
    setupLogging(this.socket)

    this.setupCommonListeners()
    this.bindEventListeners()
  }

  public disconnect() {
    if (this.status !== 'disconnected') {
      this.forceDisconnect()
    }
  }

  private forceDisconnect() {
    const {socket} = this
    if (socket == null) { return }

    // First remove listeners, and set the socket to `null`, to prevent reconnecting.
    socket.offAny()
    this.socket = null

    // Then disconnect.
    socket.disconnect()

    // Finally, emit our own disconnect event and set the status.
    this.emitDisconnect()
    this.setStatus('disconnected')
  }

  private reconnect() {
    if (this.uri == null) { return }
    this.connect(this.uri, this.connectOptions)
  }

  private reconnectAfterDisconnect() {
    const {reconnect = config.defaultReconnect} = this.connectOptions
    if (reconnect === false) { return }

    // Make sure the socket is disconnected. There are certain circumstances where an 'error' event
    // is fired, but the socket stays alive.
    if (this.socket) {
      this.socket.disconnect()
      this.socket = null
    }

    setTimeout(() => { this.reconnect() }, reconnect)
    config.logger.info(`Reconnecting in ${reconnect} ms`)

    this.setStatus('connecting')
  }

  //------
  // Socket interface

  public emit<A extends any[] = any[]>(event: string, ...args: A) {
    if (this.socket == null) { return }

    logEmit(this.socket, event, args)
    this.socket.emit(event, ...args)
  }

  public send<A extends any[] = any[], R = any>(event: string, ...args: A): Promise<SendResponse<R>> {
    return this.sendWithOptions({}, event, ...args)
  }

  public fetch<A extends any[] = any[], R = any>(event: string, ...args: A): Promise<SendResponse<R>> {
    return this.sendWithOptions({timeout: false}, event, ...args)
  }

  public sendWithOptions<A extends any[] = any[], R = any>(options: SendOptions, event: string, ...args: A): Promise<SendResponse<R>> {
    const {
      timeout      = config.defaultSendTimeout,
      waitForReady = true,
      operation,
    } = options

    const startPromise: Promise<void> = waitForReady
      ? this.ready.then(() => undefined)
      : Promise.resolve()

    const promise = startPromise.then(() => new Promise<SendResponse<R>>(resolve => {
      let timeoutSent = false

      const {socket} = this
      if (socket == null) {
        const error = new SocketError(0, "Not connected")
        return Promise.resolve({ok: false, error})
      }

      const timeoutHandle = timeout === false ? null : setTimeout(() => {
        logSendTimeout(socket, event, args)

        const error = new SocketError(0, "Socket timeout")
        config.errorHandler(error, options)
        resolve({ok: false, error})
        if (timeoutHandle != null) {
          clearTimeout(timeoutHandle)
        }
        timeoutSent = true
      }, timeout)

      if (operation != null) {
        args.push({[OPERATION_KEY]: operation.uid})
      }

      socket.emit(event, ...args, (error: any, body: R) => {
        if (timeoutSent) { return }

        if (timeoutHandle != null) {
          clearTimeout(timeoutHandle)
        }

        const socketError = error == null ? null : config.extractError(error)
        if (socketError != null) {
          logSend(socket, event, args, socketError, body)
          config.errorHandler(socketError, options)
          resolve({ok: false, error: socketError})
        } else {
          logSend(socket, event, args, null, body)
          resolve({ok: true, body})
        }
      })
    }))

    if (this.promiseRejectionHandler != null) {
      promise.then(response => {
        if (!response.ok) {
          this.promiseRejectionHandler?.(response.error)
        }
      })
    }

    return promise
  }

  public promiseRejectionHandler?: (reason: Error) => any

  //------
  // Event listeners

  private eventListeners: Map<string, Set<AnyFunction>> = new Map()

  public addEventListener<L extends AnyFunction>(event: string, listener: L) {
    if (event === 'connect') {
      this.connectListeners.add(listener)
    } else if (event === 'disconnect') {
      this.disconnectListeners.add(listener)
    } else {
      let listeners = this.eventListeners.get(event)
      if (listeners == null) {
        this.eventListeners.set(event, listeners = new Set())
      }
      listeners.add(listener)

      if (this.socket != null) {
        this.socket.on(event, this.wrapListener(listener))
      }
    }
  }

  public removeEventListener<L extends AnyFunction>(event: string, listener: L) {
    if (event === 'connect') {
      this.connectListeners.delete(listener)
    } else if (event === 'disconnect') {
      this.disconnectListeners.delete(listener)
    } else {
      const listeners = this.eventListeners.get(event)
      if (listeners != null) {
        listeners.delete(listener)
      }

      if (this.socket != null) {
        this.socket.off(event, listener)
      }
    }
  }

  public once<L extends AnyFunction>(event: string, listener: L) {
    this.socket?.once(event, listener)
  }

  public removeAllListeners() {
    this.eventListeners.clear()
    if (this.socket != null) {
      this.socket.offAny()
    }
  }

  private bindEventListeners() {
    if (this.socket == null) { return }

    for (const [event, listeners] of this.eventListeners) {
      for (const listener of listeners) {
        this.socket.on(event, this.wrapListener(listener))
      }
    }
  }

  private wrapListener(listener: AnyFunction) {
    // Catch any error, otherwise the socket will be disconnected every time some exception
    // is thrown in any listener.
    return async (...args: any[]) => {
      try {
        return await listener(...args)
      } catch (error) {
        console.error(error)
      }
    }
  }

  //-------
  // Init & ready handling

  // socket.io-react knows three states of connecting:
  //
  // - not connected
  // - connected
  // - ready
  //
  // Between connected and ready, the server is setting up services. When done, it emits a
  // 'ready' event, and all readyListeners are called.

  public readonly ready = new Semaphore()

  private readyListeners: Set<SocketListener> = new Set()
  private statusChangeListeners: Set<StatusChangeListener> = new Set()

  private connectListeners: Set<ConnectListener> = new Set()
  private disconnectListeners: Set<DisconnectListener> = new Set()

  public onReady(listener: SocketListener): SocketSubscription {
    this.readyListeners.add(listener)

    if (this.status === 'ready') {
      listener(this)
    }

    return {
      stop: () => {
        this.readyListeners.delete(listener)
      },
    }
  }

  private emitReady() {
    for (const listener of this.readyListeners) {
      listener(this)
    }
    this.ready.signal()
  }

  private emitConnect(attempt: number) {
    for (const listener of this.connectListeners) {
      listener(this, attempt)
    }
  }

  private emitDisconnect() {
    for (const listener of this.disconnectListeners) {
      listener(this)
    }
    this.ready.reset()
  }

  public addStatusChangeListener(listener: StatusChangeListener) {
    this.statusChangeListeners.add(listener)
    return () => { this.statusChangeListeners.delete(listener) }
  }

  private emitStatusChange() {
    for (const listener of this.statusChangeListeners) {
      listener(this, this.status)
    }
  }

  //-------
  // Common listeners

  private setupCommonListeners() {
    if (this.socket == null) { return }

    this.socket.on('connect', this.handleConnect)
    this.socket.on('reconnect_attempt', this.handleReconnectAttempt)
    this.socket.on('reconnect_failed', this.handleReconnectFailed)
    this.socket.on('disconnect', this.handleDisconnect)

    this.socket.on('connect_error', this.handleConnectError)
    this.socket.on('error', this.handleError)
  }

  private connectAttempt: number = 1

  private handleConnect = () => {
    config.logger.info({text: 'Initializing', styles: [{background: '#777700', color: 'white', padding: '0 2px', 'border-radius': '2px'}]})
    this.emitConnect(this.connectAttempt)
    this.setStatus('initializing')
    this.once('ready', this.handleReady)
    this.connectAttempt = 1
  }

  private handleReady = () => {
    config.logger.info({text: 'Ready', styles: [{background: 'green', color: 'white', padding: '0 2px', 'border-radius': '2px'}]})
    this.setStatus('ready')
    this.emitReady()
  }

  private handleReconnectAttempt = (attempt: number) => {
    config.logger.info(`Attempting reconnect (${attempt})`)
    this.connectAttempt = attempt
  }

  private handleReconnectFailed = () => {
    config.logger.info(`Reconnect failed`)
    this.reconnectAfterDisconnect()
  }

  private handleDisconnect = (reason: string) => {
    config.logger.info('Disconnected', reason)

    // Emit a disconnect event.
    this.emitDisconnect()

    // If our socket is null, it means we've disconnected from the client. In that case,
    // don't reconnect
    if (this.socket != null) {
      this.reconnectAfterDisconnect()
    }
  }

  private handleConnectError = (reason: string | object) => {
    // Only respond if this is an actual server error.
    if (reason instanceof Error && (reason as any).data != null) {

      const error = config.extractError((reason as any).data)
      config.logger.error('Connect error', error)
      config.errorHandler?.(error, {silent: false})

      this.forceDisconnect()
    }
  }

  private handleError = (reason: string | object) => {
    let error: SocketError
    if (reason instanceof Error && (reason as any).data != null) {
      error = config.extractError((reason as any).data)
    } else {
      error = config.extractError(reason)
    }
    config.logger.warning('Error', {error})
    config.errorHandler?.(error, {silent: false})

    // The error handler may have requested a permanent disconnect. Check that we still
    // have a socket before reconnecting. Use a setImmediate to wait for all effects to
    // be resolved.
    setImmediate(() => {
      if (this.socket != null) {
        this.reconnectAfterDisconnect()
      }
    })
  }

}

const OPERATION_KEY = 'socket.io-services:operation'