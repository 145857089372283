import React from 'react'
import { useTranslation } from 'react-i18next'
import { Post } from '~/models'
import { profileStore } from '~/stores'
import { memo } from '~/ui/component'
import { ConfirmBox, HBox, KebabMenu, Label, LinkLabel, PopupMenuItem } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { useNewsService } from './NewsContext'

export interface Props {
  postID?:      string
  comment:      Post
  interactive?: boolean
  requestEdit?: (comment: Post) => any
}

const CommentHeader = memo('CommentHeader', (props: Props) => {

  const {postID, comment, interactive = true, requestEdit} = props
  const theme = useTheme()

  const service = useNewsService()

  const [t] = useTranslation('news')

  const participantID = profileStore.participantID
  const ownComment    = comment.author.id === participantID

  const remove = React.useCallback(async () => {
    if (postID == null) { return }

    const confirmed = await ConfirmBox.show({
      ...t('comment.remove.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    const document = service.posts.document(postID)
    document.removeComment(comment.id)
  }, [comment.id, postID, service, t])

  const edit = React.useCallback(() => {
    requestEdit?.(comment)
  }, [comment, requestEdit])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    if (ownComment) {
      items.push({
        icon:     'pencil',
        caption:  t('comment.edit.caption'),
        onSelect: edit,
      }, {
        icon:    'trash',
        caption:  t('comment.remove.caption'),
        onSelect: remove,
      })
    }

    return items
  }, [edit, ownComment, remove, t])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.m} align='top'>
        {renderMain()}
        {interactive && kebabMenuItems.length > 0 && (
          <KebabMenu
            items={kebabMenuItems}
            button={{small: true, padding: 'horizontal'}}
          />
        )}
      </HBox>
    )
  }

  function renderMain() {
    return (
      <HBox flex gap={layout.padding.inline.s}>
        <LinkLabel
          bold
          small
          href={`/participants/-/${comment.author.id}`}
          color={theme.fg.normal}
          flex='shrink'
        >
          {comment.author.name}
        </LinkLabel>
        {renderDate()}
      </HBox>
    )
  }

  function renderDate() {
    if (comment.publishedAt == null) { return null }

    return (
      <Label tiny dim flex='shrink'>
        {comment.publishedAt?.toFormat('MMM d, HH:mm')}
      </Label>
    )
  }


  return render()

})

export default CommentHeader