import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useSocketStatus } from 'socket.io-react'
import { useClock } from '~/socket/useClock'
import { HBox, Label, LabelProps, Spinner } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props extends Omit<LabelProps, 'children'> {
  to:             DateTime
  soonThreshold?: number | null
}

const Countdown = observer((props: Props) => {

  const {to, soonThreshold = 5} = props

  const {currentTime} = useClock({interval: 'second'})
  const socketStatus  = useSocketStatus()
  const connected     = socketStatus === 'ready'
  const duration      = currentTime == null ? null : to.diff(currentTime)

  const [t] = useTranslation('countdown')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (duration == null) { return null }

    if (!connected) {
      return <Spinner size={12}/>
    } else {
      return renderCountdown()
    }
  }

  function renderCountdown() {
    if (duration == null) { return null }

    if (soonThreshold != null && (duration.milliseconds / 1000) < soonThreshold) {
      return (
        <Label {...props}>
          {t('soon')}
        </Label>
      )
    }

    const shifted = duration.shiftTo('days', 'hours', 'minutes', 'seconds')
    const days    = Math.max(0, Math.floor(shifted.days))
    const hours   = Math.max(0, Math.floor(shifted.hours))
    const minutes = Math.max(0, Math.floor(shifted.minutes))
    const seconds = Math.max(0, Math.floor(shifted.seconds))

    const showDays    = days > 0
    const showHours   = showDays || hours > 0

    return (
      <HBox align='baseline' gap={layout.padding.inline.s}>
        <HBox align='baseline'>
          {showDays && renderPart(days)}
          {showDays && renderUnit(t('units.day'))}
        </HBox>

        <HBox align='baseline'>
          {showHours && renderPart(showDays && hours < 10 ? `0${hours}` : `${hours}`)}
          {showHours && renderUnit(t('units.hour'))}
        </HBox>

        <HBox align='baseline'>
          {renderPart((showHours && minutes < 10) ? `0${minutes}` : `${minutes}`)}
          {renderUnit(t('units.minute'))}
        </HBox>

        <HBox align='baseline'>
          {!showHours && renderPart(seconds < 10 ? `0${seconds}` : `${seconds}`)}
          {!showHours && renderUnit(t('units.second'))}
        </HBox>
      </HBox>
    )
  }

  function renderPart(content: any) {
    return (
      <Label {...props}>
        {content}
      </Label>
    )
  }

  function renderUnit(content: any) {
    return (
      <Label classNames={$.unit}>
        {content}
      </Label>
    )
  }

  return render()

})

export default Countdown

const useStyles = createUseStyles(theme => ({
  unit: {
    fontSize: '80%',
    color:    theme.fg.dim,
  },
}))