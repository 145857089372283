import React from 'react'
import { useTranslation } from 'react-i18next'
import { challengesStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller, SearchField, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import NavColumnHelmet from '../navigation/NavColumnHelmet'
import ChallengesTileGrid from './ChallengesTileGrid'

export interface Props {
  which: 'open' | 'completed'
}

const ChallengeEndpointColumn = observer('ChallengeEndpointColumn', (props: Props) => {

  const {which}  = props
  const endpoint = React.useMemo(() => challengesStore.endpointForScope(which), [which])

  const fetch = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  const fetchMore = React.useCallback(() => {
    endpoint.fetchNextPage()
  }, [endpoint])

  const setSearch = React.useCallback((search: string | null) => {
    endpoint.setParams({search})
  }, [endpoint])

  React.useEffect(fetch, [fetch])

  const [t] = useTranslation('challenges')
  const title = t(`title.${which}`)

  //------
  // Rendering

  function render() {
    return(
      <>
        <NavColumnHelmet
          title={title}
          fetchStatus={endpoint.fetchStatus}
          reload={fetch}
        />
        {renderGrid()}
      </>
    )
  }

  function renderGrid() {
    return (
      <Scroller onEndReached={fetchMore}>
        <VBox flex='grow' gap={layout.padding.inline.l} padding={layout.padding.inline.l}>
          {renderSearch()}
          {endpoint.data.length === 0 ? (
            renderEmpty()
          ) : (
            <ChallengesTileGrid
              endpoint={endpoint}
            />
          )}
        </VBox>
      </Scroller>
    )
  }

  function renderSearch() {
    return (
      <VBox>
        <SearchField
          search={endpoint.param('search')}
          onSearch={setSearch}
        />
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={endpoint.fetchStatus}
        {...t('empty.all')}
        flex
      />
    )
  }

  return render()

})

export default ChallengeEndpointColumn