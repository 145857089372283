import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import config from '~/config'
import { appStore, projectStore } from '~/stores'
import Clock from '~/ui/app/clock/Clock'
import DrawerToggle from '~/ui/app/drawer/DrawerToggle'
import { observer } from '~/ui/component'
import { BrandedComponent, BrandedLogo, HBox, Label, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import AuthStatus from './AuthStatus'
import LanguageSwitcher from './LanguageSwitcher'

const AppHeader = observer('AppHeader', () => {

  const [t] = useTranslation('app')
  const openConfigDialog = React.useCallback(() => {
    appStore.openConfigDialog()
  }, [])

  const {project} = projectStore
  const isTablet  = useMediaQuery({minWidth: screenMinWidths.tablet})
  const isDesktop = useMediaQuery({minWidth: screenMinWidths.desktop})

  const drawerEnabled = project?.feature('drawer')?.enabled && !isDesktop

  //------
  // Rendering

  const $ = useStyles()
  const {guide} = useStyling()

  function render() {
    return (
      <BrandedComponent branding={guide.web.header} height={height} classNames={$.appHeader}>
        <HBox flex gap={layout.padding.l} justify='space-between'>
          {renderLeft()}
          {renderRight()}
        </HBox>
      </BrandedComponent>
    )
  }

  function renderLeft() {
    const title    = guide.text('title') ?? project?.name ?? t('title')
    const subtitle = guide.text('subtitle')

    return (
      <HBox gap={layout.padding.m}>
        {drawerEnabled && <DrawerToggle/>}
        <Tappable href='/'>
          <BrandedLogo/>
        </Tappable>
        {isTablet && (
          <VBox>
            <Label h2>
              {title}
            </Label>
            {subtitle != null ? (
              <Label small dim italic>
                {subtitle}
              </Label>
            ) : config.environment !== 'live' ? (
              renderVersionInfo()
            ) : null}
          </VBox>
        )}
      </HBox>
    )
  }

  function renderVersionInfo() {
    return (
      <Tappable onTap={openConfigDialog}>
        <Label tiny mono dim>
          {`v${config.version} (${config.environment})`}
        </Label>
      </Tappable>
    )
  }

  function renderRight() {
    return (
      <HBox gap={layout.padding.s}>
        {project?.web.layout === 'live' && <Clock/>}
        <LanguageSwitcher/>
        {!drawerEnabled && <AuthStatus/>}
      </HBox>
    )
  }

  return render()

})

export default AppHeader

export const height = layout.barHeight.l

const useStyles = createUseStyles({
  appHeader: {
    height:           height,
    position:         'relative',
    zIndex:           layout.z.header,
    borderTopWidth:   0,
    borderLeftWidth:  0,
    borderRightWidth: 0,

    ...layout.breakpoint({maxWidth: screenMinWidths.tablet - 1})({
      boxShadow: 'none',
    }),

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },
})