import React from 'react'
import { NotificationHost } from 'react-notifications'
import { useMediaQuery } from 'react-responsive'
import { Route, Switch } from 'react-router-dom'
import { projectStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { HBox, Panel, VBox } from '~/ui/components'
import { AppSideBar } from '~/ui/layouts'
import { createUseStyles, layout } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import { NavPanel } from '../navigation'
import { NavigationStateContainer } from '../navigation/NavigationStateContext'
import ProfileContainer from '../profile/ProfileContainer'
import CollapsedLayout from './CollapsedLayout'
import HomeChat from './HomeChat'

const FullLayout = observer('FullLayout', () => {

  const $ = useStyles()

  const isDesktop = useMediaQuery({minWidth: screenMinWidths.widescreen})

  const tabs = projectStore.tabs
  const hasChatTab = React.useMemo(
    () => tabs.find(it => it.link.href.startsWith('//chat')),
    [tabs],
  )

  function render() {
    if (!isDesktop) {
      return <CollapsedLayout />
    }

    return (
      <HBox flex align='stretch'>
        <AppSideBar/>
        {renderNavigation()}
        <VBox tag='main'>
          {hasChatTab && <HomeChat classNames={$.HomeChat}/>}
          <NotificationHost
            className={$.notificationHost}
            padding={layout.padding.inline.l}
          />
        </VBox>
      </HBox>
    )
  }

  function renderNavigation() {
    return (
      <Switch>
        <Route path='/profile' exact component={ProfileWrapper} />
        <Route path='/' component={NavPanelWrapper}/>
      </Switch>
    )
  }

  return render()

})

export default FullLayout

const ProfileWrapper = memo('ProfileWrapper', () => {
  const $ = useStyles()
  return (
    <Panel flex classNames={$.panel}>
      <ProfileContainer/>
    </Panel>
  )
})

const NavPanelWrapper = memo('NavPanelWrapper', () => {
  const $ = useStyles()
  return (
    <NavigationStateContainer>
      <NavPanel flex classNames={$.panel} />
    </NavigationStateContainer>
  )
})

const useStyles = createUseStyles(({
  HomeChat: {
    width: layout.columnWidth,
    borderRadius: 0,
  },

  notificationHost: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: layout.z.bodyTop,
    ...layout.breakpoint({minWidth: screenMinWidths.tablet})({
      left: 'auto',
      width: layout.columnWidth,
    }),
  },

  panel: {
    borderRadius: 0,
  },
}))